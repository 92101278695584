import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  adornedEnd: {
    fill: theme.palette.primary.sideBarIconColor,
    opacity: '0.8',
    fontWeight: 500,
    fontSize: '14px'
  },
  box: {
    minWidth: '200px',
    marginRight: '16px'
  },
};