export default {
  group: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonStyle: {
    textTransform: 'none',
    marginTop: '8px',
    marginRight: '8px',
    border: '1px solid #1A5787',
    position: 'relative',  // To position the checkmark icon
    paddingLeft: '24px',
    paddingRight: '24px',
    '&:hover': {
      backgroundColor: '#1A5787',
      color: '#ffffff',
      border: '1px solid #1A5787',
    },
    '&.selected': {
      backgroundColor: '#1A5787',
      color: '#ffffff',
      border: '1px solid #1A5787',
      '&:after': {
        content: '"✓"', // Checkmark symbol
        position: 'absolute',
        top: '50%',
        left: '8px', // Adjust this value to control the distance from the left edge
        transform: 'translateY(-50%)',
        fontSize: '14px',
      },
    },
    '&.not-selected': {
      backgroundColor: 'unset',
      color: '#1A5787',
    },
    '&.disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    }
  },
};
