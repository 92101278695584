import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    // Palette configuration remains largely the same, so you can keep your custom colors as is.
    primary: {
      // Your primary color configuration
      light: '#2b6c9e',
      main: '#1A5787',
      dark: '#303f9f',
      contrastText: '#fff',
      colorDark: '#fafafa',
      notActiveTextColor: '#737373',
      activeTextColor: '#000000DE',
      activeIconColor: 'black',
      footerColor: '#f5fbfb',
      footerTextColor: '#737373',
      headerColor: '#FFFFFF',
      headerIconsColor: '#1A5787',
      lightBlue: '#EAECF7',
      blue: '#3f51b5',
      tableRowSuccess: '#c6ecc6',
      tableRowError: '#ffcccc',
      backgroundColor: '#F6F7FA',
      notActiveIconColor: '#8d8d8d',
      error: '#f44336',
      errorHover: '#ebd7e0',
      scrollColor: '#c1c1c1;',
      mainBackground: '#F6F7FA',
      sideBarTextColor: '#3D3D3D',
      sideBarBgColor: 'white',
      sideBarIconColor: '#3D3D3D',
      tableDisableRow: '#8B8B8B',
    },
    secondary: {
      // Your secondary color configuration
      light: '#f7f7f7',
      main: '#e0e0e0',
      dark: '#c7c7c7',
      contrastText: '#000000DE',
      notActiveTextColor: '#FFFFFFB3',
      activeTextColor: '#FFFFFF',
      activeIconColor: 'black',
      footerColor: '#f5fbfb',
      footerTextColor: '#737373',
      headerColor: '#FFFFFF',
      headerIconsColor: '#1A5787',
      lightBlue: '#EAECF7',
      blue: '#3f51b5',
      tableRowSuccess: '#c6ecc6',
      tableRowError: '#ffcccc',
      backgroundColor: '#fafafa',
      notActiveIconColor: '#8d8d8d',
      error: '#f44336',
      errorHover: '#ebd7e0',
      scrollColor: '#c1c1c1;',
      mainBackground: '#F6F7FA',
      sideBarTextColor: '#3D3D3D',
      sideBarBgColor: 'white',
      sideBarIconColor: '#3D3D3D',
      tableDisableRow: '#8B8B8B',
    },
    error: {
      main: '#F2453D',
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation5: {
            boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.05)'
        },
        elevation8: {
            boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.05)'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(61, 61, 61, .05)',
            color: 'rgba(61, 61, 61)',
          },
          '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, .05)',
            color: 'rgba(61, 61, 61)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(27, 88, 135, .1)',
            color: '#1B5887',
          },
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        }
      }
    }
  },
});

export default theme;