import React from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from '@mui/material';
import M from 'messages';
import domainData from 'store/domainStore';
import { getCellPadding, getWidth } from 'helpers/tableStyleHelper';
import TableConfigs from 'constants/table-configs';

import classes from './styles';

function CustomTableHead(props) {
  const {
    handleAllSelected,
    sortObj,
    numSelected,
    rowCount = 0,
    fields,
    withEditAction = true,
    withQRAction = false,
    withInMarketAction = false,
    withDownloadAction = false,
    withRemoveAction = false,
    onRequestSort = null,
    filteredParams,
    withCheckbox = true,
    withOpenAction = false,
    withCloneAction = false,
    maxRowCount = null,
    tableSelects = null
  } = props;

  const createSortHandler = (property) => (event) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  const isUserSuperAdmin = domainData.userIsSuperAdmin();

  const currentSp = domainData.getCurrentSP();
  const currentSpData = domainData.getCurrentSpData();

  return (
    <TableHead>
      <TableRow sx={classes.tableHeadRow}>
        {withCheckbox && <TableCell padding="checkbox" style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}}>
          <Checkbox
            indeterminate={tableSelects > 0 && tableSelects < rowCount}
            checked={rowCount > 0 && (tableSelects === rowCount || numSelected === maxRowCount)}
            onChange={handleAllSelected}
            color="primary"
          />
        </TableCell>}
        {fields.map((headCell) => {
            const id = headCell.orderField || headCell.id;
            const {cellPaddingRight, cellPaddingLeft} = rowCount > 0 ? headCell : {cellPaddingRight: TableConfigs.defaultCellPadding, cellPaddingLeft: TableConfigs.defaultCellPadding};
            return (!headCell.show || headCell.show({ currentSpData, isUserSuperAdmin, currentSp })) && (
                <TableCell
                    key={id}
                    sortDirection={sortObj.field === id ? sortObj.order : false}
                    style={{...getWidth(headCell.width), ...getCellPadding(cellPaddingRight, cellPaddingLeft)}}
                    align={headCell.headAlign || "left"}
                >
                    {headCell.sortable && (
                    <TableSortLabel
                        active={filteredParams?.params?.sort?.field === id}
                        direction={
                        filteredParams?.params?.sort?.field === id
                            ? filteredParams.params.sort.order
                            : 'asc'
                        }
                        onClick={createSortHandler(id)}
                    >
                        {M.get(headCell.label)}
                    </TableSortLabel>
                    )}
                    {!headCell.sortable && M.get(headCell.label)}
                </TableCell>
            )})
        }
        {withQRAction && <TableCell style={{ width: '24px', paddingRight: '0px', paddingLeft: '24px'}} />}
        {withInMarketAction && <TableCell style={{ width: '24px', paddingRight: '0px', paddingLeft: '24px'}} />}
        {withEditAction && <TableCell style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}} />}
        {withDownloadAction && <TableCell style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}} />}
        {withOpenAction && <TableCell style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}} />}
        {withCloneAction && <TableCell style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}} />}
        {withRemoveAction && <TableCell style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px'}} />}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func,
  handleAllSelected: PropTypes.func.isRequired,
  sortObj: PropTypes.shape({ order: PropTypes.oneOf(['asc', 'desc', '']), field: PropTypes.string }).isRequired,
  rowCount: PropTypes.number,
  fields: PropTypes.instanceOf(Array).isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  withEditAction: PropTypes.bool,
  withDownloadAction: PropTypes.bool,
  withRemoveAction: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  withOpenAction: PropTypes.bool,
  withCloneAction: PropTypes.bool,
  maxRowCount: PropTypes.number,
  tableSelects: PropTypes.number,
  withQRAction: PropTypes.bool,
  withInMarketAction: PropTypes.bool,
};

export default CustomTableHead;