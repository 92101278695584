import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, TableCell, Checkbox, Icon, Tooltip, Typography, Link } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import GetAppIcon from '@mui/icons-material/GetApp';
import M from 'messages';
import TableConfigs from 'constants/table-configs';
import { getCellPadding, getWidth } from 'helpers/tableStyleHelper';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import classes from './styles';

function CustomTableCell({
  isCheckbox = false,
  isItemSelected = false,
  isEditAction = false,
  isQRAction = false,
  isInMarketAction = false,
  isDownloadAction = false,
  isRemoveAction = false,
  isEmptyCell = false,
  cellItem = {},
  data = {},
  loading = false,
  handleClickIcon = null,
  isOpenAction = false,
  isCloneAction = false,
  openPath = '',
  filteredParams = {},
  disableIcon = false
}) {
  const onIconClick = (e, values) => {
    e.stopPropagation();
    handleClickIcon(values);
  }

  const onEditIconClick = (e, values) => {
    e.stopPropagation();
    handleClickIcon(values);
  }

  const isSortedField = () => filteredParams.params?.sort?.field === cellItem.id || (cellItem.orderField && filteredParams.params?.sort?.field === cellItem.orderField) || cellItem.isActiveStyle;

  const defaultCell = () => (
    <TableCell
      sx={{...classes.tableCellItem, ...(isSortedField() && classes.sortedCeil), ...(cellItem.withEllipsis && classes.ellipsis)}}
      style={{...getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft), ...getWidth(cellItem.width)}}
      align={cellItem.textAlign || "left"}
    >
      {
        cellItem.withTooltip ? (
          <Tooltip title={data[cellItem.id]} placement="bottom-start" >
            <span>{data[cellItem.id]}</span>
          </Tooltip>
        ) : data[cellItem.id]
      }
    </TableCell>
  )

  const cellView = () => {
    if (isEmptyCell) {
      return <TableCell style={getCellPadding(TableConfigs.defaultCellPadding, TableConfigs.defaultCellPadding)} colSpan={20} sx={isSortedField() ? classes.sortedCeil : {}}> {!loading && M.get('table.noResult')} </TableCell>;
    }
    if (isCheckbox) {
      return <TableCell component="th" scope="row" style={{ width: '24px', paddingRight: '16px', paddingLeft: '24px' }} sx={isSortedField() ? classes.sortedCeil : {}} > <Checkbox onClick={(e) => onIconClick(e, data)} checked={isItemSelected} color="primary" /> </TableCell>;
    }
    if (isInMarketAction && data.active && data.status === 'no') {
      return <TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} > <Tooltip title="Activate for Market"><PublishedWithChangesIcon onClick={(e) => onEditIconClick(e, data)} /></Tooltip></TableCell>
    }
    if (isEditAction) {
      return <TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} > <Tooltip title="Edit"><EditIcon onClick={(e) => onEditIconClick(e, data)} /></Tooltip></TableCell>
    }
    if (isQRAction && data.active) {
      return <TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} > <Tooltip title="Open QR Code"><QrCodeIcon onClick={(e) => onEditIconClick(e, data)} /></Tooltip></TableCell>
    }
    if (isDownloadAction) {
      return <TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} > <GetAppIcon onClick={(e) => onEditIconClick(e, data)} /> </TableCell>
    }
    if (isRemoveAction) {
      return <TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil), ...classes.removeCell}} > <RemoveCircleIcon onClick={(e) => onEditIconClick(e, data)} /> </TableCell>
    }
    if (isOpenAction) {
      return handleClickIcon
        ? (<TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} >
            <ArrowForwardIosIcon onClick={(e) => onEditIconClick(e, data)} />
          </TableCell>)
        : (<TableCell sx={classes.tableCellAction} align="left">
            <RouterLink to={openPath}>
              <ArrowForwardIosIcon />
            </RouterLink>
          </TableCell>);
    }
    if (isCloneAction) {
      return (<TableCell align="left" sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} >
        <Icon sx={classes.copyIcon} onClick={(e) => onEditIconClick(e, data)}>content_copy</Icon>
      </TableCell>);
    }
    switch (cellItem.type) {
      case 'customComponent':
        return cellItem.component({ data, cellItem, isSortedCeil: isSortedField() ? classes.sortedCeil : '', onIconClick, disableIcon });
      case 'textWithComponent':
          return (
            <TableCell
              sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}}
              style={getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft)}
              align={cellItem.textAlign || "left"}
            >
            <Box sx={classes.textWithComponentRoot}>
              <Typography>{data[cellItem.id]}</Typography>
              {data[cellItem.componentDataName] && <Box sx={classes.textWithComponent} >{M.get(cellItem.componentTextPath)}</Box>}
            </Box>
          </TableCell>
          )
      case 'link':
        return (
          data[cellItem.routeIdKey ?? 'id']
            ? (<TableCell sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil), ...(cellItem.withEllipsis && classes.ellipsis)}} style={getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft)} align={cellItem.textAlign || "left"} >
              <Link href={cellItem.route.replace(':id', data[cellItem.routeIdKey ?? 'id'])} target={cellItem.newTab ? '_blank' : ''} rel="noopener noreferrer" sx={classes.listItemText}>{data[cellItem.id]}</Link>
            </TableCell>)
            : defaultCell()
        );
      case 'img':
        return (
          <TableCell sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} style={getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft)} align={cellItem.textAlign || "left"} >
            {data[cellItem.id] && <Box component='img' src={data[cellItem.id]} alt="img" sx={classes.imgItem} />}
          </TableCell>
        )
      case 'bool':
        return (
          <TableCell sx={{...classes.tableCellAction, ...(isSortedField() && classes.sortedCeil)}} style={getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft)} align={cellItem.textAlign || "left"} >
            {data[cellItem.id] ? cellItem.showIcon ?
              <Tooltip title={M.get('general.yes')}>
                <DoneIcon style={{ color: '#007f00' }} />
              </Tooltip> :
              M.get('general.yes') :
              cellItem.showIcon ?
                <Tooltip title={M.get('general.no')}>
                  <CloseIcon style={{ color: '#d30000' }} />
                </Tooltip> :
                M.get('general.no')}
          </TableCell>
        );
      case 'noWrap':
        return (
          <TableCell
            sx={{...classes.tableCellItem, ...classes.noWrap, ...(isSortedField() && classes.sortedCeil), ...(cellItem.withEllipsis && classes.ellipsis)}}
            style={{...getCellPadding(cellItem.cellPaddingRight, cellItem.cellPaddingLeft), ...getWidth(cellItem.width)}}
            align={cellItem.textAlign || "left"}
          >
            {data[cellItem.id]}
          </TableCell>
        );
      default:
        return defaultCell();
    }
  }
  return (
    <>
      {cellView()}
    </>
  );
}

CustomTableCell.propTypes = {
  cellItem: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  isCheckbox: PropTypes.bool,
  isItemSelected: PropTypes.bool,
  isEditAction: PropTypes.bool,
  isDownloadAction: PropTypes.bool,
  isRemoveAction: PropTypes.bool,
  isEmptyCell: PropTypes.bool,
  loading: PropTypes.bool,
  handleClickIcon: PropTypes.func,
  isOpenAction: PropTypes.bool,
  isCloneAction: PropTypes.bool,
  openPath: PropTypes.string,
  filteredParams: PropTypes.instanceOf(Object),
  disableIcon: PropTypes.bool,
  isQRAction: PropTypes.bool,
  isInMarketAction: PropTypes.bool,
};

export default CustomTableCell;