import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import M from 'messages';

import classes from './styles';

export default function CircularIndeterminate({ customClass = {}  }) {
  return (
    <Box sx={{...classes.root, ...customClass}}>
      <CircularProgress />
      <Typography variant="h1" sx={classes.title}> {M.get('general.loading')} </Typography>
      <Typography variant="h6" sx={classes.description}> {M.get('general.loadingDescription')} </Typography>
    </Box>
  );
}

CircularIndeterminate.propTypes = {
  customClass: PropTypes.object,
}