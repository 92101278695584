export default [
    "Aged",
    "Amarone",
    "Amphora",
    "AOC",
    "Aperitif",
    "Aromatic",
    "AVA",
    "Barolo",
    "Bio",
    "Biodynamic",
    "Blanc de Blancs",
    "Blanc de Noirs",
    "Blend",
    "Botrytis",
    "Brunello",
    "Brut",
    "Bubbly",
    "Chilled",
    "Classico",
    "Cocktail",
    "Crisp",
    "Cru Bourgeois",
    "Crianza",
    "DOC",
    "DOCa",
    "DOCG",
    "DO",
    "Earthy",
    "Elegant",
    "Estate",
    "Estate Bottled",
    "Event",
    "Fortified",
    "Fresh",
    "Fruity",
    "Full-bodied",
    "Gastronomic",
    "Garrafeira",
    "GI",
    "Gran Reserva",
    "Grand Cru",
    "Herbaceous",
    "Icewine",
    "IGP",
    "IGT",
    "IG",
    "Intense",
    "Lively",
    "Late Harvest",
    "Mature",
    "Mineral",
    "Natural",
    "Non-alcoholic",
    "Oaked",
    "Old Vines",
    "Organic",
    "Party",
    "PDO",
    "PDI",
    "Premium",
    "Prosecco",
    "Prädikatswein",
    "Kabinett",
    "Reserva",
    "Reserva Especial",
    "Reserva Privada",
    "Reserve",
    "Riserva",
    "Savory",
    "Single Vineyard",
    "Smooth",
    "Spicy",
    "Structured",
    "Super Tuscan",
    "Tannic",
    "Terroir",
    "Unoaked",
    "Velvety",
    "VDP",
    "Vendange tardive",
    "Vin de Table",
    "Vinho Regional",
    "Vinho Verde",
    "Vintage",
    "WO",
    "Youthful",
  ]
  