import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  paper: {
    padding: '2px 4px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  circleIcon: {
    padding: '10px'
  },
  inputBase: {
    padding: '0 10px',
    flex: 1,
  },
  summary: {
    height: '56px'
  },
  listRoot: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    overflowY: 'scroll',
    color: theme.palette.primary.main,
    padding: '0px',
    borderRadius: '5px',

    '&amp;::-webkit-scrollbar': {
      width: '8px',
    },
    '&amp;::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&amp;::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '10px',
      border: '2px solid #f1f1f1',
    },
    '&amp;::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listItemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '.MuiListItemText-primary': {
      fontSize: '16px'
    }
  },
  fieldHeaderText: {
    fontWeight: 500
  },
  divider: {
    margin: '0.5px',
    height: '28px'
  },
};
