import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
  },
  inputArea: {
    cursor: 'pointer',
    height: '66px',
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    width: '180px'
  },
  secondaryLogo: {
    maxWidth: '100px'
  },
  previewBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

  },
  removeBtn: {
    color: '#f44336',
  },
  loadingProgress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    color: theme.palette.primary.main,
  },
  newInputArea: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: 'unset',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputAreaDisabled: {
    opacity: 0.5
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'max-content',
  },
  boxBorder: {
    margin: '16px 0 0 0',
    padding: '30px 0 16px 0',
    border: '1px dashed rgba(27, 88, 135, 0.5)',
    borderRadius: '4px',
  },
  textStyle: {
    textAlign: 'center',
  },
  newLoadingProgress: {
    padding: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  dropImageDescription: {
    marginTop: '22px',
    color: theme.palette.primary.sideBarTextColor,
    fontSize: '14px',
    lineHeight: '21px',
  },
  dropImageBrowse: {
    color: theme.palette.primary.main,
    marginLeft: '3px'
  },
  errorBorderColor: {
    borderColor: '#f44336'
  },
  errorColor: {
    color: '#f44336'
  },
  menuItemInputArea: {
    cursor: 'pointer',
    width: '100%',
    height: '56px',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}