import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal';
import { TextField, Box, Autocomplete } from '@mui/material';
import M from 'messages';

const CopyActionModal = ({
    isOpen,
    handleClose,
    spList,
    selectedRows,
    handleCopyAction,
}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [moveLoading, setMoveLoading] = useState(false);

    const onChangeAutocomplete = (value) => {
        setSelectedOption(value);
    };

    const getOptionLabel = (option) => option.name;

    const isOptionEqualToValue = (option, value) => option.name === value.name;

    const handleSubmit = async () => {
        setMoveLoading(true);
        await handleCopyAction({ serviceProviderId: selectedOption.id, ids: selectedRows });
        setMoveLoading(false);
    };

    const disableSave = !selectedOption;

    // Reset selectedOption whenever the modal is opened
    useEffect(() => {
        if (isOpen) {
            setSelectedOption(null);
        }
    }, [isOpen]);


    return (
        <Modal
            open={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            title={M.get('catalog.copyConfirmationTitle')}
            disableSave={disableSave || moveLoading}
            leftBtnText={M.get('actions.cancel')}
            rightBtnText={M.get('actions.copy')}
            submitLoading={moveLoading}
        >
            <Box style={{ marginTop: '1rem' }}>
                {/* Padding from title */}
                <Autocomplete
                    size="small"
                    id="spId"
                    variant="outlined"
                    clearOnBlur
                    selectOnFocus
                    forcePopupIcon
                    handleHomeEndKeys
                    value={selectedOption}
                    defaultValue={selectedOption}
                    onChange={(e, value) => onChangeAutocomplete(value)}
                    options={spList}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionDisabled={(option) => option.isDisabled}
                    renderOption={(renderOptionProps, option) => {
                        const { key, ...rest } = renderOptionProps;
                        return <li key={key} {...rest}>{option.name}</li>;
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={M.get('catalog.copyAutocompleteLabel')}
                            variant="outlined"
                        />
                    )}
                />
            </Box>
        </Modal>
    );
};

CopyActionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    spList: PropTypes.array.isRequired,
    selectedRows: PropTypes.array.isRequired,
    handleCopyAction: PropTypes.func.isRequired,
};

export default CopyActionModal;
