export default {
  root: {
    width: '100%',
    backgroundColor: '#fff',
  },
  icon: {
    fontSize: '180px',
    color: '#3ABE79'
  },
  errorColor: {
    color: '#DD3834'
  },
  successContainer: {
    marginTop: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '& img': {
      width: '200px',
      height: '200px',
    }
  },
  successText: {
    fontSize: '32px'
  },
  buttons: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',

    '& button': {
      textTransform: 'none'
    }
  },
}