import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
    page: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: `calc(100vh - 64px)`,
        borderRadius: 'unset',
        backgroundColor: theme.palette.primary.mainBackground,
        color: theme.palette.primary.main,
        textAlign: 'start',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
    },
    pageContent: {
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'auto',
        height: `calc(100vh - 64px)`,
        margin: '24px 24px 0 24px',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)',
    },
    boxContainer: {
        borderRadius: '5px 5px 0 0',
        color: theme.palette.primary.sideBarTextColor,
        height: 'fit-content',
        boxShadow: '0px 0px 3px 0px #d4d4d4',
    },
    pageFooter: {
        boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        textTransform: 'none',
    },
    footerPart: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '84px',
        backgroundColor: 'white',
        alignContent: 'center',
        padding: '0 32px 0 24px',
        minWidth: '416px',
        '& > button': {
          textTransform: 'none',
          margin: '0 0 0 20px',
        },
    },
    editBox: {
        textAlign: 'end'
    },
    invalidSelect: {
        color: '#f44336',
        "&.Mui-focused": {
          color: "#f44336",
        },
    },
    divider: {
        marginBottom: '20px',
    },
    contactPerson: {
        fontWeight: 600,
        marginTop: '25px',
        fontSize: '18px',
        marginBottom: '0px',
    },
    fieldHeader: {
        margin: '0',
        '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    fieldHeaderText: {
        marginRight: '10px',
        fontWeight: '600',
        fontSize: '14px',
        color: theme.palette.primary.sideBarTextColor,
    },
    formControl: {
        marginTop: '5px',
    },
    fieldHeaderIcon: {
        color: theme.palette.primary.sideBarIconColor,
        opacity: '0.6',
        cursor: 'pointer',
        marginLeft: '8px',
    },
    fieldHeaderWithTooltip: {
        display: 'flex',
        alignItems: 'center',
    },
    isQrPartner: {
        marginLeft: 0,
    },
    helperDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '0px',
    },
}