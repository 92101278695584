import { routes } from '../config';

export default {
  dashboardMenu: {
    access: ['superAdmin', 'partner']
  },
  catalog: {
    access: ['superAdmin', 'partner']
  },
  kiosksMenus: {
    access: ['superAdmin', 'partner']
  },
  wineLabels: {
    access: ['superAdmin', 'partner']
  },
  partner: {
    access: ['superAdmin', 'partner']
  },
  users: {
    access: ['superAdmin']
  },

};

const getFirstPageRouteByRole = (currentUser) => {
  const firstOpenedPages = {
    superAdmin: routes.users.path,
    partner: routes.profileEdit.path.replace(':id', currentUser.id),
  };
  return currentUser?.usersRole?.name ? firstOpenedPages[currentUser.usersRole.name] : routes.login.path;
}

export { getFirstPageRouteByRole };