import React from 'react';
import Grid from '@mui/material/Grid2';
import { observer } from 'mobx-react';
import dashboardStore from 'store/dashboardStore';
import PieChartWidget from './widget/PieChartWidget';

const PieChartSection = observer(() => (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      <Grid size={{ xs: 12, md: 4 }}>
        <PieChartWidget title="Wine Views by Sweetness" data={dashboardStore.viewsBySweetness} />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <PieChartWidget title="Wine Views by Color" data={dashboardStore.viewsByColor} wineColors />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <PieChartWidget title="Wine Views by Style" data={dashboardStore.viewsByStyle} />
      </Grid>
    </Grid>
  )
);

export default PieChartSection;
