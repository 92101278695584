export default {
  requirementGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementTxt: {
    textAlign: 'right',
    color: '#9D9D9D',
    fontWeight: 600,
    fontSize: '11px',
    margin: '4px 0'
  },
  requirement: {
    color: '#9D9D9D',
    width: '12px',
    height: '12px',
    marginRight: '9px'
  },
  validRequirement: {
    color: 'green',
    width: '12px',
    height: '12px',
    marginRight: '9px'
  }
}