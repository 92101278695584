import React, { useState, useEffect } from 'react';
// Modules
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// MUI Core
import { Button, Paper, Box, Grid, FormControl, TextField, CircularProgress, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withAuthHOC from 'components/common/authHOC/authHOC';

import PageHeader from 'components/common/pageHeader';
import forgotPasswordStore from 'store/forgotPasswordStore';
import AuthService from 'services/authService';
import { getMessage } from 'helpers/helper';
import { ForgotPasswordForm } from 'config/forms';
import { routes } from 'config';
import emailSentImg from 'assets/emailSent.png';
import M from 'messages';

import classes from './style';


const ForgotPassword = observer(() => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const dataErrors = forgotPasswordStore.getForgotPasswordDataErrors();
  const data = forgotPasswordStore.getForgotPasswordData();

  useEffect(() =>
    () => {
      forgotPasswordStore.clearData();
      forgotPasswordStore.clearDataErrors();
    }, []
  );

  const getRequiredFields = () => {
    const requiredList = [];
    ForgotPasswordForm.forEach((i) => {
      requiredList.push(i.name);
    });
    return requiredList;
  };

  const onSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      const formHasError = forgotPasswordStore.forgotPasswordDataHasError(getRequiredFields());
      if (!formHasError) {
        try {
          const result = await AuthService.forgotPassword(data.email);
          enqueueSnackbar(getMessage(result.data), { variant: 'success' });
          setLoading(false);
          setEmailSent(true);
        } catch (err) {
          setLoading(false);
          enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
        }
        return true;
      }
      setLoading(false);
      enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
      return false;
  };

  const getEmailHelperText = () => {
    let helperText = '';
    if (dataErrors.email && dataErrors.email === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors.email && dataErrors.email === 'invalidEmail') {
      helperText = M.get(`forgotPassword.errors.invalidEmail`);
    }
    return helperText;
  }

  return !emailSent ? (
    <Box sx={classes.container} component={Paper} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('forgotPassword.title')}
        onlyGeneralPageTitle
      />
      <Typography component="h3" sx={classes.title}> {M.get('forgotPassword.title')} </Typography>
      <Typography sx={classes.description}> {M.get('forgotPassword.description')} </Typography>
      <form onSubmit={onSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
              <Grid item xs={12} sm={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    variant="outlined"
                    label={M.get('users.email')}
                    id="email"
                    name="email"
                    value={data.email}
                    required
                    onChange={forgotPasswordStore.onChangeFieldFPW}
                    error={!!dataErrors.email}
                    helperText={getEmailHelperText()}
                  />
                </FormControl>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading || !data.email || dataErrors.email}
              sx={(loading || !data.email || dataErrors.email) ? classes.sendDisabled : classes.send}
            >
              {loading ? <CircularProgress color="inherit" size={24} /> : M.get('forgotPassword.send')}
            </Button>
          </Grid>
          <FormControl fullWidth variant="filled" >
            <Box component="div" sx={classes.backContainer} >
              <Link style={classes.back} id="back" to={routes.login.path}>
                <ArrowBackIcon sx={classes.arrow}/>
                <Box component="span" sx={classes.backTxt} >{M.get('forgotPassword.back')}</Box>
              </Link>
            </Box>
          </FormControl>
        </Grid>
      </form>
    </Box> ) : (
      <Box sx={classes.container} component={Paper} p={2} width={375} margin='12px auto' elevation={3}>
        <PageHeader
          generalPageTitle={M.get('forgotPassword.success.title')}
          onlyGeneralPageTitle
        />
        <Box src={emailSentImg} component="img" sx={classes.emailImg}  alt="emailSent"  />
        <Typography component="h3" sx={classes.title}> {M.get('forgotPassword.success.title')} </Typography>
        <Typography sx={classes.successDescription}>
          {M.get('forgotPassword.success.descriptionPart_1')} <Box component="span" sx={classes.activeText}>{data.email}</Box> {M.get('forgotPassword.success.descriptionPart_2')}
        </Typography>
      </Box>
  );
});

export default withAuthHOC(ForgotPassword);