import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
      fontWeight: 500
    }
  },
  box: {
    minWidth: '200px',
    marginRight: '16px'
  },
  colorizeSelected: {
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& > svg": {
      color: theme.palette.primary.contrastText,
    },
    "& div": {
      color: theme.palette.primary.contrastText,
    }
  }
}