import { en } from 'messages/en.messages';
// import { ru } from 'messages/ru.messages';
// import lsConstants from 'constants/local-storage'
// import lgConstants from 'constants/configuration';

// This is taken from Intl.getIntlMessages
const messages = {};

messages.get = (path) => {
  // let current = en;
  // if (localStorage.getItem(lsConstants.LANGUAGE) ===  lgConstants.language.RUSSIAN) {
  //   current = ru;
  // }
  const current = en;
  const pathParts = path.split('.');
  let message;
  try {
    message = pathParts.reduce((obj, pathPart) => obj[pathPart], current) || pathParts.reduce((obj, pathPart) => obj[pathPart], en);
  } catch (err) {
    message = pathParts.reduce((obj, pathPart) => obj[pathPart], en);
  } finally {
    if (message === undefined) {
      // eslint-disable-next-line no-unsafe-finally
      throw new ReferenceError(`Could not find Intl message: ${path}`);
    }
  }

  return message;
};

export default messages;
