import {
  deleteAllSpacesFromStr,
} from 'helpers/helper';
import { isEmail } from 'helpers/validatorHelper';
import { makeAutoObservable, action, extendObservable, computed } from 'mobx';

const defaultData = {
  name: '',
  phone: '',
  planException: false
};

const defaultDataChangePassword = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

const defaultDataChangePasswordUIState = {
  showPassword: false,
  showNewPassword: false,
  showRepeatPassword: false,
};

const defaultChangedData = {
  currentPassword: false,
  newPassword: false,
  repeatNewPassword: false,
};

const defaultChangeEmailData = {
  newEmail: '',
  oldEmailCode: '',
  newEmailCode: '',
  step: 0
};

const requiredFields = ['name'];

const requiredFieldsChangePass = [
  'currentPassword',
  'newPassword',
  'repeatNewPassword',
];
class UserProfile {

  getDefaultStoreProps = () => ({
    userProfileEditedData: {},
    userProfileData: JSON.parse(JSON.stringify(defaultData)),
    userProfileDataErrors: {},
    changePasswordData: JSON.parse(JSON.stringify(defaultDataChangePassword)),
    changePasswordDataErrors: {},
    changePasswordUIState: JSON.parse(JSON.stringify(defaultDataChangePasswordUIState)),
    changePasswordsChanged: JSON.parse(JSON.stringify(defaultChangedData)),
    changeEmailData: JSON.parse(JSON.stringify(defaultChangeEmailData)),
    changeEmailDataErrors: {},
    allowModalSubmit: false,
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  setUserProfileData = (data) => {
    this.userProfileData = data;
  };

  @action
  reinitializeUserProfileData = (data) => {
    if (data) {
      this.userProfileData = data;
    }
    this.userProfileEditedData = {};
    this.userProfileDataErrors = {};
  };

  @computed
  getUserProfileData = () => this.userProfileData;

  @computed
  getUserProfileEditedData = () => ({
      ...this.userProfileEditedData,
      ...(this.userProfileEditedData.name && {name: this.userProfileEditedData.name.trim()}),
  });

  @computed
  getChangePasswordData = () => this.changePasswordData;

  @action
  onChangeField = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && value.length > 0 && value.trim().length === 0) {
      return true;
    }
    this.userProfileData[e.target.name] = e.target.value;
    this.userProfileEditedData[e.target.name] = e.target.value;
    if (!value && requiredFields.includes(name)) {
      this.userProfileDataErrors[e.target.name] = 'required';
    } else {
      delete this.userProfileDataErrors[name];
    }
    return true;
  };

  @action
  onChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    this.userProfileData[name] = checked;
    this.userProfileEditedData[name] = checked;
    return true;
  }

  @action
  onChangePhoneField = (value) => {
    this.userProfileData.phone = value;
    this.userProfileEditedData.phone = value;
    if (!value && requiredFields.includes('phone')) {
      this.userProfileDataErrors.phone = 'required';
    } else {
      delete this.userProfileDataErrors.phone;
    }
    return true;
  };

  @action
  onChangeFieldChangePasswordForm = (e) => {
    const { name, value } = e.target;
    const newValue = deleteAllSpacesFromStr(value);
    this.changePasswordsChanged[name] = true;
    this.changePasswordData[name] = newValue;
    if (!newValue && requiredFieldsChangePass.includes(name)) {
      this.changePasswordDataErrors[name] = 'required';
    } else {
      delete this.changePasswordDataErrors[name];
    }
    return true;
  };

  @computed
  getUserProfileDataErrors = () => this.userProfileDataErrors;

  @computed
  getChangePasswordDataErrors = () => this.changePasswordDataErrors;

  @action
  clearChangePasswordDataAndErrors = () => {
    this.changePasswordData = JSON.parse(
      JSON.stringify(defaultDataChangePassword)
    );
    this.changePasswordDataErrors = {};
    this.changePasswordsChanged = JSON.parse(
      JSON.stringify(defaultChangedData)
    );
  };

  @computed
  isChangePasswordBtnActive = () => {
    if (
      !this.changePasswordData.currentPassword ||
      !this.changePasswordData.newPassword ||
      !this.changePasswordData.repeatNewPassword
    ) {
      return false;
    }
    return (
      this.changePasswordData.newPassword ===
      this.changePasswordData.repeatNewPassword
    );
  };

  @action
  setEyeIconStateByField = (eyeId) => {
    this.changePasswordUIState[eyeId] = !this.changePasswordUIState[eyeId];
  };

  // show password (eye icon)
  @computed
  getShowPasswordState = () => this.changePasswordUIState.showPassword;

  @computed
  getShowNewPasswordState = () => this.changePasswordUIState.showNewPassword;

  @computed
  getShowRepeatPasswordState = () =>
    this.changePasswordUIState.showRepeatPassword;

  @computed
  getChangeNewPSWChanged = () => this.changePasswordsChanged.newPassword;

  @computed
  getChangeRepeatPSWChanged = () =>
    this.changePasswordsChanged.repeatNewPassword;

  @computed
  validateChangePasswords = () =>
    this.changePasswordData.newPassword ===
    this.changePasswordData.repeatNewPassword;

  @action
  setAllowModalSubmit = (value) => {
    this.allowModalSubmit = value;
  };

  @computed
  getAllowModalSubmit = () => this.allowModalSubmit;

  @action
  onChangeImage = (e, name) => {
    this.userProfileData[name] = e;
    this.userProfileEditedData[name] = e;
    return true;
  };

  @action
  onChangeEmailField = (e) => {
    const { value } = e.target;
    const newValue = deleteAllSpacesFromStr(value);
    this.changeEmailData.newEmail = newValue;
    if (newValue && !isEmail(newValue)) {
        this.changeEmailDataErrors.newEmail = 'invalidEmail';
    } else {
        delete this.changeEmailDataErrors.newEmail;
    }
  }

  getChangeEmailDataErrors = () => this.changeEmailDataErrors;

  getChangeEmailData = () => this.changeEmailData;

  @action
  onChangeEmailData = (e) => {
    const { name, value } = e.target;
    this.changeEmailData[name] = value;
    return true;
  }

  @action
  clearEmailChangeData = () => {
    this.changeEmailData = JSON.parse(JSON.stringify(defaultChangeEmailData));
    this.changeEmailDataErrors = {};
  }
}

export default new UserProfile();
