import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import M from 'messages';
import classes from './styles';

function Modal({
  children,
  handleClose,
  handleSubmit,
  open,
  title,
  leftBtnText = M.get('actions.cancel'),
  middleBtnText = '',
  rightBtnText = M.get('actions.save'),
  disableSave = false,
  submitLoading = false,
  disableLeftBtn = false
}) {
  return (
    <Box sx={classes.modal}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={disableLeftBtn} color="primary" startIcon={<CancelIcon />}>
            {leftBtnText}
          </Button>
          {middleBtnText && <Button disabled={disableSave} onClick={handleSubmit} color="primary" startIcon={<SaveIcon />} sx={!disableSave && classes.save}>
            {middleBtnText}
          </Button>}
          {/* TODO understand when button not disabled */}
          <Button disabled={disableSave} onClick={handleSubmit} color="primary" startIcon={<SaveIcon />}>
            {submitLoading && <CircularProgress size={16} />}
            {!submitLoading && rightBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  leftBtnText: PropTypes.string,
  rightBtnText: PropTypes.string,
  middleBtnText: PropTypes.string,
  disableSave: PropTypes.bool,
  submitLoading: PropTypes.bool,
  disableLeftBtn: PropTypes.bool
};

export default Modal;