export default [
  {
    name: 'oldPassword',
    label: 'changePassword.oldPassword',
    type: 'text',
    required: true,
  },
  {
    name: 'newPassword',
    label: 'changePassword.newPassword',
    type: 'text',
    required: true,
  },
  {
    name: 'repeatNewPassword',
    label: 'changePassword.repeatNewPassword',
    type: 'text',
    required: true,
  },
];