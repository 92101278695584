import { makeAutoObservable, computed, action, runInAction, toJS } from "mobx";
import WineLabelsService from 'services/wineLabelsService';
import SettingsService from 'services/settingsService';
import logger from 'logger';
import IngredientsStore from 'store/ingredientsStore';

const requiredFields = ['name', 'producer', 'serviceProviderId', 'productType',  'alcoholByVolume', 'color', 'sweetness', 'fatContent', 'carbohydratesContent', 'proteinContent', 'saltContent', 'energyKJ', 'energyKcal'];
const floatFields = ['alcoholByVolume', 'fat', 'saturates', 'carbohydrates', 'sugars', 'protein', 'salt', 'energyKJ', 'energyKcal'];
let timer = null;

class FormStore {
    formData = {
        name: '',
        year: '',
        producer: '',
        serviceProviderId: null,
        countryId: null,
        region: '',
        subRegion: '',
        alcoholByVolume: '',

        productType: 'Wine',
        otherProductType: '',

        grapeVarieties: [],

        color: '',
        sweetness: '',

        protectedGeographicalIndication: '',
        otherProtectedGeographicalIndication: '',
        protectedGeographicalIndicationText: '',

        provenanceOption: '',
        provenanceCountryId: null,
        provenanceCustomLabel: '',

        alcoholization: '',
        dateOfMinimumDurability: '',

        negligibleFat: true,
        fat: null,
        saturates: null,

        negligibleCarbohydrates: false,
        sugars: null,
        carbohydrates: null,

        negligibleProtein: true,
        protein: null,
        negligibleSalt: false,
        salt: null,

        // to do meke it dynamic
        ingredients: [{
            ingredientId: 1,
            order: 1,
            type: 'rawMaterials',
        }],

        energyKJ: '',
        energyKcal: '',
    };

    culculationData = {
        organicAcid: 0,
        residualSugar: 0,
        alcoholVol: 0,
        alcoholEthanolVal: 0,
        polyolsGlycerinVal: 0,

        protein: 0,
        fat: 0,
        salatrims: 0,
        fibre: 0,

        energyKJ: 0,
        energyKcal: 0,
    };

    formDataIsLoaded = false;

    iframeUrl = '';

    draftLabelId = null;

    refreshIframe = null;

    labelId = null;

    countriesSelected = null;

    provenanceSelected = null;

    menuItemTypesSelected = null;

    wineTypesOptions = [];

    errors = {};

    initialState = {};

    spList = [];

    spListLoading = false;

    labelState = '';

    maxOrder = 1;

    constructor() {
        makeAutoObservable(this);
        this.initialState = toJS({
            formData: this.formData,
            culculationData: this.culculationData,
            formDataIsLoaded: this.formDataIsLoaded,
            iframeUrl: this.iframeUrl,
            draftLabelId: this.draftLabelId,
            refreshIframe: this.refreshIframe,
            labelId: this.labelId,
            countriesSelected: this.countriesSelected,
            provenanceSelected: this.provenanceSelected,
            menuItemTypesSelected: this.menuItemTypesSelected,
            wineTypesOptions: this.wineTypesOptions,
            errors: this.errors,
        });
    }

    getMaxOrder = () => {
        this.formData.ingredients.forEach((item) => {
            if (item.order > this.maxOrder) {
                this.maxOrder = item.order;
            }
        });
        this.maxOrder += 1;
        return this.maxOrder;
    }

    setOrder = (ingredients) => {
        ingredients.forEach((ingredient, index) => {
            try {
                this.formData.ingredients.filter(item => item.ingredientId === ingredient.ingredientId)[0].order = index + 1;
            } catch (error) {
                logger.error('Failed to fetch initial data', error);
            }
        });

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    }

    /* Culculation Section */
    setCalculatedField(name, value) {
        this.culculationData[name] = value;
    }

    /* Validation Section */

    resetErrors() {
        this.errors = {};
    }

    cleanProtectedGeographicalIndicationFields() {
        this.formData.protectedGeographicalIndicationText = '';
        this.formData.otherProtectedGeographicalIndication = '';
        this.errors.protectedGeographicalIndicationText = '';
        this.errors.otherProtectedGeographicalIndication = '';
    }

    validateNutritionFields(key) {
        if (this.formData[key] && !this.isFloat(this.formData[key])) {
            this.errors[key] = 'Incorect Value';
        } else if (this.formData[key] === '') {
            this.errors[key] = 'Required';
        }
    }

    validateCustomFields() {
        if (this.formData.provenanceOption) {
            if (this.formData.provenanceOption === 'custom') {
                this.validateFieldRequired('provenanceCustomLabel', this.formData.provenanceCustomLabel);
            } else {
                this.validateFieldRequired('provenanceCountryId', this.formData.provenanceCountryId);
            }
        } else if (this.formData.productType === 'Other') {
            this.validateFieldRequired('otherProductType', this.formData.otherProductType);
        }
        if (this.formData.alcoholByVolume && !this.isFloat(this.formData.alcoholByVolume)) {
            this.errors.alcoholByVolume = 'Incorect Value';
        }
        if (this.formData.grapeVarieties && this.formData.grapeVarieties.length) {
            this.formData.grapeVarieties.forEach((item, index) => {
                if (item.percentage && !this.isFloat(item.percentage)) {
                    this.errors[`grapeVarieties[${index}].percentage`] = 'Incorect Value';
                }
                if (item.typeId === '') {
                    this.errors[`grapeVarieties[${index}].typeId`] = 'Required';
                }
            });
        }

        if (!this.formData.negligibleFat) {
            this.validateNutritionFields('fat');
            this.validateNutritionFields('saturates');
        }
        if (!this.formData.negligibleCarbohydrates) {
            this.validateNutritionFields('carbohydrates');
            this.validateNutritionFields('sugars');
        }
        if (!this.formData.negligibleProtein) {
            this.validateNutritionFields('protein');
        }
        if (!this.formData.negligibleSalt) {
            this.validateNutritionFields('salt');
        }
    }

    validateForm() {
        Object.keys(this.formData).forEach((field) => {
            this.validateField(field, this.formData[field]);
        });
        this.validateCustomFields();
        return Object.values(this.errors).every(error => error === '');
    }

    validateFieldRequired = (name, value) => {
        if (value) {
            this.errors[name] = value === null || value === undefined || (typeof value === 'string' && value.trim() === '') ? 'Required' : '';
        } else {
            this.errors[name] = 'Required';
        }
    }

    validateField(name, value) {
        this.errors[name] = '';
        const simpleRequiredFields = [...requiredFields]
        const floatValidationFields = [...floatFields];

        if (this.formData.productType === 'Other') {
            simpleRequiredFields.push('otherProductType');
        }

        if (this.formData.protectedGeographicalIndication === 'Protected designation of origin (PDO)') {
            simpleRequiredFields.push('protectedGeographicalIndicationText');
        } else if (this.formData.protectedGeographicalIndication === 'Protected geographical indication (PGI)') {
            simpleRequiredFields.push('protectedGeographicalIndicationText');
        } else if (this.formData.protectedGeographicalIndication === 'Other protected area') {
            simpleRequiredFields.push('protectedGeographicalIndicationText');
            simpleRequiredFields.push('otherProtectedGeographicalIndication');
        }

        if (['wine of', 'produced in', 'product of', 'sekt of'].includes(this.formData.provenanceOption)) {
            simpleRequiredFields.push('provenanceCountryId');
        } else if (this.formData.provenanceOption === 'custom') {
            simpleRequiredFields.push('provenanceCustomLabel');
        }

        if (this.formData.alcoholization !== '') {
            simpleRequiredFields.push('dateOfMinimumDurability');
        }
        if (!this.formData.negligibleFat) {
            simpleRequiredFields.push('fat');
            simpleRequiredFields.push('saturates');
            floatValidationFields.push('fat');
            floatValidationFields.push('saturates');
        }
        if (!this.formData.negligibleCarbohydrates) {
            simpleRequiredFields.push('carbohydrates');
            simpleRequiredFields.push('sugars');
            floatValidationFields.push('carbohydrates');
            floatValidationFields.push('sugars');
        }
        if (!this.formData.negligibleProtein) {
            simpleRequiredFields.push('protein');
            floatValidationFields.push('protein');
        }
        if (!this.formData.negligibleSalt) {
            simpleRequiredFields.push('salt');
            floatValidationFields.push('salt');
        }
        if (simpleRequiredFields.includes(name)) {
            this.validateFieldRequired(name, value);
        }
        if (floatValidationFields.includes(name)) {
            if (value) {
                if (!this.isFloat(value)) {
                    this.errors[name] = 'Incorect Value';
                }
            }
        }

        if (['negligibleProtein', 'negligibleFat', 'negligibleCarbohydrates', 'negligibleSalt'].includes(name)) {
            if (name === 'negligibleFat' && value) {
                this.errors.fat = '';
                this.errors.saturates = '';
            } else if (name === 'negligibleCarbohydrates' && value) {
                this.errors.carbohydrates = '';
                this.errors.sugars = '';
            } else if (name === 'negligibleProtein' && value) {
                this.errors.protein = '';
            } else if (name === 'negligibleSalt' && value) {
                this.errors.salt = '';
            }
            return;
        }
        if (['fatContent', 'carbohydratesContent', 'proteinContent', 'saltContent'].includes(name)) {
            if (name === 'fatContent' && !this.formData.negligibleFat) {
                this.validateFieldRequired('fat', this.formData.fat)
                this.validateFieldRequired('saturates', this.formData.saturates)
            } else if (name === 'carbohydratesContent' && !this.formData.negligibleCarbohydrates) {
                this.validateFieldRequired('carbohydrates', this.formData.carbohydrates)
                this.validateFieldRequired('sugars', this.formData.sugars)
            } else if (name === 'proteinContent' && !this.formData.negligibleProtein) {
                this.validateFieldRequired('protein', this.formData.protein)
            } else if (name === 'saltContent' && !this.formData.negligibleSalt) {
                this.validateFieldRequired('salt', this.formData.salt)
            }
        }
    }
    /* Validation Section END */

    resetStore() {
        const initialStateCopy = toJS(this.initialState); // Deep copy to avoid mutations
        Object.assign(this, initialStateCopy);
    }

    onChangeCulculationData = (name, value) => {
        this.culculationData[name] = value;
    }

    /* grapeVarieties */

    getSelectedWineType = (item) => this.wineTypesOptions.find(option => option.id === item.typeId) || null;

    onChangeGrapeVarietyAutocomplete = (value, field, list, item, index) => {
        this.formData.grapeVarieties[index] = {
            ...item,
            [field]: value ? value.id : null,
        };
        this.errors[`grapeVarieties[${index}].${field}`] = '';
        if (!value) {
            this.errors[`grapeVarieties[${index}].${field}`] = 'Required';
        }
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    };

    onChangePercentage = (event, item, index) => {
        const { value } = event.target;
        this.formData.grapeVarieties[index] = {
            ...item,
            percentage: value,
        };
        this.errors[`grapeVarieties[${index}].percentage`] = '';
        if (value) {
            if (!this.isFloat(value)) {
                this.errors[`grapeVarieties[${index}].percentage`] = 'Incorect Value';
            }
        }
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    };

    handleAddGrapeVarietyRow = () => {
        this.formData.grapeVarieties.push({ key: Date.now().toString(), typeId: '', percentage: '' });
    };

    handleRemoveGrapeVarietyRow = (index) => {
        this.formData.grapeVarieties.splice(index, 1);
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    };
    /* grapeVarieties */

    handleChange = (name, value) => {
        if (timer) {
            clearTimeout(timer);
        }

        this.setField(name, value);

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    };

    /* county autocomplete */
    @action
    createFormChanged = (value = true) => {
        this.isCreateFormChanged = value;
    };

    @computed
    get countriesOptions() {
        return this.countries;
    }

    @computed
    get getSelectedOption() {
        return this.countriesSelected;
    };

    @computed
    get getProvenanceSelectedOption() {
        return this.provenanceSelected;
    };

    @action
    setSpList = (list) => {
        this.spList = list.slice().sort((a, b) => a.name.localeCompare(b.name));
    };

    @computed
    get getSpList() {
        return this.spList;
    };

    @action
    setSpListLoading = (value) => {
        this.spListLoading = value;
    };

    @computed
    get getSpListLoading() {
        return this.spListLoading;
    };

    @action
    setLabelState(value) {
        this.labelState = value;
    }

    @action
    setServiceProviderId = (id) => {
        if (this.labelState === 'create') {
            this.formData.serviceProviderId = id;
        }
    }

    @action
    handleIngredientsChange = (name, value) => {
        // this.formData[name] = value;
        const type = name;
        // remove all ingredients of this type
        this.formData.ingredients = this.formData.ingredients.filter(item => item.type !== type);
        // add new ingredients
        value.forEach(item => {
            this.formData.ingredients.push({ ingredientId: item.ingredientId, order: item.order, type });
        });
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            this.saveFormData();
        }, 2000);
    }

    onChangeAutocomplete = (newValue, name, addType) => {
        this.createFormChanged(); // to do check if form is changed need
        if (name === 'countryId') {
            if (newValue && typeof newValue === 'object') {
                this[`${addType}Selected`] = newValue;
                this.handleChange(name, newValue.id);
            } else if (!newValue) {
                this.handleChange(name, null);
                this[`${addType}Selected`] = null;
            }
        } else if (name === 'provenanceCountryId') {
            if (newValue && typeof newValue === 'object') {
                this.provenanceSelected = newValue;
                this.handleChange(name, newValue.id);
                delete this.errors[name];
            } else if (!newValue) {
                this.errors[name] = 'required';
                this.handleChange(name, null);
                this.provenanceSelected = null;
            }
        }
    }
    /* county autocomplete */

    setField(name, value) {
        this.formData[name] = value;
        const noValidateFields = ['year', 'image', 'producerImage', 'countryId', 'region', 'subRegion', 'style', 'allergens', 'rawMaterials', 'acidityps', 'preservatives', 'stabilizings', 'gases', 'sweetenings', 'liquors', 'others'];
        if (!noValidateFields.includes(name)) {
            this.validateField(name, value);
        }
    }

    resetFieldRequired(name) {
        this.formData[name] = null;
        this.errors[name] = '';
    }

    clearCustomFields(name, value = '') {
        this.formData[name] = value;
        this.errors[name] = '';
    }

    clearIngredientCustomFields(name) {
        this.formData.ingredients = this.formData.ingredients.filter(ingredient => ingredient.type !== name);
    }

    clearSelectedProvanceCountryId() {
        this.formData.provenanceCountryId = null;
        this.errors.provenanceCountryId = '';
    }

    clearSelectedProvanceCustomLabel() {
        this.formData.provenanceCustomLabel = '';
        this.errors.provenanceCustomLabel = '';
    }

    clearSelectedProvance() {
        this.provenanceSelected = null;
        this.clearSelectedProvanceCountryId();
        this.clearSelectedProvanceCustomLabel();
    }

    async fetchInitialData(isEdit, wineId = null, labelId = null, inMarket = null, marketData = null) {
        if (this.isFetchingData) {
            return; // Ignore subsequent calls if data fetching is in progress
        }

        const initializeIngredients = () => {
            const ingredients = [];
            if (this.formData.ingredients.length) {
                this.formData.ingredients.forEach(item => {
                    // Destructure `id` from `item`
                    const { ingredientId } = item;
                    // Find ingredient by `id` and destructure `name`
                    const { name } = this.ingredients.find(ingredient => ingredient.id === ingredientId);
                    ingredients.push({ name, value: ingredientId, type: item.type, order: item.order });
                });
            }
            ingredients.sort((a, b) => a.order - b.order);
            ingredients.forEach((item) => {
                IngredientsStore.addIngredient(item.name, item.value, item.type);
            });
        }

        const parseFloatToStrig = () => {
            floatFields.forEach(field => {
                if (this.formData[field] !== null) {
                    this.formData[field] = this.formData[field].toString();
                }
            });
        }

        this.isFetchingData = true; // Set the flag to indicate data fetching has started

        try {
            IngredientsStore.resetStore();
            if (inMarket) {
                this.formData = marketData.wineLabelData;
                this.iframeUrl = marketData.url;
                this.ingredients = marketData.ingredients;
                const [countriesResponse, wineTypesResponse] = await Promise.all([
                    SettingsService.getCountriesOptions(),
                    SettingsService.getWineTypesOptions()
                ]);
                runInAction(() => {
                    // Process countries data
                    const countriesData = countriesResponse.data.map(country => {
                        const item = {
                            id: country.id,
                            name: country.name
                        };
                        if (item.id === this.formData.countryId) {
                            this.countriesSelected = item;
                        }
                        if (item.id === this.formData.provenanceCountryId) {
                            this.provenanceSelected = item;
                        }
                        return item;
                    });

                    this.countries = countriesData;
                    this.wineTypesOptions = wineTypesResponse.data;

                    initializeIngredients();

                    parseFloatToStrig();
                });
            } else if (isEdit) {
                this.formData.status = 'Draft';
                delete this.formData.id;
                delete this.formData.createdAt;
                delete this.formData.updatedAt;
                delete this.formData.slug;
                const [wineLabelResponse, countriesResponse, wineTypesResponse] = await Promise.all([
                    WineLabelsService.createWineLabel(this.formData),
                    SettingsService.getCountriesOptions(),
                    SettingsService.getWineTypesOptions()
                ]);

                runInAction(() => {
                    // Update observables with fetched wine label data
                    const wineLabelData = wineLabelResponse.data;
                    this.iframeUrl = wineLabelData.url;
                    this.ingredients = wineLabelData.ingredients;
                    this.labelId = wineLabelData.id;
                    this.draftLabelId = wineLabelData.id;

                    // Process wine types options
                    this.wineTypesOptions = wineTypesResponse.data;

                    // Process countries data
                    const countriesData = countriesResponse.data.map(country => {
                        const item = {
                            id: country.id,
                            name: country.name
                        };

                        if (item.id === this.formData.countryId) {
                            this.countriesSelected = item;
                        }
                        if (item.id === this.formData.provenanceCountryId) {
                            this.provenanceSelected = item;
                        }

                        return item;
                    });

                    this.countries = countriesData;
                    parseFloatToStrig();
                    initializeIngredients();
                });
            } else if (wineId) {
                // get wine data (handle asynchronously in parallel if needed)
            } else if (labelId) {
                // get label data (handle asynchronously in parallel if needed)
            } else {
                this.resetStore();
                // Fetch createWineLabel, countries options, and wine types options in parallel
                const [wineLabelResponse, countriesResponse, wineTypesResponse] = await Promise.all([
                    WineLabelsService.createWineLabel(this.cleanUnvalidFields(this.formData)),
                    SettingsService.getCountriesOptions(),
                    SettingsService.getWineTypesOptions()
                ]);

                runInAction(() => {
                    // Update observables with fetched wine label data
                    const wineLabelData = wineLabelResponse.data;
                    this.iframeUrl = wineLabelData.url;
                    this.ingredients = wineLabelData.ingredients;
                    this.labelId = wineLabelData.id;
                    this.draftLabelId = wineLabelData.id;

                    // Process wine types options
                    this.wineTypesOptions = wineTypesResponse.data;

                    // Process countries data
                    const countriesData = countriesResponse.data.map(country => {
                        const item = {
                            id: country.id,
                            name: country.name
                        };

                        if (item.id === this.formData.countryId) {
                            this.countriesSelected = item;
                        }
                        if (item.id === this.formData.provenanceCountryId) {
                            this.provenanceSelected = item;
                        }

                        return item;
                    });

                    this.countries = countriesData;
                    IngredientsStore.addIngredient('Grapes', 1, 'rawMaterials');
                });
            }

        } catch (error) {
            logger.error('Failed to fetch initial data', error);
        } finally {
            runInAction(() => {
                this.isFetchingData = false; // Reset the flag when data fetching is complete
                this.formDataIsLoaded = true;
            });
        }
    }

    getDraftWineLableID = () => this.draftLabelId;

    removeDraftWineLable = async (draftLabelId) => {
        try {
            return await WineLabelsService.deleteWineLabel(draftLabelId);
        } catch (error) {
            logger.error('Failed to remove draft wine label', error);
            throw error;
        }
    }

    updatePublishedWineLable = (data, labelId) => {
        const { id, active, status, slug, createdAt, updatedAt, ...payload } = data;
        payload.status = "published";
        payload.draftLabelId = this.draftLabelId;
        return WineLabelsService.updateWineLabel(this.cleanUnvalidFields(payload), labelId);
    };

    isFloat = (value) => {
        const floatRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        return floatRegex.test(value);
    }

    cleanUnvalidFields = (data) => {
        const cleanedData = { ...data };
        const floatValidationFields = [...floatFields];
        floatValidationFields.forEach(field => {
            if (cleanedData[field] === '' || cleanedData[field] && !this.isFloat(cleanedData[field])) {
                cleanedData[field] = null;
            }
        });
        if (this.formData.grapeVarieties && this.formData.grapeVarieties.length) {
            cleanedData.grapeVarieties = this.formData.grapeVarieties.map(item => {
                const itemCopy = { ...item };
                if (item.percentage === '' || !this.isFloat(item.percentage)) {
                    itemCopy.percentage = null;
                }
                if (item.typeId === '' || item.typeId === null) {
                    return null;
                }
                return itemCopy;
            });
            // Remove null values from grapeVarieties array
            cleanedData.grapeVarieties = cleanedData.grapeVarieties.filter(item => item !== null);
        }
        return cleanedData;
    }

    updateDraftWineLabel = async (data, labelId) => {
        try {
            const { id, active, status, slug, createdAt, updatedAt, ...payload } = data;
            const cleanedPayload = this.cleanUnvalidFields(payload);
            await WineLabelsService.updateWineLabel(cleanedPayload, labelId);
        } catch (error) {
            logger.error('Failed to update wine label', error);
        }
    }

    async saveFormData() {
        try {
            await this.updateDraftWineLabel(this.formData, this.labelId);
            this.refreshIframe();
        } catch (error) {
            logger.error("Failed to save data", error);
        }
    }

    wineLabelDataHasError = () => !this.validateForm();

    createWineLabel = () => {
        const { id, active, status, slug, createdAt, updatedAt, ...payload } = this.formData;

        // Clear the timer to prevent draft save after manual save
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        payload.draftLabelId = this.draftLabelId;
        return WineLabelsService.publishWineLabel(this.cleanUnvalidFields(payload));
    }

    @action
    setWineLabelData = (data) => {
        this.formData = data;
    };

}

const formStore = new FormStore();
export default formStore;