import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
  },
  priceField: {
    width: '100%',
  },
  addIconContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: '20px',
  },
  addIcon: {
    width: '35px',
    height: '20px',
  },
  error: {
    fontSize: '12px',
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error
  },
  deleteIcon: {
    width: '35px',
    height: '20px',
    float: 'right',
    backgroundColor: '#f44336',
    '&:hover': {
        backgroundColor: '#d32f2f'
    }
  },
  placeholderColor: {
    marginTop: '0px',
    opacity: 0.7
  },
  selectRoot: {
    marginTop: '0px',
  }
};