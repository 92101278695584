import React from 'react';
import PropTypes from 'prop-types';
import FilterTextField from './filterTextField';
import FilterAutocompleteField from './filterAutocomplete';
import FilterSelectField from './filterSelect';
import FilterNumberRange from './filterNumberRange';
import FilterCustomAutocomplete from './filterCustomAutocomplete';
import FilterSelectMultiOption from './filterSelectMultiOption';
import FilterButton from './filterButton';

const textFieldList = ['text', 'date', 'number'];
const selectList = ['select'];
const numberRangeList = ['numberRange'];
const autocompleteList = ['autocomplete', 'multi-autocomplete'];
const customAutocompleteList = ['custom-multi-autocomplete', 'custom-autocomplete'];
const customMultiOptionSelectList = ['custom-multi-option-select'];
const buttonList = ['button'];

const CustomFilterFormItem = ({field, onFilterCallback, searchValue = '', filteredParams}) =>
  (
    textFieldList.includes(field.type) ? (
      <FilterTextField
        field={field}
        onFilterCallback={onFilterCallback}
        searchValue={searchValue}
      />
    ) : autocompleteList.includes(field.type) ? (
      <FilterAutocompleteField
        field={field}
        onFilterCallback={onFilterCallback}
        searchValue={searchValue}
        multiple={field.type.includes('multi')}
      />
    ) : selectList.includes(field.type) ? (
      <FilterSelectField
        field={field}
        onFilterCallback={onFilterCallback}
        searchValue={searchValue}
      />
    ) : numberRangeList.includes(field.type) ? (
      <FilterNumberRange
        field={field}
        onFilterCallback={onFilterCallback}
        filteredParams={filteredParams}
      />
    ) : customAutocompleteList.includes(field.type) ? (
      <FilterCustomAutocomplete
        field={field}
        onFilterCallback={onFilterCallback}
        filteredParams={filteredParams}
        searchValue={searchValue}
        multiple={field.type.includes('multi')}
      />
    ) : customMultiOptionSelectList.includes(field.type) ? (
      <FilterSelectMultiOption
        field={field}
        onFilterCallback={onFilterCallback}
        filteredParams={filteredParams}
      />
    ) : buttonList.includes(field.type) ? (
      <FilterButton
        field={field}
        onFilterCallback={onFilterCallback}
        filteredParams={filteredParams}
        searchValue={searchValue}
      />
    ) : null
  );

CustomFilterFormItem.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  searchValue:  PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  filteredParams: PropTypes.instanceOf(Object).isRequired,
}

export default CustomFilterFormItem;