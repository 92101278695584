import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';

import useDebounce from "components/common/customDebounceHook/useDebounce";

import M from 'messages';
import classes from './styles';

const MultiOptionSelect = ({ field, onFilterCallback, filteredParams }) => {
  const filter = filteredParams?.params?.filter;

  const memoizedFilterValue = useMemo(() => {
    const memoizedValues = {};
    field.id.forEach(item => {
      memoizedValues[item] = (filter && filter[item]) ? filter[item] : '';
    })
    return memoizedValues;
  }, [field.id, filter]);

  const [searchTerm, setSearchTerm] = useState(memoizedFilterValue);
  const debouncedValue = useDebounce(searchTerm, 1000);
  const [selected, setSelected] = useState([]);

  const getSelected = () => {
    let newChecked;
    field.options.forEach(option => {
      if (searchTerm[option.optionName]) {
        newChecked = option.id;
      }
    });
    return newChecked;
  }

  useEffect(() => {
    setSelected(getSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const setField = (id) => {
    const termOptions = {};
    field.options.forEach(option => {
      if (id && (option.id === id)) {
        termOptions[option.optionName] = typeof option.value === 'object' ? JSON.stringify(option.value) : option.value;
      } else {
        termOptions[option.optionName] = '';
      }
    });
    setSelected(id);
    setSearchTerm(termOptions);
  }

  const fetchData = () => {
    const fetchedOptions = {};
    if (filter) {
      field.id.forEach(item => {
        if (filter[item]) {
          fetchedOptions[item] = filter[item];
        } else {
          fetchedOptions[item] = '';
        }
      })
    }
    setSearchTerm(fetchedOptions);
  }

  useEffect(() => {
    fetchData();
    return () => {
      setSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, field.options]);

  const handleOnChang = (e) => {
    const { value: id } = e.target;
    setField(id);
  }

  return (
    <Box sx={classes.box}>
      <FormControl margin='dense' fullWidth variant="outlined">
        <InputLabel size='small' sx={classes.label} shrink={false}>{selected ? "" : M.get(`${field.label}`)}</InputLabel>
        <Select
          variant='outlined'
          value={selected || ''}
          onChange={handleOnChang}
          name={field.id}
          sx={{
            ...classes.selectElement,
            ...(selected && classes[field.selectedClassName]),
            ...classes.selectRoot,
          }}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }}>
          <MenuItem value="" key='empty'> <em><Box sx={classes.menuItem}>{field.insteadNone || M.get('filterFields.none')}</Box></em> </MenuItem>
          {field.options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id} >
              <Box sx={classes.menuItem} style={field.maxWidth ? { maxWidth: field.maxWidth } : {}}>
                {opt.title}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MultiOptionSelect.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
}

export default MultiOptionSelect;
