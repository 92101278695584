import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  popoverBtn: {
    minHeight: '40px',
    color: theme.palette.primary.sideBarTextColor,
    opacity: '0.8',
    textTransform: 'capitalize'
  },
  popoverContainer: {
    backgroundColor: 'white'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 8px 0 8px'
  },
  paper: {
    padding: '8px 0',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)'
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "10px"
    },
    "& > label": {
      padding: '3px 16px 7px 16px'
    },
    "& > div": {
      "&:hover:not(.Mui-disabled):before": {
        borderBottomWidth: '1px'
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      appearance: 'none',
    },
    '& > div:after': {
      borderBottomWidth: '1px'
    },
    "& > div > input": {
      padding: '3px 16px 7px 16px',
      fontWeight: 500,
      fontSize: '14px'
    }
  },
  primaryIconButton: {
    marginLeft: '16px'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  placeholder: {
    opacity: 0.5,
  },
  valueText: {
    margin: '8.82px 9px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '150px',
    maxWidth: '210px',
    fontSize: '14px',
    fontWeight: '500',
  },
  rangeInput: {
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    display: 'inline-flex',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'rgba(0, 0, 0)',
    },
  },
  arrowIcon: {
    paddingTop: '6px',
    width: '30px',
    '& > svg': {
      opacity: 0.6
    }
  },
  colorizeSelected: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& > div > input": {
      fontWeight: 500,
      fontSize: '14px',
      color: '#fff'
    },
    "& svg":{
      color: '#fff',
      opacity: 1
    }
  },
  box: {
    minWidth: '200px',
    marginRight: '16px'
  }
};