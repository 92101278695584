import { replacePath } from "helpers/helper";
import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";
import CataloguesService from 'services/cataloguesService';
import kiosksMenuService from "services/kiosksMenuService";

const defaultData = {
  menuName: '',
  description: '',
  menuItems: [],
};

const defaultParams = {
  params: {
    sort: {
      field: "createdAt",
      order: "desc"
    },
    filter: {},
    limit: 50,
    skip: 0
  }
};

const requiredFields = ["menuName", "serviceProviderId"];

class KiosksMenus {

  getDefaultStoreProps = () => ({
    kiosksMenus: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
    kioskMenuState: {},
    kioskMenuData: {},
    kioskMenuDataErrors: {},
    selectedMenuItems: [],

    // catalogs data for service provider
    spCatalogsData: [],
    spCatalogsDataLoaded: false,
    spCatalogsDataLoading: false,

    defaultsCountLoaded: false,
    defaultsCountLoading: false,
    defaultsCount: 0,

    spList: [],
    pageName:'',
  })

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreKiosksMenus(newData) {
    this.kiosksMenus.data = [];
    newData.data.forEach(this.addStoreKioskMenu);
    this.kiosksMenus.count = newData.count;
  }

  @action
  addStoreKioskMenu = (element) => {
    this.kiosksMenus.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

  @action
  setKioskMenuState(value) {
    this.kioskMenuState = value;
  }

  @action
  clearSpCatalogData = () => {
    this.spCatalogsData = [];
    this.spCatalogsDataLoaded = false;
    this.spCatalogsDataLoading = false;
    this.defaultsCount = 0;
    this.defaultsCountLoaded = false;
    this.defaultsCountLoading = false;
  }

  @action
  clearKioskMenuDataErrors = () => {
    this.kioskMenuDataErrors = {};
  }

  @action
  clearKioskMenuMainData = () => {
    this.selectedMenuItems = [];
  }

  @action
  clearKioskMenuAllData = () => {
    this.kioskMenuData = JSON.parse(JSON.stringify(defaultData));
    this.kioskMenuData.serviceProviderId = '';
    this.clearKioskMenuMainData();
  }

  @action
  initializeKioskMenuData = (currentSP) => {
    this.clearKioskMenuAllData();
    if (currentSP && currentSP !== 'unset' && currentSP !== 'allSP') {
      this.kioskMenuData.serviceProviderId = currentSP;
    }
  }

  getStoreKioskMenuForCreate = () => this.kioskMenuData;

  @action
  setServiceProviderId = (id) => {
    if (this.kioskMenuState === 'create' && id !== 'unset' && id !== 'allSP' && id !== this.kioskMenuData.serviceProviderId) {
      this.kioskMenuData.serviceProviderId = +id;
      this.clearSpCatalogData();
      this.globalSPChanged();
    }
  }

  @action
  setMenuItemsFromUrl = (selectedItems) => {
    const menuItemsFromUrl = [];
    selectedItems.forEach(item => {
      if(menuItemsFromUrl.find(value => value.id === item)) {
        return;
      }
      const selectedItem = this.spCatalogsData.find(spCatalog => spCatalog.id === item);
      if (selectedItem) {
        menuItemsFromUrl.push(selectedItem)
        this.onChangeItemsField('menuItems', menuItemsFromUrl, null, true, { option: selectedItem }, 'select-option')
      }
    })
  }

  @action
  globalSPChanged = () => {
    if (this.kioskMenuState === 'create') {
      delete this.kioskMenuData.menuItems;
      this.clearKioskMenuMainData();
    }
  }

  @action
  onChangeField = (e) => {
    const { name, value } = e.target;
    this.kioskMenuData[name] = value;
    if (!value && requiredFields.includes(name)) {
      this.kioskMenuDataErrors[e.target.name] = 'required';
    } else {
      delete this.kioskMenuDataErrors[name];
    }
    if (name === 'serviceProviderId') {
      this.globalSPChanged();
      this.clearSpCatalogData();
      this.getSpCatalogsData(value)
      this.getSpDefaultMenusCount(value)
    }
    return true;
  }

  @action
  updateData = (data) => {
    this.kioskMenuData = {
      ...this.kioskMenuData,
      ...data
    };
    return true;
  }

  @action
  onChangeItemsField = (_event, value, _select, selectedOption) => {
    const newValue = value.map((item, index) => {
      if (item.id === selectedOption.option.id) {
        return {
          ...item,
          order: (index + 1).toString()
        };
      }
      return item;
    });
    this.selectedMenuItems = newValue;
    return true;
  }

  @action
  onChangeCheckBox = (e) => {
    this.kioskMenuData[e.target.name] = e.target.checked;
  }

  getKioskMenuData = (isEdit) => {
    if (isEdit) {
      delete this.kioskMenuData.active
      delete this.kioskMenuData.serviceProvider
    }
    delete this.kioskMenuData.menuItems

    return {
      ...this.kioskMenuData,
      menuItemsIds: this.selectedMenuItems.map(menuItem => menuItem.id)
    }
  }


  getSelectedMenuItems = () => this.selectedMenuItems;

  setSortedSelectedMenuItems = (sortedItems) => {
    this.selectedMenuItems = sortedItems;
  }

  getMaxMenuNameSymbols = () => 255;

  setKioskMenuData = (data) => {
    this.clearSpCatalogData();
    this.kioskMenuData = data;
    this.selectedMenuItems = data.menuItems;
    if (data.serviceProviderId) {
      this.getSpCatalogsData(data.serviceProviderId);
      this.getSpDefaultMenusCount(data.serviceProviderId);
    }
  }

  getKioskMenuDataErrors = () => this.kioskMenuDataErrors;

  kioskMenuDataHasError = () => {
    let hasError = false;
    requiredFields.forEach(i => {
      if (!this.kioskMenuData[i]) {
        this.kioskMenuDataErrors[i] = 'Required';
        hasError = true;
      } else {
        this.kioskMenuDataErrors[i] = '';
      }
    });
    return hasError;
  };

  isSpCatalogsDataLoaded = () => this.spCatalogsDataLoaded;

  @computed
  getCatalogsDataForSp() {
    return this.spCatalogsData;
  }

  loadSpCatalogsData = async (spId) => {
    this.spCatalogsDataLoading = true;
    try {
      const response = await CataloguesService.getCatalogues({params: { currentServiceProvider: spId }});
      if (response?.data?.data) {
        this.spCatalogsData = [];
        response.data.data.forEach((menuItem) => {
          const order = this.selectedMenuItems?.findIndex(item => item.id.toString() === menuItem.id.toString());
          const menuItemData = {
            id: menuItem.id.toString(),
            name: menuItem.name,
            producer: menuItem.producer,
            wineCountry: menuItem.country?.name || '',
            year: menuItem.year,
            color: menuItem.color,
            style: menuItem.style,
            sweetness: menuItem.sweetness,
            isSuperVino: menuItem.isSuperVino,
            tableOrWineNumber: menuItem.tableOrWineNumber,
            order: (order + 1).toString(),
            active: menuItem.active
          };
          if (this.kioskMenuData.menuItems?.length && this.kioskMenuData.menuItems.find(item => Number(item.id) === Number(menuItem.id))) {
            const menuItemIndex = this.kioskMenuData.menuItems.findIndex(item => Number(item.id) === Number(menuItem.id));
            this.selectedMenuItems[menuItemIndex] = menuItemData;
          }
          this.spCatalogsData.push(menuItemData)
        });
        this.spCatalogsDataLoaded = true;
        this.spCatalogsDataLoading = false;
      }
    } catch (err) {
      this.spCatalogsDataLoading = false;
    }
  }

  getSpCatalogsData = async (spId) => {
    if (this.spCatalogsDataLoaded) {
      return this.spCatalogsData;
    }
    if (this.spCatalogsDataLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.spCatalogsDataLoaded) {
            resolve(this.spCatalogsData)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadSpCatalogsData(spId);
    return this.spCatalogsData;
  }

  loadDefaultsCount = async (spId) => {
    this.defaultsCountLoading = true;
    try {
      const { data } = await kiosksMenuService.getDefaultsCount(spId);
      this.defaultsCount = data.count;
      this.defaultsCountLoaded = true;
      this.defaultsCountLoading = false;

    } catch (err) {
      this.defaultsCountLoading = false;
    }
  }

  getSpDefaultMenusCount = async (spId) => {
    if (this.defaultsCountLoaded) {
      return this.defaultsCount;
    }
    if (this.defaultsCountLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.defaultsCountLoaded) {
            resolve(this.defaultsCount)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadDefaultsCount(spId);
    return this.defaultsCount;
  }

  isSpDefaultMenusCountLoaded = () => this.defaultsCountLoaded;

  getDefaultsCount = () => this.defaultsCount;

  onRemoveMenuItem = (menuItemId) => {
    this.selectedMenuItems.forEach((selectedMenuItem, selectedMenuItemIndex) => {
      if (selectedMenuItem.id === menuItemId) {
        this.selectedMenuItems.splice(selectedMenuItemIndex, 1);
      }
    })
  }

  @action
  setSpList = (list) => {
    this.spList = list;
  };

  @computed
  get getSpList() {
    return this.spList;
  };

  @action
  setCurrentSP = (navigate, location) => {
    if (location.pathname.substring(1) === this.pageName) {
      replacePath(navigate, defaultParams);
    }
    this.setFilter(defaultParams);
  }

  @action
  setPageName = (value) => {
    this.pageName = value;
  }

}

export default new KiosksMenus();