import { createTheme } from '@mui/material/styles';

// Dark theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      contrastText: '#fff',
      colorLight: '#424242',
      colorDark: '#303030',
      notActiveTextColor: '#FFFFFFB3',
      activeTextColor: '#FFFFFF',
      activeIconColor: 'black',
      footerColor: '#f5fbfb',
      footerTextColor: '#FFFFFF',
      headerColor: '#1A5787',
      headerIconsColor: '#FFFFFF',
      lightBlue: '#EAECF7',
      blue: '#3f51b5',
      tableRowSuccess: '#c6ecc6',
      tableRowError: '#ffcccc',
      backgroundColor: '#303030',
      notActiveIconColor: '#8d8d8d',
      error: '#f44336',
      errorHover: '#ebd7e0',
      scrollColor: '#FFFFFFB3',
      mainBackground: '#303030',
      sideBarTextColor: '#FFFFFF',
      sideBarBgColor: '#303030',
      sideBarIconColor: '#FFFFFF',
    },
    secondary: {
      light: '#5F9EA0',
      main: '#008B8B',
      dark: '#2F4F4F',
      contrastText: '#fff',
      notActiveTextColor: '#FFFFFFB3',
      activeTextColor: '#FFFFFF',
      activeIconColor: 'black',
      footerColor: '#f5fbfb',
      footerTextColor: '#FFFFFF',
      headerColor: '#1A5787',
      headerIconsColor: '#FFFFFF',
      lightBlue: '#EAECF7',
      blue: '#3f51b5',
      tableRowSuccess: '#c6ecc6',
      tableRowError: '#ffcccc',
      backgroundColor: '#303030',
      notActiveIconColor: '#8d8d8d',
      error: '#f44336',
      errorHover: '#ebd7e0',
      scrollColor: '#FFFFFFB3',
      mainBackground: '#303030',
      sideBarTextColor: '#FFFFFF',
      sideBarBgColor: '#303030',
      sideBarIconColor: '#FFFFFF',
    },
    error: {
      main: '#F2453D',
    }
  },
});

export default theme;