import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  Avatar,
  Divider,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material';
import domainData from 'store/domainStore';
import userProfileStore from 'store/userProfileStore';
import usersStore from 'store/usersStore';
import SidebarStore from 'store/ui/sidebarStore';
import settingsStore from 'store/settingsStore';
import UsersService from 'services/usersService';
import SubscriptionService from 'services/subscriptionService';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import ProfileImageInput from 'components/common/form/profileImageInput';
import { routes } from 'config/index';
import { getMessage } from 'helpers/helper';
import { isPartner } from 'helpers/usersRolesHelper';
import M from 'messages';

import AccountDetailsForm from './config/components';
import Subscription from './subscription';
import classes from './styles';

const Profile = observer(({isProfile}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = domainData.getCurrentUser();
  const [loading, setLoading] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [activePlans, setActivePlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const [manageBillingLoading, setManageBillingLoading] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [planType, setPlanType] = useState('standard');
  const isSidebarOpened = SidebarStore.sidebarState;

  const { enqueueSnackbar } = useSnackbar();
  const data = userProfileStore.getUserProfileData();
  const editedData = userProfileStore.getUserProfileEditedData();

  const isCurrentUserPartner = isPartner(currentUser);

  usersStore.getUsersRolesData();

  if (location.pathname.includes('/profile') && +id !== +currentUser.id) {
    navigate(routes.notFound.path);
  }

  const getProfile = useCallback(
    async (currentPlanType) => {
      try {
        setLoading(true);
        const response = await UsersService.getUser(id);

        if (response && Object.keys(response.data).length) {
          userProfileStore.setUserProfileData({...response.data, usersRolesId: response.data.usersRole.id});
          setInitialData({...response.data, usersRolesId: response.data.usersRole.id});
          setActivePlans(response.data.subscriptions.map(subscription => {
            const plan = {
              planId: subscription.planId,
              priceId: subscription.priceId,
              status: subscription.status,
              cancelAtPeriodEnd: subscription.cancelAtPeriodEnd,
              type: subscription.subscriptionPlan.type,
              stripeId: subscription.stripeId
            }
            if (plan.type === (currentPlanType || planType)) {
              setCurrentPlan(plan);
            }
            return plan;
          }));
          setLoading(false);
        }
      } catch (err) {
        if (err.message === 'Request failed with status code 403') {
          navigate(routes.notFound.path);
        }
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    if (currentUser.id) {
      getProfile();
    }
    return () => {
      if (currentUser.id) {
        userProfileStore.reinitializeUserProfileData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfile, currentUser.id, id]);

  const getSubscriptionPlans = useCallback(
    async () => {
      try {
        const response = await SubscriptionService.getSubscriptionPlans();
        if (response?.data) {
          setSubscriptionList(response.data);
        }
      } catch (err) {
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (isProfile && isCurrentUserPartner) {
      getSubscriptionPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfile, isCurrentUserPartner]);

  useEffect(() => {
    settingsStore.getIsShowEuPlan();
  }, []);

  const updateProfile = async (newData) => {
    try {
      const response = await UsersService.updateUser({ ...newData }, id);
      if (isProfile && +id === +currentUser.id) {
        const {id: currUserId, phone, email, name, lastLoginDate, serviceProviderId, image} = response.data.user;
        const currentUserUpdatedData = {id: currUserId, phone, email, name, lastLoginDate, serviceProviderId, image};
        domainData.setCurrentUser({...currentUser, ...currentUserUpdatedData});
      }
      enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      getProfile();
      setDisableSave(false);
    } catch (err) {
      setDisableSave(false);
      setLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const handleManageBillingButton = async () => {
    try {
      setManageBillingLoading(true);
      const response = await SubscriptionService.createCustomerPortal();
      window.location.href = response.data.url;
      setManageBillingLoading(false);
    } catch (err) {
      setManageBillingLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
        variant: 'error',
      });
    }
  };

  const changePassword = async (newData) => {
    try {
      const response = await UsersService.changePassword({ ...newData }, id);
      enqueueSnackbar(getMessage(response.data), { variant: 'success' });
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const handleSubmit = () => {
    setDisableSave(true);
    updateProfile(editedData);
  }
  const handleClickCancel = () => {
    if (currentUser.id) {
      userProfileStore.reinitializeUserProfileData(initialData);
    }
  }
  const isFormChanged = () => initialData && data ? JSON.stringify(initialData) !== JSON.stringify(data) : false;
  const dataErrors = userProfileStore.getUserProfileDataErrors();
  const hasError = () => Object.keys(dataErrors).length > 0;

  const changePlanType = (type) => {
    setPlanType(type)
    setCurrentPlan(activePlans.find(plan => plan.type === type));
  }

  if (loading || !settingsStore.isShowEuPlanLoaded()) return <Loading />;
  return (
    <Box
      sx={{...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={isProfile ? M.get('profile.pageTitle') : `${M.get('account.pageTitle')} | ${initialData.name || ''}`}
        onlyGeneralPageTitle
      />
      <Card sx={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box sx={classes.avatar}>
            {
              isProfile ? (
                <ProfileImageInput
                  onChange={(e) => userProfileStore.onChangeImage(e, 'image')}
                  defaultValue={data.image}
                  userName={data.name.charAt(0)}
                  label={M.get('actions.edit')}
                />
              ) : (
                <Avatar src={data.image} sx={classes.avatarIcon}>
                  {data.name.charAt(0)}
                </Avatar>
              )
            }
          </Box>
          <Box sx={classes.accountDetails} >
            <AccountDetailsForm
              submitChangePassword={changePassword}
              isProfile={isProfile}
              initialProfileData={initialData}
              getProfile={getProfile}
            />
            <Box sx={classes.actionsButtons} mt={4} ml={2} mb={2} >
              <Button
                role="button"
                onClick={handleClickCancel}
                disabled={disableSave || !isFormChanged()}
                sx={classes.cancelButton}
                color="primary"
              >
                {M.get('actions.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={classes.cancelButton}
                onClick={handleSubmit}
                disabled={disableSave || !isFormChanged()  || hasError()}
              >
                {M.get('form.saveChanges')}
              </Button>
            </Box>
          </Box>
          {((isProfile && isCurrentUserPartner) || !isProfile) && (
            <Grid container>
              <Grid item xs={12}>
                <Divider  />
              </Grid>
              <Grid container sx={classes.subscriptionTitleContainer}>
                <Grid item xs={8}>
                  <Typography sx={classes.subscriptionTitleText}>
                    {M.get('subscription.title')}
                  </Typography>
                </Grid>
                {isProfile && <Grid item xs={4} sx={classes.customerPortal}>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      ...classes.primaryIconButton,
                      '&.Mui-disabled': classes.disabledButton,
                      '&:hover': classes.primaryButtonHover
                    }}
                    disabled={manageBillingLoading}
                    onClick={handleManageBillingButton}
                  >
                    {manageBillingLoading
                      ? <CircularProgress color="inherit" size={24} />
                      : M.get('subscription.customerPortal')
                    }
                  </Button>
                </Grid>}
              </Grid>
              <Grid container spacing={2} justifyContent="center" mb={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      ...classes.primaryIconButton,
                      ...planType !== 'standard' ? classes.inactiveButton : {},
                      '&.Mui-disabled': classes.disabledButton,
                      '&:hover': classes.primaryButtonHover
                    }}
                    onClick={() => changePlanType('standard')}
                  >
                    {M.get('subscription.types.standard')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      ...classes.primaryIconButton,
                      ...planType !== 'eu' ? classes.inactiveButton : {},
                      '&.Mui-disabled': classes.disabledButton,
                      '&:hover': classes.primaryButtonHover
                    }}
                    onClick={() => changePlanType('eu')}
                    disabled={!settingsStore.getShowEuPlan()}
                  >
                    {M.get('subscription.types.eu')}
                  </Button>
                </Grid>
              </Grid>
              {currentPlan 
                ? currentPlan.status === 'active'
                  ? currentPlan.cancelAtPeriodEnd
                    ? <Alert severity="warning" sx={classes.errorMessage}>{M.get('subscription.statuses.canceled')}</Alert>
                    : <Alert severity="success" sx={classes.errorMessage}>{M.get('subscription.statuses.active')}</Alert>
                  : <Alert severity="error" sx={classes.errorMessage}>{M.get('subscription.statuses.error')}</Alert>
                : <Alert severity="info" sx={classes.errorMessage}>{M.get('subscription.statuses.noSubscription')}</Alert>
              }
              <Grid container spacing={4}>
                {(isProfile ? subscriptionList : initialData.subscriptions.map(i => i.subscriptionPlan)).map((subscription) => (
                  planType === subscription.type && <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={subscription.id} >
                    <Subscription
                      subscription={subscription}
                      currentPlan={currentPlan}
                      getProfile={getProfile}
                      isProfile={!!isProfile}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
});

export default Profile;
