export default {
    emailInput: {
        margin: '15px 0',
        width: '100%',
    },
    button: {
        textTransform: 'none',
        marginRight: '15px',
        marginBottom: '15px'
    }
};
