import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, FormControlLabel, Switch, Grid, Box, Typography, FormControl, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Protein = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;
    const handleNegligibleProteinChange = (event) => {
        handleChange({ target: { name: event.target.name, value: event.target.checked } });
        if (event.target.checked) {
            handleChange({ target: { name: 'protein', value: '' } });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={classes.fieldHeader}>
                    <Typography sx={classes.fieldHeaderText}>{`${M.get('labels.proteinTitle')} *`}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <FormControlLabel
                        control={
                            <Switch
                                name="negligibleProtein"
                                checked={formData.negligibleProtein}
                                onChange={handleNegligibleProteinChange}
                                color="primary"
                            />
                        }
                        label={M.get('labels.proteinLabel')}
                        disabled={disabled}
                    />
                </FormControl>
            </Grid>
            {!formData.negligibleProtein && (
                <>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={`${M.get('labels.protein')}`}
                                name="protein"
                                required
                                value={formData.protein || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}
                                variant="outlined"
                                error={!!errors?.protein}
                                helperText={
                                    errors?.protein ?
                                        errors.protein : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <>
                                                  <Typography
                                                        sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                    >
                                                    <b>According to Regulation (EU) 1169/2011</b>, protein content is calculated using the formula: protein = total Kjeldahl nitrogen × 6,25. This component generally does not exist or is present in negligible amounts in wines.
                                                  </Typography>
                                                </>
                                              }
                                        >
                                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider sx={classes.divider} />
            </Grid>
        </Grid>
    );
});

export default Protein;
