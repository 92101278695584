export default {
    requirementGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    requirementTxt: {
      textAlign: 'right',
      color: '#9D9D9D',
      fontWeight: 400,
      fontSize: '0.75rem',
      margin: '4px 0'
    },
    requirementTxtError: {
      textAlign: 'right',
      color: '#F2453D',
      fontWeight: 400,
      fontSize: '0.75rem',
      margin: '4px 0'
    },
    requirement: {
      color: '#9D9D9D',
      width: '12px',
      height: '12px',
      marginRight: '9px'
    },
    validRequirement: {
      color: 'green',
      width: '12px',
      height: '12px',
      marginRight: '9px'
    }
  }