import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  box: {
    marginRight: '16px'
  },
  mainElement: {
    textTransform: 'none',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    display: 'inline-flex',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'rgba(0, 0, 0)',
    },
  },
  colorizeSelected: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& > div > input": {
      fontWeight: 500,
      fontSize: '14px',
      color: '#fff'
    },
    "& svg":{
      color: '#fff',
      opacity: 1
    }
  },
  valueTextContainer: {
    margin: '8.82px 9px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    fontWeight: '500',
  },
  valueText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    fontWeight: '500',
    display: 'flex'
  },
  fieldValue: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  placeholder: {
    opacity: 0.5,
  },
  arrowIcon: {
    paddingTop: '6px',
    width: '30px',
    '& > svg': {
      opacity: 0.6
    }
  },
  paper: {
    padding: '0',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)'
  },
  popoverContainer: {
    backgroundColor: 'white',
    maxWidth: '450px'
  },
  popoverNoOptions: {
    backgroundColor: 'white',
    maxWidth: '265px',
    maxHeight: '224px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '400px',
    padding: 0
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    }
  },
  selected: {
    backgroundColor: 'rgba(27, 88, 135, 0.1)'
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  clearOptionsBtn: {
    fontWeight: 400,
    fontSize: 14,
    textTransform: 'none',
    width: '100%',
    placeContent: 'start',
    paddingLeft: 16,
    minHeight: '50px',
    borderRadius: 0,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
  },
  searchInput: {
    minHeight: '50px',
    padding: '0 16px'
  },
  searchRoot: {
    '&::before': {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
    '&::after': {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
    "&:focus-within:not(.Mui-disabled):before": {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
    "&:focus-within:not(.Mui-disabled):after": {
      borderBottom: '1px solid rgba(61, 61, 61, 0.3)'
    },
  },
  adornedEnd: {
    paddingRight: '16px',
    cursor: 'pointer',
    '& > svg': {
      color: theme.palette.primary.sideBarIconColor,
      opacity: 0.8,
      padding: '5px',
      '&:hover': {
        backgroundColor: '#3d3d3d0c',
        borderRadius: '50%',
      }
    }
  },
  noFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  helpIcon: {
    height: '54px',
    width: '54px',
    margin: '33px 0'
  },
  noMatchesText: {
    marginBottom: '30px',
    fontSize: '14px',
    fontWeight: '400'
  },
  noneField: {
    boxShadow: '0px 0px 3px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
  }
};