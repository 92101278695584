import M from 'messages';

export default [
  {
    id: 1,
    value: 'winery',
    title: M.get('serviceProvider.type.winery')
  },
  {
    id: 2,
    value: 'restaurant',
    title: M.get('serviceProvider.type.restaurant')
  },
  {
    id: 3,
    value: 'importer',
    title: M.get('serviceProvider.type.importer')
  },
  {
    id: 4,
    value: 'eventOrganizer',
    title: M.get('serviceProvider.type.eventOrganizer')
  },
  {
    id: 5,
    value: 'other',
    title: M.get('serviceProvider.type.other')
  }
]