import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid2';
import settingsStore from 'store/settingsStore';
import dashboardStore from 'store/dashboardStore';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import ComingSoon from 'components/common/comingSoon';
import DashboardLayout from './dashboardLayout';

const Dashboard = observer(() => {
  const { loading } = dashboardStore;

  useEffect(() => {
    settingsStore.getDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!settingsStore.getShowDashboard()) {
    return <ComingSoon />;
  }

  return loading ? (
    <Loading />
  ) : (
      <Grid container spacing={2} sx={{ padding: 3, width: "100%" }}>
        <Grid item size={{xs: 12}}>
          <PageHeader generalPageTitle="Dashboard" onlyGeneralPageTitle />
        </Grid>
        <Grid item size={{xs: 12}}>
          <DashboardLayout dashboardStore={dashboardStore} />
        </Grid>
      </Grid>
  );
});

export default Dashboard;