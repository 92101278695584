import ServiceProvidersService from 'services/serviceProvidersService';
import { serviceProvidersOptionData } from 'helpers/adapter';

const wineLabelOptions = {
  fields: [
    { id: 'id', label: 'table.wineLabels.id', type: 'text', sortable: true, width: '7%' , show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
    { id: 'serviceProviderId', label: 'table.wineLabels.partner', type: 'text', sortable: true, width: '20%', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
    { id: 'producer', label: 'table.wineLabels.producer', type: 'text', sortable: true, width: '20%' },
    { id: 'name', label: 'table.wineLabels.name', type: 'text', sortable: true, width: '15%' },
    { id: 'country', label: 'table.wineLabels.country', type: 'text', sortable: false, width: '15%' },
    { id: 'year', label: 'table.wineLabels.year', type: 'text', sortable: true, width: '15%' },
    { id: 'color', label: 'table.wineLabels.color', type: 'text', sortable: true, width: '15%' },
    { id: 'energyKcal', label: 'table.wineLabels.energyKcal', type: 'text', sortable: true, width: '15%' },
    { id: 'status', label: 'table.wineLabels.published', type: 'text', sortable: true, width: '15%' },
    { id: 'createdAt', label: 'table.wineLabels.createdAt', type: 'noWrap',  sortable: true, width: '10%' },
  ],
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  filterFields: [
    {id: 'serviceProviderId_in', label: 'wineLabels.filter.partner', type: 'custom-multi-autocomplete', options: ServiceProvidersService.getServiceProvidersNames, adapterCallback: serviceProvidersOptionData, withoutParams: true, priority: 1,  optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true, show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
  ],
  searchFields: ['name', 'producer'],
  colorGroup: {
    label: 'menuItem.colorTitle',
    name: 'color',
    defaultValue: 'red',
    fields: [
      {value: 'red', label: 'menuItem.red'},
      {value: 'white', label: 'menuItem.white'},
      {value: 'orange', label: 'menuItem.orange'},
      {value: 'rose', label: 'menuItem.rose'},
    ]
  },
  /*
  'Brut Nature', 'Extra Brut', 'Brut', 'Extra Dry', 'Dry (Sec)', 'Demi-Sec', 'Doux', 'Dry', 'Off Dry', 'Medium Dry', 'Medium', 'Medium Sweet', 'Sweet',
  */
  sweetnessGroup: {
    label: 'menuItem.sweetnessTitle',
    name: 'sweetness',
    defaultValue: 'red',
    fields: [
      {value: 'Brut Nature', label: 'labels.sweetness.brutNature'},
      {value: 'Extra Brut', label: 'labels.sweetness.extraBrut'},
      {value: 'Brut', label: 'labels.sweetness.brut'},
      {value: 'Extra Dry', label: 'labels.sweetness.extraDry'},
      {value: 'Dry (Sec)', label: 'labels.sweetness.drySec'},
      {value: 'Demi-Sec', label: 'labels.sweetness.demiSec'},
      {value: 'Doux', label: 'labels.sweetness.doux'},
      {value: 'Dry', label: 'labels.sweetness.dry'},
      {value: 'Off Dry', label: 'labels.sweetness.offDry'},
      {value: 'Medium Dry', label: 'labels.sweetness.mediumDry'},
      {value: 'Medium', label: 'labels.sweetness.medium'},
      {value: 'Medium Sweet', label: 'labels.sweetness.mediumSweet'},
      {value: 'Sweet', label: 'labels.sweetness.sweet'}
    ]
  },
  /*
  'Still', 'Semi-Sparkling', 'Sparkling', 'Fortified', 'Desert'
  */
  styleGroup: {
    label: 'menuItem.styleTitle',
    name: 'style',
    defaultValue: 'still',
    fields: [
      {value: 'Still', label: 'labels.style.still'},
      {value: 'Semi-Sparkling', label: 'labels.style.semiSparkling'},
      {value: 'Sparkling', label: 'labels.style.sparkling'},
      {value: 'Fortified', label: 'labels.style.fortified'},
      {value: 'Desert', label: 'labels.style.desert'},
    ]
  },
}

export default wineLabelOptions;