import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Button } from '@mui/material';
import classes from './styles';

const CustomToolbarAction = ({
    handleOnClick,
    startIcon = null,
    btnText,
    tooltipText = '',
    className = '',
    variant = 'text',
    disabled = false,
}) => (
    <Box sx={classes.box}>
        <Tooltip title={tooltipText}>
            <Button
                sx={{...classes.icon, ...classes[className]}}
                startIcon={startIcon}
                onClick={handleOnClick}
                variant={variant}
                disabled={disabled}
                color='inherit'
            >
                {btnText}
            </Button>
        </Tooltip>
    </Box>
);


CustomToolbarAction.propTypes = {
    handleOnClick: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    className: PropTypes.string,
    startIcon: PropTypes.node,
    variant: PropTypes.string,
    disabled: PropTypes.bool
}

export default CustomToolbarAction;