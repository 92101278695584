import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Modal from 'components/common/customModal';
import userProfileStore from 'store/userProfileStore';
import usersStore from 'store/usersStore';
import domainData from 'store/domainStore';
import userActivateOptions from 'constants/user-activate-options.js';

import { useModal } from 'hooks/common';
import {
  isSuperAdmin,
  isPartner,
} from 'helpers/usersRolesHelper';
import M from 'messages';
import ChangePasswordModal from '../changePasswordModal';
import ChangeEmailModal from '../changeEmailModal';
import classes from './styles';

const AccountDetailsForm = observer(
  ({
    submitChangePassword,
    isProfile,
    initialProfileData,
    getProfile
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenEmailChangeModal = () => {
      setIsModalOpen(true);
    };

    const handleCloseEmailChangeModal = async (_event, _action, emailUpdated) => {
      if (emailUpdated) {
        await getProfile();
      }
      userProfileStore.clearEmailChangeData();
      setIsModalOpen(false);
    };

    const currentUser = domainData.getCurrentUser();
    const isUserSuperAdmin = isSuperAdmin(currentUser);
    const isUserPartner = isPartner(currentUser);
    if (isUserSuperAdmin || isUserPartner) {
      usersStore.getUsersRolesData();
    }
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const data = userProfileStore.getUserProfileData();

    const handleCloseModal = () => {
      userProfileStore.clearChangePasswordDataAndErrors();
      closeModal();
    };

    const handleChangePassword = () => {
      const changePasswordData = userProfileStore.getChangePasswordData();
      submitChangePassword(changePasswordData);
      handleCloseModal();
    };

    return <>
      {isProfile && ( <>
        <ChangeEmailModal
            open={isModalOpen}
            handleClose={handleCloseEmailChangeModal}
        />
        <Modal
          open={isOpenModal}
          handleSubmit={handleChangePassword}
          handleClose={handleCloseModal}
          disableSave={
            !userProfileStore.getAllowModalSubmit() ||
            !userProfileStore.isChangePasswordBtnActive()
          }
          title={M.get('account.changePassword')}
          leftBtnText={M.get('actions.cancel')}
          rightBtnText={M.get('actions.save')}
          actionFooterStyle="accountActionColumn"
          dialogContentClassName="accountModalContent"
          modalClassName="accountModals"
          submitBtnVariant="contained"
          footerBtnStyle="accountBtnStyle"
          dialogHeader="changePasswordModalTitle"
        >
          <ChangePasswordModal initialProfileData={initialProfileData} />
        </Modal>
      </>)}
      <Grid container sx={classes.detailsContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={classes.fields}>
          <Grid item xs={12} sm={5}>
            <Typography sx={classes.fieldName}>
              {M.get('account.name')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextField
              type="text"
              id="name"
              name="name"
              variant="standard"
              sx={classes.fieldValue}
              placeholder={M.get('account.name')}
              required
              value={data.name}
              onChange={userProfileStore.onChangeField}
              InputProps={{
                inputProps: { maxLength: 255 }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.fields}>
          <Grid item xs={5}>
            <Typography sx={classes.fieldName}>
              {M.get('account.email')}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1} sx={classes.emailField}>
              <Grid item xs={10}>
                <Typography sx={classes.fieldValue}>
                  {data.email}
                </Typography>
              </Grid>
              {isProfile && <Grid item xs={2} sx={classes.changeIconContainer}>
                <Box sx={classes.InputAdornment}>
                  <EditIcon onClick={handleOpenEmailChangeModal} />
                </Box>
              </Grid>}
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.fields}>
          <Grid item xs={5}>
            <Typography sx={{...classes.fieldName, ...classes.fieldNameWithTooltip}}>
              {M.get('account.phone')}
              <Tooltip placement="top" title={M.get('account.phoneTooltip')}>
                <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <MuiTelInput                         
              id="phone"
              name="phone"
              sx={classes.fieldValue}
              value={data.phone}
              variant="standard"
              required
              defaultCountry='US'
              onChange={userProfileStore.onChangePhoneField}
            />
            <Divider />
          </Grid>
        </Grid>
        {isProfile && (
          <Grid item xs={12} sx={classes.fields}>
            <Grid item xs={5}>
              <Typography sx={classes.fieldName}>
                {M.get('account.password')}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Button
                sx={classes.button}
                onClick={() => openModal()}
                color="primary"
                variant="text"
              >
                {M.get('account.changePassword')}
              </Button>
              <Divider />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={classes.fields}>
          <Grid item xs={5}>
            <Typography sx={classes.fieldName}>
              {M.get('account.role')}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={classes.fieldValue}>
              {M.get(`usersRoles.${data.usersRole.name}`)}
            </Typography>
            <Divider />
          </Grid>
        </Grid>
        {!isProfile && isUserSuperAdmin && (
          <>
            <Grid item xs={12} sx={classes.fields}>
              <Grid item xs={5}>
                <Typography sx={classes.fieldName}>
                  {M.get('account.activityStatus')}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <Select
                    variant="standard"
                    value={data.isActive}
                    onChange={userProfileStore.onChangeField}
                    id="isActive-select"
                    name="isActive"
                  >
                    {userActivateOptions.map((opt) => (
                      <MenuItem key={opt.id} value={opt.value}>
                        {opt.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={classes.fields}>
              <Grid item xs={12} sx={classes.fields}>
                <Grid item xs={5}>
                  <Typography sx={classes.fieldName}>
                    {M.get('account.planException')}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel
                    variant="outlined"
                    labelPlacement="start"
                    control={
                      <Switch
                        id="planException"
                        color="primary"
                        name="planException"
                        onChange={userProfileStore.onChangeCheckBox}
                        checked={data.planException}
                      />
                    }
                    sx={classes.planException}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>;
  }
);

AccountDetailsForm.propTypes = {
  submitChangePassword: PropTypes.func.isRequired,
  isProfile: PropTypes.bool,
  initialProfileData: PropTypes.shape({}).isRequired,
  getProfile: PropTypes.func.isRequired,
};

AccountDetailsForm.defaultValues = {
  isProfile: false,
};

export default AccountDetailsForm;
