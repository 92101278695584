import M from 'messages';

export default [
  {
    id: 1,
    value: 'active',
    title: M.get('serviceProvider.accountStatus.active')
  },
  {
    id: 2,
    value: 'inactive',
    title: M.get('serviceProvider.accountStatus.inactive')
  }
]