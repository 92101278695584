import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  tableCellAction: {
    padding: '6px 16px',
    color: theme.palette.primary.sideBarIconColor,
    opacity: '0.7',
    paddingRight: '18px!important',
    '& > a': {
      '& > svg': {
        marginTop: '4px',
        padding: '6px!important',
        fontSize: '1.2rem',
        '& > path': {
          color: theme.palette.primary.sideBarIconColor,
        },
      },
      '& > svg:hover': {
        background: 'rgba(61, 61, 61, 0.05)',
        borderRadius: '50%'
      },
      '& > span': {
        marginTop: '4px',
        padding: '6px!important',
        fontSize: '1.2rem'
      },
      '& > span:hover': {
        background: 'rgba(61, 61, 61, 0.05)',
        borderRadius: '50%'
      }
    },
    '& > svg': {
      marginTop: '4px',
      padding: '6px!important',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    '& > svg:hover': {
      background: 'rgba(61, 61, 61, 0.05)',
      borderRadius: '50%'
    },
    '& > span': {
      marginTop: '4px',
      padding: '6px!important',
      fontSize: '1.2rem'
    },
    '& > span:hover': {
      background: 'rgba(61, 61, 61, 0.05)',
      borderRadius: '50%'
    }
  },
  removeCell: {
    textAlign: 'end',
    '& > svg': {
      fontSize: '1.5rem!important',
    }
  },
  imgItem : {
    width: '24px',
    height: '28px',
    marginTop: '6px'
  },
  listItemText: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'revert',
    }
  },
  tableCellItem: {
    wordBreak: 'break-word'
  },
  sortedCeil: {
    fontWeight: '500!important'
  },
  copyIcon: {
    cursor: 'pointer'
  },
  textWithComponentRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& p': {
      width: '70%'
    }
  },
  textWithComponent: {
    width: '30%',
    padding: '3px 8px',
    margin: '0 10px',
    backgroundColor: '#e1e1e1',
    textAlign: 'center',
    fontSize: '12px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noWrap: {
    whiteSpace: 'nowrap',
    paddingRight: '10px'
  },
};