export default {
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: '1px',
  },
  tableHeadRow: {
    height: '56px',
    '& > th': {
      fontWeight: '500'
    }
  }
};