import { get, post } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getSubscriptionPlans: async () => {
    const options = { url: apiEndpoints.subscriptionPlans };
    return get(options);
  },
  checkDashboardAccess: async () => {
    const options = { url: apiEndpoints.checkDashboardAccess };
    return get(options);
  },
  checkLabelAccess: async () => {
    const options = { url: apiEndpoints.checkLabelAccess };
    return get(options);
  },
  subscribe: async (data) => {
    const options = { url: apiEndpoints.subscribe, data };
    return post(options);
  },
  confirmSubscription: async (data) => {
    const options = { url: apiEndpoints.confirmSubscription, data };
    return post(options);
  },
  createCustomerPortal: async () => {
    const options = { url: apiEndpoints.createCustomerPortal };
    return post(options);
  },
}
