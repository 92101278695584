import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import M from 'messages';
import useDebounce from "components/common/customDebounceHook/useDebounce";
import domainData from 'store/domainStore';
import CustomAutocomplete from './customAutocomplete';
import classes from './styles';

const CustomFilterAutocomplete = ({field, onFilterCallback, searchValue = '', multiple = false}) => {
  const [searchTerm, setSearchTerm] = React.useState(searchValue);
  const debouncedValue = useDebounce(typeof searchTerm === 'object' ? searchTerm : {[field?.id]: searchTerm}, 1000);
  const [modifiedOptions, setModifiedOptions] = useState([]);

  useEffect(() => {
      setSearchTerm({[field.id]: searchValue});
  }, [field, searchValue]);

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const getCurrentServiceProvider = () => {
    const currentSP = domainData.getCurrentSP();
    const currSP = currentSP && currentSP !== 'allSP' ? { currentServiceProvider: currentSP } : {};
    return currSP;
  }

  useEffect(() => {
    if(typeof field.options === 'function') {
      const newParams = { params: {...getCurrentServiceProvider()} };
      field.options(newParams).then(res => {
        setModifiedOptions(field.adapterCallback(res.data.data));
      })
    } else {
      setModifiedOptions(field.options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  const getSelectedValue = () => {
    if (!multiple) {
      return (searchTerm?.[field.id] || (searchTerm?.[field.id] === 0 ||  searchTerm?.[field.id] === false)) &&  searchTerm?.[field.id] !== undefined ? modifiedOptions.find(i => i.value === (searchTerm?.[field.id]) || +i.value === + (searchTerm?.[field.id] || '') ) : '';
    }
    if (searchTerm?.[field.id] && searchTerm?.[field.id].length) {
      return modifiedOptions.filter(i => searchTerm?.[field.id].includes(i.value) || searchTerm?.[field.id].includes(i.value.toString()));
    }
    return [];
  }

  const getSelectedClassName = () => {
    if (multiple) {
      return getSelectedValue().length ? field.selectedClassName : '';
    }
    return getSelectedValue() ? field.selectedClassName : '';
  }

  const handleClearOptions = () => {
    setSearchTerm({[field.id]: multiple ? [] : ''});
  }

  return (
    <Box sx={classes.box}>
      <FormControl margin='dense' fullWidth variant="outlined">
        <CustomAutocomplete
          items={modifiedOptions}
          selectedValues={getSelectedValue()}
          selectedClassName={getSelectedClassName()}
          onToggleOption={(selectedOptions) => setSearchTerm({[field.id]: selectedOptions})}
          onRemoveAll={() => handleClearOptions()}
          onClearOptions={() => handleClearOptions()}
          getOptionLabel={option => option.title || ''}
          inputLabel={getSelectedClassName() ? '' : M.get(field.label)}
          size="small"
          limitTags={1}
          multiple={multiple}
          withCheckbox={field.optionsWithCheckbox}
          icon={field.icon}
          iconClassName={field.iconClassName}
        />
      </FormControl>
    </Box>
  );
};

CustomFilterAutocomplete.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  searchValue:  PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  multiple: PropTypes.bool
}

export default CustomFilterAutocomplete;