import {variables} from 'config';

export default ({
  appBar: {
    flexGrow: 1,
    boxShadow: 'none',
    backgroundColor: 'primary.headerColor',
  },
  toolBar: {
    backgroundColor: 'primary.headerColor',
    minHeight: variables.headerHeight,
    color: 'primary.sideBarTextColor',
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,

    '@media (max-width: 980px)': {
      paddingLeft: '5px',
      paddingRight: '5px',
    }
  },
  menuButton: {
    marginRight: '36px',
    color: 'white'
  },
  rightPart: {
    width: '100%',
    textAlign: 'end',
  },
  headerIcons: {
    color: 'primary.headerIconsColor',
    width: '36px',
    height: '36px',
    '& > path': {
      width: '36px',
      height: '36px',
    }
  },
  headerIconsRegion: {
    color: 'primary.headerIconsColor',
    width: '34px',
    height: '27px'
  },
  configurationMenu: {
    padding: '30px'
  },
  ellipsis: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'bottom'
  },
  ellipsisSM: {
    width: '50vw',
  },
  ellipsisMD: {
    width: '65vw',
  },
  ellipsisLG: {
    width: '75vw',
  },
  verticalAlign: {
    verticalAlign: 'sub'
  },
  logo: {
    width: '82px',
    height: '22px',
    marginRight: '15px'
  },
  headerBadge: {
    "&::after": {
      position: "absolute",
      top: 16,
      left: 16,
    }
  },
  headerAvatars: {
    cursor: 'pointer'
  },
  regionImg: {
    width: '34px',
  },
  avatar: {
    width: '33px',
    height: '33px',
    fontSize: '14px',
    fontWeight: '500',
  },
  menuIcon: {
    color: '#000',
    width: '24px',
    height: '24px',
    '& > path': {
      width: '36px',
      height: '36px',
    }
  },
  spView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spViewLogo: {
    width: '36px',
    marginRight: '15px',
    objectFit: 'cover',
    textAlign: 'center',
    textIndent: '10000px',
    borderRadius: '20%',
  },
  logoWine: {
    maxWidth: '160px',
  }
});