import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import kiosksMenuStore from 'store/kiosksMenusStore';
import M from 'messages';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import classes from '../styles';

const DraggableTableRow = ({ item }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: String(item.order),
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    background: isDragging ? '#f0f0f0' : !item.active ? 'rgb(227 227 227)' : 'white',
    cursor: 'grab',
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <TableCell>
        <DragIndicatorIcon sx={classes.dragIcon} />
      </TableCell>
      <TableCell style={{ width: '19%' }}>{item.name}</TableCell>
      <TableCell style={{ width: '19%' }}>{item.producer}</TableCell>
      <TableCell style={{ width: '15%' }}>{item.wineCountry}</TableCell>
      <TableCell style={{ width: '7%' }}>{item.year}</TableCell>
      <TableCell style={{ width: '7%' }}>{item.color}</TableCell>
      <TableCell style={{ width: '7%' }}>{item.style}</TableCell>
      <TableCell style={{ width: '7%' }}>{item.sweetness}</TableCell>
      <TableCell style={{ width: '7%' }}>{item.isSuperVino ? 'Yes' : 'No'}</TableCell>
      <TableCell style={{ width: '12%' }}>{item.tableOrWineNumber || 'N/A'}</TableCell>
    </TableRow>
  );
};

DraggableTableRow.propTypes = {
  item: PropTypes.shape({
    order: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.string,
    sweetness: PropTypes.string,
    isSuperVino: PropTypes.bool,
    active: PropTypes.bool,
    producer: PropTypes.string,
    wineCountry: PropTypes.string,
    tableNumber: PropTypes.string,
    tableOrWineNumber: PropTypes.number,
  }),
};

const DraggableTable = () => {
  const [sortConfig, setSortConfig] = useState(null);
  const [displayedItems, setDisplayedItems] = useState(kiosksMenuStore.getSelectedMenuItems()); // Initially load items from the store

  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }
    const oldIndex = displayedItems.findIndex(item => String(item.order) === active.id);
    const newIndex = displayedItems.findIndex(item => String(item.order) === over.id);
    const newItems = arrayMove(displayedItems, oldIndex, newIndex);

    setDisplayedItems(newItems);
    kiosksMenuStore.setSortedSelectedMenuItems(newItems); // Update store
  };

  const handleSortRequest = (field) => {
    const direction = sortConfig?.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ field, direction });

    const sortedItems = [...displayedItems].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setDisplayedItems(sortedItems);
    kiosksMenuStore.setSortedSelectedMenuItems(sortedItems); // Update store
  };

  return (
    !!displayedItems.length && (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={displayedItems.map(item => String(item.order))} strategy={verticalListSortingStrategy}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ width: '19%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'name'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('name')}
                    >
                      {M.get('table.menuMenuItems.name')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '19%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'producer'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('producer')}
                    >
                      {M.get('table.menuMenuItems.producer')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '15%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'wineCountry'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('wineCountry')}
                    >
                      {M.get('table.menuMenuItems.wineCountry')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'year'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('year')}
                    >
                      {M.get('table.menuMenuItems.year')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'color'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('color')}
                    >
                      {M.get('table.menuMenuItems.color')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'style'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('style')}
                    >
                      {M.get('table.menuMenuItems.style')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'sweetness'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('sweetness')}
                    >
                      {M.get('table.menuMenuItems.sweetness')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'isSuperVino'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('isSuperVino')}
                    >
                      {M.get('table.menuMenuItems.isSuperVino')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: '12%' }}>
                    <TableSortLabel
                      active={sortConfig?.field === 'tableOrWineNumber'}
                      direction={sortConfig?.direction || 'asc'}
                      onClick={() => handleSortRequest('tableOrWineNumber')}
                    >
                      {M.get('table.menuMenuItems.tableNumber')}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedItems.map((item) => (
                  <DraggableTableRow key={item.order} item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>
    )
  );
};

export default DraggableTable;
