import getTheme from "config/themes/base";

const theme = getTheme();

export default {
  detailsContainer: {
    marginTop: '25px',
  },
  fields: {
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldValue: {
    wordWrap: 'break-word',
    width: '100%',
    '& > div > input': {
      color: theme.palette.primary.sideBarTextColor,
      '&:disabled': { opacity: 0.6 },
    },
  },
  InputAdornment: {
    color: '#666666',
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  success: {
    color: '#4CAF50',
  },
  error: {
    color: theme.palette.primary.error,
  },
  button: {
    padding: '6px 0',
    textTransform: 'none',
  },
  rootSelectGrid: {
    width: '100%',
    '& > div:first-of-type': {
      width: '100%',
    },
  },
  rootSelect: {
    width: '100%',
  },
  menuPaper: {
    maxHeight: '300px',
  },
  siteAccess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 0 6px 0',
    fontSize: '16px',
    fontWeight: 400,
  },
  siteAccessButton: {
    padding: '0',
  },
  paper: {
    marginTop: '48px',
  },
  rootAutoComplete: {
    '&:before': {
      borderBottom: 'none!important',
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  displayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  emailField: {
    marginBottom: '4px'
  },
  changeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldHeaderIcon: {
    color: theme.palette.primary.sideBarIconColor,
    opacity: '0.6',
    cursor: 'pointer',
    marginLeft: '8px'
  },
  fieldNameWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  planException: {
    marginLeft: 0,
},
};
