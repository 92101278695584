import { makeAutoObservable } from 'mobx';
import { arrayMove } from '@dnd-kit/sortable';

class IngredientsStore {
    ingredients = [];

    constructor(initialIngredients = []) {
        this.ingredients = initialIngredients;
        makeAutoObservable(this);
    }

    resetStore() {
        this.ingredients = [];
    }

    getMaxIngredientsId() {
        if (this.ingredients.length === 0) {
            return 1;
        }
        return Math.max(...this.ingredients.map((ingredient) => parseInt(ingredient.id, 10))) + 1;
    }

    // Method to add a new ingredient
    addIngredient(name, value, type) {
        const newId = this.getMaxIngredientsId().toString();
        this.ingredients.push({ id: newId, name, ingredientId: value, type });
    }

    // Method to remove an ingredient by name
    removeIngredient(name) {
        this.ingredients = this.ingredients.filter(ingredient => ingredient.name !== name);
    }

    // Method to remove all gases ingredients
    removeGasesIngredient() {
        this.ingredients = this.ingredients.filter(ingredient => ingredient.type !== 'gases');
    }

    // Method to reorder ingredients
    reorderIngredients(oldIndex, newIndex) {
        this.ingredients = arrayMove(this.ingredients, oldIndex, newIndex);
        return this.ingredients;
    }

    // Method to get the ordering from the sorted array
    getOrderedIngredients() {
        return this.ingredients;
    }
}

const store = new IngredientsStore();
export default store;
