import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  container: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px',
    boxShadow: 'none',
    textAlign: 'left',

    '@media (min-width: 1000px)': {
      padding: '24px 80px',
    },
    '@media (min-width: 1536px)': {
      padding: '24px 200px',
    }
  },
  successContainer: {
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '& img': {
      width: '200px',
      height: '200px',
    }
  },
  title: {
    textAlign: 'left',
    fontSize: '17px',
    margin: '0 0 35px 0'
  },
  submit: {
    height: '40px',
    textTransform: 'none',
  },
  emailImg: {
    marginBottom: '32px',

    '& img': {
      backgroundColor: 'red'
    },
    '& svg': {
      fill: 'red !important'
    },
    '& svg path': {
      fill: 'red !important'
    },
  },
  emailColor: {
    color: theme.palette.primary.main,
  },
  footerPart: {
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'end',
  },
  loading: {
    backgroundColor: 'white',
  },
  successDescription: {
    textAlign: 'left',
    fontSize: 16,
    margin: '0 0 0 0'
  },
  contactPerson: {
    fontWeight: 600,
    marginTop: '25px',
    fontSize: '18px',
    marginBottom: '0px',
  },
  fieldHeader: {
    margin: '0',
    '& > p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
  },
    fieldHeaderText: {
      marginRight: '10px',
      fontWeight: '600',
      fontSize: '14px',
      color: theme.palette.primary.sideBarTextColor,
  },
  formControl: {
    marginTop: '5px',
  },
  fieldHeaderIcon: {
    color: theme.palette.primary.sideBarIconColor,
    opacity: '0.6',
    cursor: 'pointer',
    marginLeft: '8px'
  },
  fieldHeaderWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  helperDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '19px'
},
}