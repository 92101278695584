import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Link } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import AddIcon from '@mui/icons-material/Add';

import M from 'messages';
import classes from './styles';

function EmptyData({
  title,
  withCreate = true,
  onCreateClick = null,
  icon = null,
  titleClassName = '',
  hasLink = false,
  link = '',
  linkText = '',
  rootClassName = 'root',
  mainClassName = 'main'
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const onCreate = () => {
    if (onCreateClick && typeof onCreateClick === 'function') {
      onCreateClick();
    } else {
      navigate(`${location.pathname}/create`);
    }
  }

  return (
    <Box
      sx={{...classes[rootClassName],
        ...(!!titleClassName && rootClassName !== 'createOrderRoot' && classes.backgroundWhite)
      }}
    >
      <Box sx={classes[mainClassName]}>
        {icon || <InboxIcon sx={classes.icon}/>}
        {hasLink ?
          (<Box sx={classes.container}>
            <Typography variant="h1" sx={titleClassName ? classes[titleClassName] : classes.title}>
              {M.get(`emptyPage.${title}Empty`).split("{link}")[0] || ''} <Link component={RouterLink} sx={classes.link} to={link}>{linkText}</Link> {M.get(`emptyPage.${title}Empty`).split("{link}")[1] || ''}
            </Typography>
          </Box>) :
          (<Typography
            variant="h1"
            sx={titleClassName ? classes[titleClassName] : classes.title}
          >
            {title ? M.get(`emptyPage.${title}Empty`) : M.get(`emptyPage.noData`)}
          </Typography>)
        }
        {withCreate &&
          <>
            <Typography variant="h6" sx={classes.description}> {M.get('emptyPage.addOne')} </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={onCreate}>
              {M.get('actions.create')}
            </Button>
          </>
        }
      </Box>
    </Box>
  );
}

EmptyData.propTypes = {
  title: PropTypes.string.isRequired,
  withCreate: PropTypes.bool,
  onCreateClick: PropTypes.func,
  icon: PropTypes.node,
  titleClassName: PropTypes.string,
  hasLink: PropTypes.bool,
  link: PropTypes.string,
  linkText: PropTypes.string,
  rootClassName: PropTypes.string,
  mainClassName: PropTypes.string
}

export default EmptyData;