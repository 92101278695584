import { get } from 'services/client';
import { apiEndpoints } from 'config';

const dashboardService = {
  getKPIData: async (startDate, endDate) => {
    const options = { 
      url: apiEndpoints.dashboardKPIs,
      params: { startDate, endDate }
    };
    return get(options);
  },

  getViewsByColor: async (startDate, endDate) => {
    const options = {
      url: apiEndpoints.dashboardViewsByColor,
      params: { startDate, endDate }
    };
    return get(options);
  },
  getViewsBySweetness: async (startDate, endDate) => {
    const options = {
      url: apiEndpoints.dashboardViewsBySweetness,
      params: { startDate, endDate }
    };
    return get(options);
  },
  getViewsByStyle: async (startDate, endDate) => {
    const options = {
      url: apiEndpoints.dashboardViewsByStyle,
      params: { startDate, endDate }
    };
    return get(options);
  },

  getAverageRatingByWine: async (startDate, endDate) => {
    const options = {
      url: apiEndpoints.dashboardAverageRatingByWine,
      params: { startDate, endDate }
    };
    return get(options);
  },

  getViewsByCountry: async (startDate, endDate) => {
    const options = {
      url: apiEndpoints.dashboardViewsByCountry,
      params: { startDate, endDate }
    };
    return get(options);
  }
};

export default dashboardService;
