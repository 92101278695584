import getTheme from "config/themes/base";

const theme = getTheme();

export default {
  container: {
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px',
    boxShadow: '-6px 4px 15px rgba(0, 0, 0, 0.05), 6px 4px 20px rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1
  },
  title: {
    fontSize: 17,
    fontWeight: 600,
    margin: '0 0 35px 0'
  },
  eyeIcon: {
    padding: '6px'
  },
  requirementContainer: {
    padding: '24px 8px 0 8px'
  },
  submit: {
    height: '40px',
    textTransform: 'capitalize'
  },
  submitDisabled: {
    height: '40px',
    textTransform: 'capitalize',
    opacity: 0.6,
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  forgotPasswordLink: {
    color: theme.palette.primary.sideBarTextColor,
    opacity: 0.6,
    textDecoration: 'none',
    margin: '16px 0 0 0'
  },
  field: {
    marginTop: '5px'
  },
  inputField: {
    height: '50px',
    fontSize: '14px',
  }
};