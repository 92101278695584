import PartnerApproved from "constants/partner-approved-options";

const tableOptions = {
  serviceProviders: {
    fields: [
      { id: 'id', label: 'table.sp.id', type: 'text', sortable: true, width: '8%' },
      { id: 'sortingOrder', label: 'table.sp.sortingOrder', type: 'text', sortable: true, width: '8%' },
      { id: 'name', label: 'table.sp.partnerName', type: 'text', sortable: true, width: '15%' },
      { id: 'country', orderField: 'include_countries_name', label: 'table.sp.country', type: 'text', sortable: true, width: '14%' },
      { id: 'email', orderField: 'include_users_email', type: 'link', route: '/users/:id', routeIdKey: 'userId', label: 'table.sp.email', withEllipsis: true, sortable: true, width: '19%' },
      { id: 'type', label: 'table.sp.type', type: 'text', sortable: true, width: '10%' },
      { id: 'isQrPartner', label: 'table.sp.isQrPartner', type: 'bool', showIcon: false, sortable: true, width: '10%' },
      { id: 'approved', label: 'table.sp.status', width: '10%', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
      { id: 'created_at', label: 'table.sp.createdAt', width: '15%', sortable: true, textAlign: 'right', headAlign: 'right' }
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [
      { id: 'include_users_isApproved_eq', label: 'menuItem.filter.partner', type: 'select', options: PartnerApproved, priority: 1, selectedClassName: 'colorizeSelected', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
    ],
    searchFields: ['name']
  }
};

export default tableOptions;