import React, { useState, useMemo, useCallback } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import getTheme from './base';

export const CustomThemeContext = React.createContext({
  currentTheme: 'light',
  setTheme: null,
});

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const currentTheme = localStorage.getItem('appTheme') || 'light';
  const [themeName, _setThemeName] = useState(currentTheme);
  const theme = getTheme(themeName);

  const setThemeName = useCallback((name) => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
  }, [_setThemeName]); 

  const contextValue = useMemo(() => ({
    currentTheme: themeName,
    setTheme: setThemeName,
  }), [themeName, setThemeName]);

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
