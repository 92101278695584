import { isSuperAdmin } from "helpers/usersRolesHelper";
import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";
import usersRolesService from "services/usersRolesService";

const defaultData = {
  name: '',
  email: '',
  phone: ''
};

const defaultParams = {
  params: {
    sort: {
      field: "",
      order: ""
    },
    filter: {},
    limit: 50,
    skip: 0,
  }
}

class Users {

  getDefaultStoreProps = () => ({
    users: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
    userState: {},
    userData: JSON.parse(JSON.stringify(defaultData)),
    userDataErrors: {},
    usersRolesData: [],
    usersRolesDataLoaded: false,
    usersRolesDataLoading: false,
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreUsers(newData) {
    this.users.data = [];
    newData.data.forEach(this.addStoreUser);
    this.users.count = newData.count;
  }

  @action
  addStoreUser = (element) => {
    this.users.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter || defaultParams;
  }

  @computed
  get storeUsers() {
    const result = toJS(this.users)
    return result;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

  @action
  initializeUserData = (currentUser) => {
    this.userData = JSON.parse(JSON.stringify(defaultData));
    if (currentUser && !isSuperAdmin(currentUser)) {
      this.userData = {...this.userData, role: 'sp', serviceProviderId: currentUser.serviceProviderId}
    }
  }

  @action
  loadUsersRolesData = async () => {
    this.usersRolesDataLoading = true;
    try {
      const response = await usersRolesService.getUsersRoles();
      if (response) {
        this.usersRolesData = response.data.data;
        this.usersRolesDataLoaded = true;
        this.usersRolesDataLoading = false;
      }
    } catch (err) {
      this.usersRolesDataLoading = false;
    }
  }

  getUsersRolesData = async () => {
    if (this.usersRolesDataLoaded) {
      return this.usersRolesData;
    }
    if (this.usersRolesDataLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.usersRolesDataLoaded) {
            resolve(this.usersRolesData)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadUsersRolesData();
    return this.usersRolesData;
  }

}

export default new Users();