import React from 'react';
import { FormControl, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import M from 'messages';
import classes from './styles';

const ButtonsGroup = ({
  groupObject,
  value = '',
  handleChange,
  disabled = false,
  noTranslation = false,
  size = 'medium',
}) => {
  const { fields } = groupObject;

  const getButtonStyle = (itemValue, isDisabled = false) => {
    if (isDisabled) {
      return {
        backgroundColor: value === itemValue ? 'rgba(0, 0, 0, 0.08)' : 'unset',
      };
    }
    return {
      backgroundColor: value === itemValue ? '#1A5787' : 'unset',
      color: value === itemValue ? '#ffffff' : '#1A5787',
      '&:disabled': {
        color: value === itemValue ? '#ffffff' : '#000000',
      },
    };
  };

  const handleButtonClick = (itemValue) => {
    // If the clicked value is already selected, set it to an empty string to uncheck
    if (value === itemValue) {
      handleChange(groupObject.name, '');
    } else {
      handleChange(groupObject.name, itemValue);
    }
  };

  return (
    <FormControl component="fieldset">
      <Box className={classes.group}>
        {fields.map((item) => (
          <Button
            key={item.value}
            aria-label={item.value}
            variant="outlined"
            sx={{
              ...classes.buttonStyle,
              ...getButtonStyle(item.value, disabled),
            }}
            onClick={() => handleButtonClick(item.value)}
            disabled={disabled}
            size={size}
          >
            {noTranslation ? item.label : M.get(item.label)}
          </Button>
        ))}
      </Box>
    </FormControl>
  );
};

ButtonsGroup.propTypes = {
  groupObject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    fields: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        map: PropTypes.func,
      }),
    ]),
  }).isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  noTranslation: PropTypes.bool,
  size: PropTypes.string,
};

export default ButtonsGroup;
