import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";

const defaultData = {};

const defaultParams = {
  params: {
    filter: {},
    sort: {
      field: "createdAt",
      order: "desc"
    },
    limit: 50,
    skip: 0
  }
}

const requiredFields = [];

class WineLabels {

  getDefaultStoreProps = () => ({
    wineLabels: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
    wineLabelData: JSON.parse(JSON.stringify(defaultData)),
    wineLabelDataErrors: {},
  })
 

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreWineLabels(newData) {
    this.wineLabels.data = [];
    newData.data.forEach(this.addStoreWineLabel);
    this.wineLabels.count = newData.count;
  }

  @action
  addStoreWineLabel = (element) => {
    this.wineLabels.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter || defaultParams;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

  getWineLabelData = () => this.wineLabelData;

  @action
  setWineLabelData = (data) => {
    this.wineLabelData = data;
  };

  wineLabelDataHasError = () => {
    let hasError = false;
    requiredFields.forEach(i => {
      if (!this.wineLabelData[i]) {
        this.wineLabelDataErrors[i] = 'required';
        hasError = true;
      } else {
        delete this.wineLabelDataErrors[i];
      }
    });
    return hasError;
  };
}

export default new WineLabels();