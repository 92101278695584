import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '12px 24px 24px 24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal'
  },
  tableRoot: {
    marginTop: '12px',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.sideBarBgColor,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal'
  },
  container: {
    display: 'inherit',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px'
  },
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.backgroundColor
  },
  primaryIcon: {
    width: '18px',
    height: '18px',
  },
  primaryIconButton: {
    marginLeft: '16px',
    marginTop: '4px',
    height: '36px',
    minWidth: '109px',
    fontWeight: '500',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    },
  },
  actionPart: {
    textAlign: 'end'
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  link: {
    fontWeight: '600',
    textDecoration: 'unset',
    alignSelf: 'center',
    color: theme.palette.primary.main,
  },
  secondPrimaryButton: {
    backgroundColor: '#F4F7F9',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
    marginTop: '4px',
    height: '36px',
    minWidth: '145px',
    fontWeight: 500,
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f1f1f1'
    },
    textTransform: 'none'
  },
  secondPrimaryIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    width: '18px',
    height: '18px',
    '& > g': {
      '& > path': {
        fill: theme.palette.primary.main,
      }
    },
  },
  pageHeaderText: {
    display: 'flex',
    flexDirection: 'column',
  },
  emptyIcon: {
    width: '150px',
    height: '150px',
  }
}