import { makeAutoObservable, action, extendObservable, computed } from "mobx";
import {deleteAllSpacesFromStr} from 'helpers/helper';

const defaultDataReset = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
};

const defaultDataUIState = {
    showOldPassword: false,
    showNewPassword: false,
    showRepeatPassword: false,
};

const defaultChangedData = {
    oldPassword: false,
    newPassword: false,
    repeatNewPassword: false
};

const requiredFieldsReset = ['oldPassword', 'newPassword', 'repeatNewPassword'];

class ChangePassword {

    getDefaultStoreProps = () => ({
        changePasswordData: JSON.parse(JSON.stringify(defaultDataReset)),
        changePasswordDataErrors: {},
        changePasswordUIState: JSON.parse(JSON.stringify(defaultDataUIState)),
        changePasswordsChanged: JSON.parse(JSON.stringify(defaultChangedData))
    })

    constructor() {
        makeAutoObservable(this);
        extendObservable(this, { ...this.getDefaultStoreProps()});
    }

    @computed
    getChangePasswordData = () => this.changePasswordData;

    @action
    onChangeFieldRPW = (e) => {
        const { name, value } = e.target;
        this.changePasswordsChanged[name] = true;
        const newValue = deleteAllSpacesFromStr(value);
        this.changePasswordData[name] = newValue;
        if (!newValue && requiredFieldsReset.includes(name)) {
            this.changePasswordDataErrors[e.target.name] = 'required';
        } else {
            delete this.changePasswordDataErrors[name];
        }
        return true;
    }


    @computed
    getChangeOldPSWChanged = () => this.changePasswordsChanged.oldPassword;

    @computed
    getChangeNewPSWChanged = () => this.changePasswordsChanged.newPassword;

    @computed
    getChangeRepeatPSWChanged = () => this.changePasswordsChanged.repeatNewPassword;

    @computed
    validateChangePasswords = () => this.changePasswordData.newPassword === this.changePasswordData.repeatNewPassword;

    @computed
    getChangePasswordDataErrors = () => this.changePasswordDataErrors;

    @action
    clearDataErrors = () => {
        this.changePasswordDataErrors = {};
    }

    @action
    clearData = () => {
        this.changePasswordData = JSON.parse(JSON.stringify(defaultDataReset));
        this.changePasswordsChanged = JSON.parse(JSON.stringify(defaultChangedData));
    }

    @computed
    changePasswordDataHasError = (shownReqFields) => {
        let hasError = false;
        shownReqFields.forEach(i => {
            if (!this.changePasswordData[i]) {
                this.changePasswordDataErrors[i] = 'Required';
                hasError = true;
            } else {
                this.changePasswordDataErrors[i] = '';
            }
        });
        return hasError;
    };

    @action
    setEyeIconStateByField = (eyeId) => {
        this.changePasswordUIState[eyeId] = !this.changePasswordUIState[eyeId];
    }

    @computed
    getShowOldPasswordState = () => this.changePasswordUIState.showOldPassword;

    @computed
    getShowNewPasswordState = () => this.changePasswordUIState.showNewPassword;

    @computed
    getShowRepeatPasswordState = () => this.changePasswordUIState.showRepeatPassword;

    @computed
    isSubmitBtnActive = () => {
        if (!this.changePasswordData.oldPassword || !this.changePasswordData.newPassword || !this.changePasswordData.repeatNewPassword) {
            return false;
        }
        return this.changePasswordData.newPassword === this.changePasswordData.repeatNewPassword;
    }
}

export default new ChangePassword();