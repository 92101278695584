import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Tooltip,
  Box,
  Link,
  Typography,
  InputLabel
} from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import { CompactPicker } from 'react-color';
import Crop54OutlinedIcon from '@mui/icons-material/Crop54Outlined';
import { ColorLens as ColorIcon, CloudUpload as UploadIcon, Download as DownloadIcon, GridOn as DotsIcon, GridView as SquaresIcon, Waves as FluidIcon, CropSquare as SquareEyeIcon, PanoramaFishEye as RoundEyeIcon } from '@mui/icons-material';
import wineLableQRCodeStore from 'store/wineLableQRCodeStore';

const QRCodeModal = observer(({ open, handleClose, handleSave }) => {
  const {qrColor, logo, qrStyle, eyeStyle, backgroundColor, qrRedirectUrl, qrUrl} = wineLableQRCodeStore.getQRData();
  const [showQrColorPicker, setShowQrColorPicker] = useState(false);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [qrRef, setQrRef] = useState(null);

  const qrColorRef = useRef(null);
  const bgColorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (qrColorRef.current && !qrColorRef.current.contains(event.target)) {
        setShowQrColorPicker(false);
      }
      if (bgColorRef.current && !bgColorRef.current.contains(event.target)) {
        setShowBgColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      wineLableQRCodeStore.setLogo(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDownload = () => {
    const canvas = qrRef.querySelector('canvas');
    const img = canvas.toDataURL();
    const link = document.createElement('a');
    link.href = img;
    link.download = 'qr-code.png';
    link.click();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Generate QR Code</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            To meet legal requirements, ensure text is included near the QR code indicating that it provides information on the wine&#39;s nutrition and ingredients. For example, you can add &#39;Nutrition &amp; Ingredients&#39; above or below the QR code.
          </Grid>
          {/* Customization Tools on the Left */}
          <Grid item xs={6}>
            {/* QR Code Style Selection with Icons */}
            <Box mt={2}>
              <Typography variant="subtitle1">QR Code Style</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Tooltip title="Dots Style">
                    <IconButton onClick={() => wineLableQRCodeStore.setQrStyle('dots')} color={qrStyle === 'dots' ? 'primary' : 'default'}>
                      <DotsIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Squares Style">
                    <IconButton onClick={() => wineLableQRCodeStore.setQrStyle('squares')} color={qrStyle === 'squares' ? 'primary' : 'default'}>
                      <SquaresIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Fluid Style">
                    <IconButton onClick={() => wineLableQRCodeStore.setQrStyle('fluid')} color={qrStyle === 'fluid' ? 'primary' : 'default'}>
                      <FluidIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>

            {/* QR Code Eye/Corners Style Selection with Icons */}
            <Box mt={2}>
              <Typography variant="subtitle1">Corners Style</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Tooltip title="Square Corners">
                    <IconButton onClick={() => wineLableQRCodeStore.setEyeStyle([
                      { outer: [0, 0, 0, 0], inner: [0, 0, 0, 0] },
                      { outer: [0, 0, 0, 0], inner: [0, 0, 0, 0] },
                      { outer: [0, 0, 0, 0], inner: [0, 0, 0, 0] }
                    ])} color={eyeStyle[0].outer.every(val => val === 0) ? 'primary' : 'default'}>
                      <SquareEyeIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Rounded Square Corners">
                    <IconButton onClick={() => wineLableQRCodeStore.setEyeStyle([
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                      { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] }
                    ])} color={eyeStyle[0].outer.every(val => val === 10) ? 'primary' : 'default'}>
                      <Crop54OutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Round Corners">
                    <IconButton onClick={() => wineLableQRCodeStore.setEyeStyle([
                      { outer: [50, 50, 50, 50], inner: [50, 50, 50, 50] },
                      { outer: [50, 50, 50, 50], inner: [50, 50, 50, 50] },
                      { outer: [50, 50, 50, 50], inner: [50, 50, 50, 50] }
                    ])} color={eyeStyle[0].outer.every(val => val === 50) ? 'primary' : 'default'}>
                      <RoundEyeIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>

            {/* QR Code Color Picker with Background Reflecting Chosen Color */}
            <Box mt={2}>
              <Typography variant="subtitle1">QR Code Color</Typography>
              <Tooltip title="Change QR Code Color" arrow>
                <IconButton
                  onClick={() => setShowQrColorPicker(!showQrColorPicker)}
                  sx={{
                    borderRadius: '50%',
                    width: 40,
                    height: 40,
                    border: `2px solid ${qrColor}`,
                  }}
                >
                  <ColorIcon />
                </IconButton>
              </Tooltip>
              {showQrColorPicker && (
                <Box ref={qrColorRef} style={{ position: 'absolute', zIndex: 2 }}>
                  <CompactPicker
                    color={qrColor}
                    onChangeComplete={(color) => wineLableQRCodeStore.setQrColor(color.hex)}
                  />
                </Box>
              )}
            </Box>

            {/* Background Color Picker with Background Reflecting Chosen Color */}
            <Box mt={2}>
              <Typography variant="subtitle1">Background Color</Typography>
              <Tooltip title="Change Background Color" arrow>
                <IconButton
                  onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                  sx={{
                    borderRadius: '50%',
                    width: 40,
                    height: 40,
                    border: `2px solid ${backgroundColor}`,
                  }}
                >
                  <ColorIcon />
                </IconButton>
              </Tooltip>
              {showBgColorPicker && (
                <Box ref={bgColorRef} style={{ position: 'absolute', zIndex: 2 }}>
                  <CompactPicker
                    color={backgroundColor}
                    onChangeComplete={(color) => wineLableQRCodeStore.setBackgroundColor(color.hex)}
                  />
                </Box>
              )}
            </Box>

            {/* Upload Logo */}
            <Box mt={2}>
              <InputLabel>Upload Logo (optional)</InputLabel>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadIcon />}
                fullWidth
              >
                Upload Logo
                <input type="file" accept="image/*" hidden onChange={handleLogoUpload} />
              </Button>
            </Box>
          </Grid>

          {/* QR Code and URL on the Right */}
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <div ref={setQrRef}>
              <QRCode
                ecLevel="H"
                value={qrUrl}
                logoImage={logo}
                fgColor={qrColor}
                bgColor={backgroundColor}
                size={256}
                logoWidth={50}
                logoHeight={50}
                logoOpacity={1}
                qrStyle={qrStyle}
                logoPaddingStyle="circle"
                logoPadding={2}
                eyeRadius={eyeStyle}
              />
            </div>
            <Box mt={2}>
              <Typography variant="subtitle1">QR Code URL</Typography>
              <Link href={qrRedirectUrl} target="_blank" rel="noopener" variant="body1" underline="hover">
                {qrRedirectUrl}
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
        WARNING: The list of ingredients and nutrition facts must remain consistent with the information known at the production phase, just as they appear on any physical label. They must not be changed during the product&#39;s lifetime. Once the product is on the market and the QR code is in use, the wine details included in the QR label shall not be modified.
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default QRCodeModal;
