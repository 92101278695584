import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import qs from 'qs';

import CustomTable from 'components/common/customTableV2';
import EmptyData from 'components/common/emptyData';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import UsersServices from 'services/usersService';
import usersStore from 'store/usersStore';
import { adaptUsersTableData } from 'helpers/adapter';
import { replacePath, getMessage } from 'helpers/helper';
import domainData from 'store/domainStore';
import { ReactComponent as EmptyUser } from 'assets/emptyUser.svg';
import M from 'messages'

import SidebarStore from 'store/ui/sidebarStore';
import { routes } from 'config';
import tableOptions from './config/config';
import CustomTableToolbar from './toolbar';

import classes from './styles';

const Users = observer(({ path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [loading, setLoading] = useState(true);
  const [fromFilter, setFromFilter] = useState(true);
  const [usersData, setUsersData] = useState({});

  const currentUser = domainData.getCurrentUser();
  const filteredParamsStore = usersStore.filter;
  const isSidebarOpened = SidebarStore.sidebarState;
  const { searchFields } = tableOptions.user;

  // NOTE: cut / symbol
  const pageItem = path.substring(1);

  const setFilter = (filter) => {
    replacePath(navigate, filter);
    usersStore.setFilter(filter);
  };

  const queryParams = {
    params: {
      sort: pageParams?.sort || filteredParamsStore.params.sort,
      filter: pageParams?.filter || filteredParamsStore.params.filter,
      limit: pageParams?.limit || filteredParamsStore.params.limit,
      skip: pageParams?.skip || filteredParamsStore.params.skip,
      search: pageParams?.search || filteredParamsStore.params.search,
    }
  }


  const getUsersLists = useCallback(
    async (params, withFilter = true) => {
      try {
        // setLoading(true);
        setFromFilter(withFilter);
        const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (urlParams?.filter && Object.keys(urlParams.filter).length) {
          setFromFilter(true);
        }
        replacePath(navigate, params);
        usersStore.setFilter(params);
        const newParams = { params: { ...params.params } };
        const response = await UsersServices.getUsers(newParams);
        if (response) {
          usersStore.setStoreUsers(response.data);
          setUsersData({
            count: response.data.count,
            data: adaptUsersTableData(response.data.data),
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    getUsersLists(queryParams, false);

    return () => {
      usersStore.setFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersLists]);

  const onSearchCallback = (value) => {
    let oldValue = queryParams.params?.search?.value;
    if (oldValue === undefined) {
      oldValue = '';
    }
    if (searchFields && (oldValue !== value)) {
      const newFilter = { params: { ...queryParams.params, search: { ...queryParams.params.search, value, fields: searchFields }, skip: 0 } }
      if (!value) {
        delete newFilter.params.search;
      }
      setFilter(newFilter);
      getUsersLists(newFilter, true)
    }
  };

  const toolbarView = (
    <CustomTableToolbar
      onSearchCallback={onSearchCallback}
      filteredParams={queryParams}
    />
  );

  const openPath = (row) => (+currentUser.id === +row.id ? routes.profileEdit.path : routes.userEdit.path).replace(':id', row.id);

  const isEmptyState = () => (!fromFilter && !(usersData?.data?.length));

  if (loading) { return (<Loading />); }

  return (
    <>
      <Box
        sx={{
          ...classes.root,
          ...(isSidebarOpened && classes.rootDrawerOpen),
          ...(!isSidebarOpened && classes.rootDrawerClose)
        }}
      >
        <PageHeader
          title={M.get('users.pageTitle')}
          onlyGeneralPageTitle
        />
        <Grid item xs={12}>
          <Box sx={classes.tableRoot}>
            {isEmptyState() && !loading ? (
              <EmptyData
                icon={<EmptyUser />}
                title={pageItem}
                titleClassName="userEmptyPageTitle"
                mainClassName="userMain"
                withCreate={false}
              />
            ) : (
              <Grid item container sx={classes.container}>
                <Box sx={classes.tabsContainer}>
                  <CustomTable
                    tableSources={usersData}
                    tableOptions={tableOptions.user}
                    loading={loading}
                    filteredParams={queryParams}
                    setFilteredParams={setFilter}
                    getDataOnChange={getUsersLists}
                    openPath={openPath}
                    withOpenAction={false}
                    withEditAction
                    toolbarView={toolbarView}
                    withCheckbox={false}
                    rowIsNotSelectable
                  />
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  )
});

Users.propTypes = {
  path: PropTypes.string.isRequired
}

export default Users;