import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, Typography, Icon } from '@mui/material';
import M from 'messages';
import CustomToolbarAction from 'components/common/customToolbarAction';

import CustomFilter from 'components/common/customFilter';
import CustomSearch from 'components/common/customSearchV2';

import classes from './styles';

const CustomTableToolbar = ({
  onSearchCallback,
  filteredParams,
  numSelected,
  handleDelete,
  filterFields = [],
  onFilterCallback = null,
}) => {

  const searchValue = filteredParams?.params?.search?.value || '';

  return (
    <Toolbar sx={classes.root}>
      <Box sx={classes.filters}>
        <CustomSearch
          onSearchCallback={onSearchCallback}
          searchValue={searchValue}
        />
        <CustomFilter
          onFilterCallback={onFilterCallback}
          filterFields={filterFields}
          filteredParams={filteredParams}
        />
      </Box>
      <Box sx={classes.actions}>
        {numSelected > 0 &&
          <Typography sx={classes.headerText}>
            {`${numSelected} ${M.get('table.itemSelected')}`}
          </Typography>}
        {numSelected > 0 && handleDelete && <CustomToolbarAction
          handleOnClick={handleDelete}
          btnText={M.get('actions.delete')}
          tooltipText={M.get('actions.delete')}
          startIcon={<Icon>delete_forever</Icon>}
        />}
      </Box>
    </Toolbar>
  );
};

CustomTableToolbar.propTypes = {
  onSearchCallback: PropTypes.func.isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  onFilterCallback: PropTypes.func,
  filterFields: PropTypes.instanceOf(Array),
};

export default CustomTableToolbar;