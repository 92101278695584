import { routes } from 'config';
import Roles from 'constants/roles';
import domainStore from 'store/domainStore';
import settingsStore from 'store/settingsStore';

const getSPRoute = ({ isUserSuperAdmin, domainData }) => {
  const currentUser = domainData.getCurrentUser();
  let spPath = routes.serviceProviders.path;
  if (currentUser && !isUserSuperAdmin) {
    spPath = routes.serviceProviderEdit.path.replace(':id', currentUser.serviceProviderId);
  }
  return spPath;
}

const sideBarData = [
  { id: 1, label: "pages.catalog", link: routes.catalogue.path, icon: 'liquor', iconPosition: 'start', height: 40, roles: Roles.catalog.access},
  { id: 2, label: "pages.kiosksMenus", link: routes.kiosksMenus.path, icon: 'menu_book', iconPosition: 'start', height: 40, roles: Roles.kiosksMenus.access },
  { id: 3, label: "pages.wineLabels", link: routes.wineLabels.path, icon: 'label', iconPosition: 'start', height: 40, roles: Roles.wineLabels.access, showSideBarSection: async ({ isUserSuperAdmin }) => (isUserSuperAdmin || await domainStore.isUserHaveLabelAccess()) && settingsStore.getShowEuPlan() },
  { id: 4, label: "pages.dashboard", link: routes.dashboard.path, icon: 'dashboard_customize', iconPosition: 'start', height: 40, roles: Roles.dashboardMenu.access, groupClassName: 'dashboardGroup', showSideBarSection: async ({ isUserSuperAdmin }) => (isUserSuperAdmin || await domainStore.isUserHaveDashboardAccess()) && settingsStore.getShowDashboard() },
  { id: 5, label: "pages.users", link: routes.users.path, icon: 'menu_book', iconPosition: 'start', height: 40, roles: Roles.users.access },
  { id: 6, label: "pages.sp", link: getSPRoute, icon: 'group', iconPosition: 'start', height: 40, roles: Roles.partner.access, showSideBarSection: ({ isUserSuperAdmin }) => isUserSuperAdmin },
  { id: 7, label: "pages.spSettings", link: getSPRoute, icon: 'group', iconPosition: 'start', height: 40, roles: Roles.partner.access, showSideBarSection: ({ isUserSuperAdmin }) => !isUserSuperAdmin },
];

export default sideBarData;
