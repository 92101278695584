import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0'
  },
  listItemClosedDrawer: {
    padding: '6px 6px 6px 6px',
  },
  listItemText: {
    color: theme.palette.primary.sideBarTextColor,
    fontWeight: 500
  },
  listItemIcon: {
    minWidth: '40px',
    transition: 'min-width 0.1s',
    opacity: '0.8',
    fill: theme.palette.primary.sideBarIconColor,
    color: theme.palette.primary.sideBarIconColor,
    '& > svg': {
      fill: theme.palette.primary.sideBarIconColor,
      color: theme.palette.primary.sideBarIconColor,
      '& > g': {
        fill: theme.palette.primary.sideBarIconColor,
        color: theme.palette.primary.sideBarIconColor,
        '& > path': {
          fill: theme.palette.primary.sideBarIconColor,
          color: theme.palette.primary.sideBarIconColor,
        }
      },
      '& > path': {
        fill: theme.palette.primary.sideBarIconColor,
        color: theme.palette.primary.sideBarIconColor,
      }
    }
  },
  listItemIconClosedDrawer: {
    minWidth: '56px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'min-width 100s',
    opacity: '0.8',
    '& > svg': {
      '& > g': {
        '& > path': {
          fill: theme.palette.primary.sideBarIconColor,
        }
      },
      '& > path': {
        fill: theme.palette.primary.sideBarIconColor,
      }
    }
  },
  hideOpacity: {
    opacity: 1,
  },
  linkActive: {
    color: theme.palette.primary.main,
  },
  iconActive: {
    color: theme.palette.primary.main,
    '& > svg': {
      '& > g': {
        '& > path': {
          fill: theme.palette.primary.main,
        }
      },
      '& > path': {
        fill: theme.palette.primary.main,
      }
    }
  },
  listItemsContainer: {
    borderTop: '1px solid #e3e3e3',
    padding: '12px 0'
  },
  listItemsContainerBottom: {
    borderBottom: '1px solid #e3e3e3',
  },
  listItemTextChild: {
    fontSize: '14px',
    fontWeight: '500'
  },
  dashboardGroup: {
    fontWeight: '500'
  },
  catalogGroup: {
    fontWeight: '600'
  },
  kioskGroup: {
    fontWeight: '600'
  },
  inventoryGroup: {
    fontWeight: '600'
  },
  analyticsGroup: {
    fontWeight: '600'
  },
  deliveryGroup: {
    fontWeight: '600'
  },
  hideContent: {
    display: 'none'
  },
  lastElement: {
    borderBottom: '1px solid #e3e3e3',
  },
  listItemIconShow: {
    '& > span': {
      display: 'none',
    },
    '&:hover': {
      '& > span': {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }
  }
}