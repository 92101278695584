import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  Grid,
  Button,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Switch,
  FormControlLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import settingsStore from 'store/settingsStore';
import SettingsService from 'services/settingsService';

import ListWithAccordion from 'components/common/listWithAccordion';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import { getMessage } from 'helpers/helper';

import M from 'messages';

// import Plans from './plans';
import classes from './styles';

const Settings = observer(() => {
  const [disableSave, setDisableSave] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const data = settingsStore.getStoredData();

  const isSaveButtonDisabled = () => !settingsStore.getIsFormChanged || disableSave || Object.keys(settingsStore.dataErrors).length > 0;

  useEffect(() => {
    settingsStore.getCountriesData();
    settingsStore.getWineTypesData();
    settingsStore.getOurImpactsData();
    settingsStore.getSubscriptionPlansData();
    return () => settingsStore.reset();
  }, []);


  const prepareDataForRequest = (obj) => ({
    wineTypes: obj.wineTypes,
    countries: obj.countries,
    settingsKeys: !obj.impactsChanged ? [] : obj.settingsKeys,
    subscriptions: !obj.bulletsChanged ? [] : obj.subscriptions.map(subscription => ({
      ...subscription,
      subscriptionPlanBullets: obj[`Bullets${subscription.id}`].map(item => ({ bullet: item.bullet, planId: subscription.id}) )
    }))
  })

  const onSubmit = async () => {
    try {
      const cleanedData = prepareDataForRequest(data);
      const response = await SettingsService.update(cleanedData);
      if (response.data) {
        enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      }
    } catch (err) {
      setDisableSave(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    setActionLoading(true);
  };

  useEffect(() => {
    if (actionLoading) {
      settingsStore.reset();
      settingsStore.getCountriesData();
      settingsStore.getWineTypesData();
      settingsStore.getOurImpactsData();
      settingsStore.getSubscriptionPlansData();
      setActionLoading(false);
    }
  }, [actionLoading]);

  const ratings = data.settingsKeys.filter(item => item.key === 'rating');

  if (!settingsStore.getAllOptionsLoaded()) return <Loading />;

  return (
    <Box sx={classes.page}>
      <PageHeader
        title={M.get('settings.pageTitle')}
        onlyGeneralPageTitle
      />
      <Box sx={classes.pageContent}>
        <Card sx={classes.card}>
          <CardContent sx={{ root: classes.cardContent }}>
            <Grid container fullWidth xs={12} sm={10} md={7} >
              <Grid item xs={12} >
                <ListWithAccordion
                  title={M.get('settings.countries')}
                  placeholder={M.get('settings.searchCountry')}
                  value={data.country}
                  options={settingsStore.getFilteredCountryList}
                  handleChange={(e) => settingsStore.listItemChange(e, 'country', 'countries')}
                  handleInputChange={(e) => settingsStore.onChangeField(e, 'country')}
                />
              </Grid>
              <Grid item xs={12}  mt={2}>
                <ListWithAccordion
                  title={M.get('settings.wineTypes')}
                  placeholder={M.get('settings.searchWineType')}
                  value={data.wineType}
                  options={settingsStore.getFilteredWineTypesList}
                  handleChange={(e) => settingsStore.listItemChange(e, 'wineType', 'wineTypes')}
                  handleInputChange={(e) => settingsStore.onChangeField(e, 'wineType')}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <Divider sx={classes.divider}/>
                <Typography sx={classes.ourImpactTitle}>
                  {M.get('settings.ourImpact')}
                </Typography>
                {ratings.map((item) => (
                  <Grid container xs={12} sx={classes.fields}>
                    <Grid item xs={12} sm={5}>
                      <Typography sx={classes.fieldName}>
                        {M.get(`settings.${item.name}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        type="text"
                        id={item.name}
                        name={item.name}
                        variant="standard"
                        sx={classes.fieldValue}
                        placeholder={M.get(`settings.${item.name}`)}
                        required
                        value={item.value}
                        onChange={(e) => settingsStore.impactsOnChange(e, item.key)}
                        error={
                          !item.value &&
                          !!settingsStore.dataErrors[item.name]
                        }
                        helperText={
                          !item.value &&
                          !!settingsStore.dataErrors[item.name] &&
                          M.get('form.errors.required')
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <EditIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {!!data.subscriptions.length && (
                <Grid container xs={12} sx={classes.fields}>                    
                  <Divider sx={classes.divider}/>
                  <Typography sx={classes.ourImpactTitle}>
                    {M.get('settings.subscriptionsTitle')}
                  </Typography>
                  {data.subscriptions.map((subscription) => (
                    <Grid item xs={12} mt={2}>
                      <ListWithAccordion
                        optionKey="bullet"
                        title={subscription.name}
                        placeholder={M.get('settings.searchBullet')}
                        value={data[`bullet_${subscription.id}`] || ''}
                        options={
                          settingsStore.getBulletsList(subscription.id) || []
                        }
                        handleChange={(e) => {
                          settingsStore.listBulletItemChange(
                            e,
                            `bullet_${subscription.id}`,
                            `Bullets${subscription.id}`
                          );
                        }}
                        handleInputChange={(e) =>
                          settingsStore.onChangeBulletField(
                            e,
                            `bullet_${subscription.id}`,
                            `Bullets${subscription.id}`
                          )
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid container xs={12} sx={classes.fields}>
                <Divider sx={classes.divider}/>
                <FormControlLabel
                  variant="outlined"
                  label={
                    <Typography sx={classes.fieldHeaderText}>
                      {M.get('settings.showComingSoon')}
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      id="showComingSoon"
                      color="primary"
                      name="showComingSoon"
                      defaultChecked
                      checked={data.comingSoon?.showComingSoon === 'true'}
                      onChange={(e) => settingsStore.onChangeSwitchField(e, 'comingSoon')}
                    />
                  }
                  sx={classes.isQrPartner}
                />
              </Grid>
              <Grid container xs={12} sx={classes.fields}>
                <Divider sx={classes.divider}/>
                <FormControlLabel
                  variant="outlined"
                  label={
                    <Typography sx={classes.fieldHeaderText}>
                      {M.get('settings.showEuPlan')}
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      id="showEuPlan"
                      color="primary"
                      name="showEuPlan"
                      defaultChecked
                      checked={data.comingSoon?.showEuPlan === 'true'}
                      onChange={(e) => settingsStore.onChangeSwitchField(e, 'comingSoon')}
                    />
                  }
                  sx={classes.isQrPartner}
                />
              </Grid>
              <Grid container xs={12} sx={classes.fields}>
                <Divider sx={classes.divider}/>
                <FormControlLabel
                  variant="outlined"
                  label={
                    <Typography sx={classes.fieldHeaderText}>
                      {M.get('settings.showDashboard')}
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      id="showDashboard"
                      color="primary"
                      name="showDashboard"
                      defaultChecked
                      checked={data.dashboard?.showDashboard === 'true'}
                      onChange={(e) => settingsStore.onChangeSwitchField(e, 'dashboard')}
                    />
                  }
                  sx={classes.isQrPartner}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box sx={classes.pageFooter}>
        <Box>
          <Grid item container sx={classes.footerPart}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaveButtonDisabled()}
              onClick={onSubmit}
            >
              {M.get('form.save')}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
});

export default Settings;
