import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  Skeleton
} from '@mui/material';
import domainData from 'store/domainStore';
import CustomTableHead from './customTableHead';
import CustomTableCell from './customTableCell';
import TablePaginationActions from './customPaginationAction';
import classes from './styles';

function CustomTable({
  rowUniqueKey = 'id',
  loading = false,
  tableSources = { data: [], count: 0 },
  tableOptions,
  getDataOnChange = null,
  filteredParams,
  setFilteredParams = null,
  selectedRows = [],
  setSelectedRows = null,
  handleRowClick = null,
  handleEditAction = null,
  handleShowQA = null,
  handleShowInMarket = null,
  handleDownloadAction = null,
  handleRemoveAction = null,
  handleClickIcon = null,
  withEditAction = true,
  withQRAction = false,
  withInMarketAction = false,
  withRemoveAction = false,
  withDownloadAction = false,
  withCheckbox = true,
  withOpenAction = true,
  withoutPagination = false,
  handleOpenClick = null,
  rowIsNotSelectable = false,
  withFilterBar = true,
  withCloneAction = false,
  handleCloneAction = null,
  openPath = null,
  disableIcon = false,
  toolbarView = null,
  maxRowCount = null,
  containerClassName = '',
  tableClass = 'table',
  tableContainerClass = '',
  rowColorizing = true,
  bottomPagination = false,
  hideElevation = false,
  hasRowStyle = null,
  withoutHead = false,
  tableRowClass = 'tableRow',
  withSinglePagination = false,
  paginationRoot = 'paginationRoot',
  disableCondition = null
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { limit, skip } = filteredParams?.params ?? {};
  const sortObj = filteredParams?.params?.sort || {};
  const { order, field: orderBy } = sortObj;

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(limit, 10));

  const { fields, rowsPerPageOptions } = tableOptions;
  const { data: tableData, count } = tableSources;

  useEffect(() => {
    setRowsPerPage(parseInt(limit, 10));
  }, [limit]);

  const page = useMemo(() => parseInt(skip / limit, 10), [skip, limit]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newFilter = { params: { ...filteredParams.params, sort: { field: property, order: isAsc ? 'desc' : 'asc' }, skip: 0 } };
    setFilteredParams(newFilter);
    getDataOnChange(newFilter);
  };

  const handleAllSelected = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n[rowUniqueKey]);
      const result = [];
      if (setSelectedRows) {
        newSelected.forEach(item => {
          if (!selectedRows.includes(item)) {
            result.push(item);
          }
        });
        setSelectedRows([...selectedRows, ...result]);
      }
      return;
    }

    const newSelected = [];
    for (let i = 0; i < selectedRows.length; i += 1) {
      if (!tableData.find(row => row[rowUniqueKey] === selectedRows[i])) {
        newSelected.push(selectedRows[i]);
      }
    }
    setSelectedRows(newSelected);
  };

  const handleClick = (row) => {
    if (withCheckbox) {
      const name = row[rowUniqueKey];
      const selectedIndex = selectedRows.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }
      setSelectedRows(newSelected);
    }
  };

  const onShow = (row) => {
    navigate(`${location.pathname}/${row.id}/show`);
  };

  const onEdit = (row) => {
    if (handleEditAction && typeof handleEditAction === 'function') {
      handleEditAction(row.id);
      return;
    }
    navigate(`${location.pathname}/${row.id}`);
  };

  const onShowQR = (row) => {
    if (handleShowQA && typeof handleShowQA === 'function') {
      handleShowQA(row.id);
    }
  };

  const onShowInMarket = (row) => {
    if (handleShowInMarket && typeof handleShowInMarket === 'function') {
      handleShowInMarket(row.id);
    }
  };

  const onDownload = (row) => {
    if (handleDownloadAction && typeof handleDownloadAction === 'function') {
      handleDownloadAction(row.id);
    }
  };

  const onRemove = (row) => {
    if (handleRemoveAction && typeof handleRemoveAction === 'function') {
      handleRemoveAction(row.id);
    }
  };

  const tableSelectsCount = () => {
    let tableSelectedCount = 0;
    tableData?.forEach(row => {
      if (selectedRows.includes(row[rowUniqueKey])) {
        tableSelectedCount += 1;
      }
    });
    return tableSelectedCount;
  };

  const handleChangePage = (event, newPage) => {
    const newFilter = {
      params: { ...filteredParams.params, skip: newPage * filteredParams.params.limit },
    };
    setFilteredParams(newFilter);
    getDataOnChange(newFilter);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const newFilter = {
      params: { ...filteredParams.params, skip: 0, limit: parseInt(event.target.value, 10) },
    };
    setFilteredParams(newFilter);
    getDataOnChange(newFilter);
  };

  const isSelected = (name) => selectedRows.indexOf(name) !== -1;

  const collectSkeletonContainer = fields.map(fld => (
    <Skeleton key={fld.id} sx={classes.skeletonItem} animation="pulse" />
  ));

  const isUserSuperAdmin = domainData.userIsSuperAdmin();

  const currentSp = domainData.getCurrentSP();
  const currentSpData = domainData.getCurrentSpData();

  const onTableRowClick = (row) => {
    if (withOpenAction || rowIsNotSelectable) {
      return null;
    }
    if (handleRowClick) {
      return handleRowClick(row);
    }
    return onShow(row);
  };

  return (
    <Box sx={{...(containerClassName && classes[containerClassName]) }}>
      <Paper elevation={hideElevation ? 0 : 1} sx={!hideElevation ? classes.paperWithShadow : null}>
        <TableContainer sx={classes[tableContainerClass]}>
          {withFilterBar && toolbarView}
          <Table sx={classes[tableClass]} size="small" padding="checkbox">
            {!loading && !withoutHead && (
              <CustomTableHead
                tableSelects={tableSelectsCount()}
                numSelected={selectedRows.length}
                sortObj={sortObj}
                handleAllSelected={handleAllSelected}
                onRequestSort={tableData?.length > 0 ? handleRequestSort : null}
                rowCount={tableData?.length}
                maxRowCount={maxRowCount}
                fields={fields}
                filteredParams={filteredParams}
                withEditAction={!!tableData?.length && withEditAction}
                withQRAction={!!tableData?.length && withQRAction}
                withInMarketAction={!!tableData?.length && withInMarketAction}
                withDownloadAction={!!tableData?.length && withDownloadAction}
                withCheckbox={!!tableData?.length && withCheckbox}
                withOpenAction={!!tableData?.length && withOpenAction}
                withCloneAction={!!tableData?.length && withCloneAction}
                withRemoveAction={!!tableData?.length && withRemoveAction}
              />
            )}
            <TableBody sx={loading ? classes.tableBody : {}}>
              {tableData?.map((row) => {
                const isItemSelected = isSelected(row[rowUniqueKey]);
                return loading ? (
                  <tr key={row[rowUniqueKey]} style={classes.skeletonContainer}>
                    <td>{collectSkeletonContainer}</td>
                  </tr>
                ) : (
                  <TableRow
                    onClick={() => onTableRowClick(row)}
                    key={row[rowUniqueKey]}
                    sx={{
                      ...classes[tableRowClass],
                      ...(row.isApproved === false && rowColorizing && classes.rowError),
                      ...(!withOpenAction && !rowIsNotSelectable && classes.tableRowCursor),
                      ...(hasRowStyle ? hasRowStyle(row) : null),
                      ...(disableCondition && disableCondition(row) && classes.rowDisabled)
                    }}
                  >
                    {withCheckbox && <CustomTableCell handleClickIcon={handleClick} data={row} key={`${row[rowUniqueKey]}-checkbox`} isItemSelected={isItemSelected} isCheckbox={withCheckbox} filteredParams={filteredParams} />}
                    {fields.map((item) =>
                      (!item.show || item.show({ currentSpData, currentSp, isUserSuperAdmin })) && (<CustomTableCell disableIcon={disableIcon} handleClickIcon={handleClickIcon} key={item.id} cellItem={item} data={row} filteredParams={filteredParams} />)
                    )}
                    {withCloneAction && <CustomTableCell handleClickIcon={handleCloneAction} data={row} key={`${row[rowUniqueKey]}-clone`} isCloneAction filteredParams={filteredParams} />}
                    {withInMarketAction && <CustomTableCell handleClickIcon={onShowInMarket} data={row} key={`${row[rowUniqueKey]}-inMarket`} isInMarketAction />}
                    {withQRAction && <CustomTableCell handleClickIcon={onShowQR} data={row} key={`${row[rowUniqueKey]}-qr`} isQRAction />}
                    {withEditAction && <CustomTableCell handleClickIcon={onEdit} data={row} key={`${row[rowUniqueKey]}-edit`} isEditAction />}
                    {withDownloadAction && <CustomTableCell handleClickIcon={onDownload} data={row} key={`${row[rowUniqueKey]}-download`} isDownloadAction />}
                    {withOpenAction && <CustomTableCell openPath={openPath ? openPath(row) : `${location.pathname}/${row.id}/show`} handleClickIcon={handleOpenClick} data={row} key={`${row[rowUniqueKey]}-show`} isOpenAction />}
                    {withRemoveAction && <CustomTableCell handleClickIcon={onRemove} data={row} key={`${row[rowUniqueKey]}-remove`} isRemoveAction />}
                  </TableRow>
                );
              })}
              {!tableData?.length && (
                <TableRow style={{ height: '60px' }}>
                  <CustomTableCell isEmptyCell loading={loading} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {(!withoutPagination && !loading && tableData?.length > 0) && (
          <TablePagination
            sx={bottomPagination ? classes.tablePagination : {}}
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={(props) => TablePaginationActions({ ...props, withSinglePagination })}
            classes={{ toolbar: classes[paginationRoot] }}
          />
        )}
      </Paper>
    </Box>
  );
}

CustomTable.propTypes = {
  loading: PropTypes.bool,
  tableSources: PropTypes.shape({ data: PropTypes.instanceOf(Array), count: PropTypes.number }),
  tableOptions: PropTypes.shape({ fields: PropTypes.instanceOf(Array), rowsPerPageOptions: PropTypes.instanceOf(Array), searchFields: PropTypes.instanceOf(Array) }).isRequired,
  withEditAction: PropTypes.bool,
  withDownloadAction: PropTypes.bool,
  withRemoveAction: PropTypes.bool,
  rowUniqueKey: PropTypes.string,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  setFilteredParams: PropTypes.func,
  getDataOnChange: PropTypes.func,
  withCheckbox: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  handleClickIcon: PropTypes.func,
  handleRowClick: PropTypes.func,
  selectedRows: PropTypes.instanceOf(Array),
  withoutPagination: PropTypes.bool,
  handleEditAction: PropTypes.func,
  handleDownloadAction: PropTypes.func,
  handleRemoveAction: PropTypes.func,
  withOpenAction: PropTypes.bool,
  handleOpenClick: PropTypes.func,
  rowIsNotSelectable: PropTypes.bool,
  withFilterBar: PropTypes.bool,
  handleCloneAction: PropTypes.func,
  withCloneAction: PropTypes.bool,
  openPath: PropTypes.func,
  disableIcon: PropTypes.bool,
  toolbarView: PropTypes.node,
  maxRowCount: PropTypes.number,
  containerClassName: PropTypes.string,
  tableClass: PropTypes.string,
  tableContainerClass: PropTypes.string,
  rowColorizing: PropTypes.bool,
  bottomPagination: PropTypes.bool,
  hideElevation: PropTypes.bool,
  hasRowStyle: PropTypes.func,
  withoutHead: PropTypes.bool,
  tableRowClass: PropTypes.string,
  withSinglePagination: PropTypes.bool,
  paginationRoot: PropTypes.string,
  disableCondition: PropTypes.func,
  handleShowQA: PropTypes.func,
  handleShowInMarket: PropTypes.func,
  withQRAction: PropTypes.bool,
  withInMarketAction: PropTypes.bool,
};

export default CustomTable;
