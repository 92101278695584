export default {
  modal: {
    display: 'flex',
    maxWidth: '600px',
    flexDirection: 'column'
  },
  save: {
    backgroundColor: '#283593',
    color: 'white',
    '&:hover': {
      backgroundColor: '#001064'
    }
  }
};