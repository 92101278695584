import React, { forwardRef } from 'react';
import { Box, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import classes from "./styles";

const CustomMenuItem = forwardRef((props, ref) => {
  const {text, children = null, onClick = null, disableItem = false, isLinkActive = false} = props;
  // Used ref to solve <Function components cannot be given refs> warning
  return (
    <MenuItem disabled={disableItem} onClick={onClick} ref={ref} sx={classes.menuItem}>
      <Box sx={classes.listItem}>
        {children &&
          <ListItemIcon sx={{...classes.listItemIcon, ...(isLinkActive && classes.linkActive)}} >
            {children}
          </ListItemIcon>
        }
        <ListItemText sx={{...classes.listItemIcon, ...(isLinkActive && classes.linkActive)}} primary={text} />
      </Box>
    </MenuItem>
  );
})

CustomMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disableItem: PropTypes.bool,
  isLinkActive: PropTypes.bool
};

export default CustomMenuItem;