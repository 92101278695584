import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, FormControlLabel, Switch, Grid, Box, Typography, FormControl, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FatInputComponent = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;
    const handleNegligibleFatChange = (event) => {
        handleChange({ target: { name: event.target.name, value: event.target.checked } });
        if (event.target.checked) {
            handleChange({ target: { name: 'fat', value: '' } });
            handleChange({ target: { name: 'saturates', value: '' } });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider sx={classes.divider} />
                <Box sx={classes.fieldHeader}>
                    <Typography sx={classes.fieldHeaderText}>{`${M.get('labels.fatTitle')} *`}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <FormControlLabel
                        control={
                            <Switch
                                name="negligibleFat"
                                checked={formData.negligibleFat}
                                onChange={handleNegligibleFatChange}
                                color="primary"
                            />
                        }
                        disabled={disabled}
                        label={M.get('labels.fatLabel')}
                    />
                </FormControl>
            </Grid>
            {!formData.negligibleFat && (
                <>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={M.get('labels.fat')}
                                name="fat"
                                required
                                value={formData.fat || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}
                                variant="outlined"
                                error={!!errors?.fat}
                                helperText={
                                    errors?.fat ?
                                        errors.fat : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <>
                                                  <Typography
                                                        sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                    >
                                                    <b>According to Regulation (EU) 1169/2011</b>, ‘fat’ means total lipids, and includes phospholipids. This component generally does not exist or is present in negligible amounts in wines.
                                                  </Typography>
                                                </>
                                              }
                                        >
                                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={M.get('labels.saturates')}
                                name="saturates"
                                required
                                value={formData.saturates || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}                                
                                variant="outlined"
                                error={!!errors?.saturates}
                                helperText={
                                    errors?.saturates ?
                                        errors.saturates : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <>
                                                  <Typography
                                                        sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                    >
                                                    <b>According to Regulation (EU) 1169/2011</b>, ‘saturates’ means fatty acids without double bond. Saturates are considered negligible if they are ≤ 0.1 g/100ml
                                                  </Typography>
                                                </>
                                              }
                                        >
                                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider sx={classes.divider} />
            </Grid>
        </Grid>
    );
});

export default FatInputComponent;
