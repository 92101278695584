import { matchIsValidTel } from 'mui-tel-input';

// eslint-disable-next-line security/detect-unsafe-regex
const EMAIL_PATTERN = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

const URL_PATTERN = /^https:\/\/[a-zA-Z0-9-._~:\\/?#[\]@!$&'()*+,;=]+$/;

const isEmail = (email) => EMAIL_PATTERN.test(email);

const isValidPhoneNumber = (value) => matchIsValidTel(value);

const isValidUrl = (value) => URL_PATTERN.test(value);

export { EMAIL_PATTERN, isEmail, isValidPhoneNumber, isValidUrl };
