import React from "react";
import { PropTypes } from "prop-types";

import { Box, Checkbox, TextField, Typography, Icon } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import M from 'messages';
import classes from './styles';

const CustomAutocomplete = ({
  items = [],
  selectedValues,
  onToggleOption = null,
  onClearOptions = null,
  getOptionLabel = (value) => value,
  inputLabel = '',
  inputError = false,
  size = '',
  limitTags = -1,
  multiple = true,
  withCheckbox = true,
  selectedClassName = '',
  onRemoveAll = null,
  icon = '',
  iconClassName = '',
}) => {
  const filter = createFilterOptions();
  const allSelected = multiple ? items.length === selectedValues.length : false;
  const handleToggleRemoveAll = () => onRemoveAll && onRemoveAll();

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.value === "remove-all")) {
        if (selectedOptions.length > 1) {
          handleToggleRemoveAll();
        }
      } else if (onToggleOption) {
        onToggleOption(selectedOptions.map(i => i.value));
      }
    } else if (reason === "clear" && onClearOptions) {
      onClearOptions();
    }
  };

  const handleChangeSingle = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      onToggleOption(selectedOptions.value);
    } else if (reason === "clear" && onClearOptions) {
      onClearOptions();
    }
  };

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.value === "select-all"
        ? { checked: allSelected }
        : {};
    
    return (
      <Box sx={option.value === 'remove-all' ? classes.removeAll : classes.liElement} {...props} >
        {withCheckbox && option.value !== 'remove-all' && 
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
            {...selectAllProps}
          />
        }
        {getOptionLabel(option)}
        {option.value !== 'remove-all' && <Icon sx={classes[iconClassName]}> {icon} </Icon >}
      </Box>
    );
  };

  const inputRenderer = (params) => (
    <TextField
      {...params}
      placeholder={inputLabel}
      variant="outlined"
      error={inputError}
      sx={classes.textField}
    />
  );

  const getFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (onRemoveAll && multiple && filtered.length > 0) {
      filtered.push({ title: M.get('actions.clearSelection'), value: "remove-all" })
    }
    return filtered;
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={items}
      value={selectedValues}
      disableCloseOnSelect={multiple}
      getOptionLabel={getOptionLabel}
      filterOptions={getFilterOptions}
      onChange={multiple ? handleChange : handleChangeSingle }
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      isOptionEqualToValue={(option, value) => option.title === value.title}
      sx={{
        '& .MuiAutocomplete-paper': classes.paper,
        '& .MuiAutocomplete-listbox': multiple && onRemoveAll ? classes.listBox : {},
        '& .MuiAutocomplete-option': classes.optionItem,
        '& .MuiInput-root': classes.inputProps,
        '& .MuiAutocomplete-endAdornment': selectedClassName ? classes.endAdornment : {},
        ...classes[selectedClassName]
      }}
      forcePopupIcon
      size={size}
      limitTags={limitTags}
      disableClearable={multiple}
      renderTags={(value) =>
        value.map((option, index) =>
          index < 1 ? (
            <Typography
              key={option.title}
              sx={classes.chipElement}
            >
              {option.title}
            </Typography>) : null
        )
      }
    />
  );
};

CustomAutocomplete.propTypes = {
  items: PropTypes.instanceOf(Array),
  selectedValues:  PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  onToggleOption: PropTypes.func,
  onClearOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  inputLabel: PropTypes.string,
  inputError: PropTypes.bool,
  size: PropTypes.string,
  limitTags: PropTypes.number,
  multiple: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  selectedClassName: PropTypes.string,
  onRemoveAll: PropTypes.func,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default CustomAutocomplete;
