import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import PropTypes from 'prop-types';
import classes from './styles';

const ListWithAccordion = ({
  optionKey = 'name',
  title,
  placeholder,
  value,
  options,
  handleChange,
  handleInputChange,
}) => (
  <Accordion>
    <AccordionSummary
      id="panel1-header"
      aria-controls="panel1-content"
      sx={classes.summary}
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography sx={classes.fieldHeaderText}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Paper sx={classes.paper} component="form">
        <InputBase
          sx={classes.inputBase}
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={handleInputChange}
          inputProps={{ 'aria-label': 'Search and add countries' }}
        />
        <Divider sx={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          sx={classes.circleIcon}
          value={value}
          defaultValue={value}
          onClick={() => handleChange()}
        >
          <PlaylistAddIcon />
        </IconButton>
      </Paper>
      <List sx={classes.listRoot}>
        {options.map((option) => {
          const labelId = `checkbox-list-label-${option.id}`;
          return (
            <ListItem
              key={option.id}
              disablePadding
              secondaryAction={
                <IconButton
                  onClick={() => handleChange(option.id)}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton role={undefined} dense>
                <ListItemText
                  sx={classes.listItemText}
                  id={labelId}
                  primary={option[optionKey]}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </AccordionDetails>
  </Accordion>
);

ListWithAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  optionKey: PropTypes.string,
};

export default ListWithAccordion;
