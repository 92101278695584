import M from 'messages';

export default [
  {
    id: 1,
    value: true,
    title: M.get('users.active')
  },
  {
    id: 2,
    value: false,
    title: M.get('users.inactive')
  }
]