import React, { useState, useCallback, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography, Paper } from '@mui/material';
import { routes } from 'config/index';
import { getMessage } from 'helpers/helper';
import Loading from 'components/common/loading';
import SubscriptionService from 'services/subscriptionService';
import domainData from 'store/domainStore';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import M from 'messages';
import classes from './styles';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manageBillingLoading, setManageBillingLoading] = useState(false);
  const { status, sessionId, userId } = useParams();
  const isSuccessStatus = status === 'success';


  const confirmSubscription = useCallback(
    async () => {
      try {
        setLoading(true);
        await SubscriptionService.confirmSubscription({ sessionId });
        domainData.checkDashboardAccess();
        domainData.checkLabelAccess();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, sessionId]
  );
  useEffect(() => {
    if (sessionId) {
      confirmSubscription();
    }
  }, [confirmSubscription, sessionId]);

  const handleBackButton = () => {
    navigate({
      pathname: userId
        ? routes.profileEdit.path.replace(':id', userId)
        : routes.home.path,
    });
  };

  const handleManageBillingButton = async () => {
    try {
      setManageBillingLoading(true);
      const response = await SubscriptionService.createCustomerPortal();
      window.location.href = response.data.url;
      setManageBillingLoading(false);
    } catch (err) {
      setManageBillingLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
        variant: 'error',
      });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Box sx={classes.root}>
        <Box
            p={2}
            mt={12}
            elevation={3}
            component={Paper}
            sx={classes.successContainer}
        >
            {isSuccessStatus ? <CheckCircleIcon sx={classes.icon} /> : <CancelIcon sx={{...classes.icon, ...classes.errorColor}} />}
            <Typography sx={classes.successText}>
                {isSuccessStatus
                ? M.get('subscription.paymentSuccess')
                : M.get('subscription.paymentCanceled')}
            </Typography>
            <Box sx={classes.buttons} gap={2}>
                <Button variant="outlined" onClick={handleBackButton}>
                    {M.get('subscription.returnPortal')}
                </Button>
                {isSuccessStatus && (
                    <Button variant="outlined" onClick={handleManageBillingButton}>
                    {manageBillingLoading ? (
                        <CircularProgress color="inherit" size={24} />
                    ) :
                        M.get('subscription.manageBilling')
                    }
                    </Button>
                )}
            </Box>
        </Box>
    </Box>
  );
};

export default SubscriptionSuccess;
