import React from 'react';
import { observer } from 'mobx-react';
import { Grid, FormControl, Divider, InputLabel, Box, MenuItem, Select, Typography, FormHelperText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonsGroup from 'components/common/customBtnGroup';
import wineLabelsFormStore from 'store/wineLabelsFormStore';
import formClasses from 'components/common/form/styles';

const ProductTypeComponent = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;
    const productTypeOptions = ['Wine', 'Sparkling Wine', 'Aromatized Wine', 'Other'];
    const otherProductTypeOptions = [
        'New wine still in fermentation',
        'Liqueur wine', 'Grape must', 'Partially fermented grape must', 'Partially fermented grape must extracted from raisined grapes', 
        'Concentrated grape must', 'Rectified concentrated grape must', 'Wine from raisined grapes', 
        'Wine of overripe grapes', 'Wine vinegar', 'Wine in Different Stages of Marketing'
    ];

    const sparklingWineOptions = [
        'Sparkling Wine', 'Quality sparkling wine', 'Quality aromatic sparkling wine', 'Aerated sparkling wine',
        'Semi-sparkling wine', 'Aerated semi-sparkling wine'
    ];

    const handleProductTypeChange = (name, value) => {
        const e = { target: { name, value } };
        wineLabelsFormStore.clearCustomFields('otherProductType');  // Clear otherProductType
        handleChange(e);
    };

    const handleOtherProductTypeChange = (event) => {
        wineLabelsFormStore.clearCustomFields('otherProductType'); // Clear otherProductType
        const { value } = event.target;
        if (value === 'Sparkling Wine') {
            const productTypeEvent = { target: { name: 'productType', value } };
            handleChange(productTypeEvent);
            return;
        };
        handleChange(event);
    };

    const configOptions = {
        label: 'Product Type',
        name: 'productType',
        fields: productTypeOptions.map(option => ({ value: option, label: option }))
    };

    return (
        <>
            <Grid item xs={12}>
                <Box mb={1}>
                    <Divider sx={classes.divider} />
                    <Box display="flex" alignItems="center">
                        <Typography sx={classes.fieldHeaderText} component='h1'>
                            {`${M.get('labels.product.type')} *`}
                        </Typography>
                        <Tooltip
                            title={
                                <Typography
                                    sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                >
                                    See the detailed description of the products in Regulation (EU) 1308/2013, Annex VII, Part II.
                                </Typography>
                                }
                            placement='top'
                            >
                            <HelpOutlineIcon sx={classes.helpIcon} />
                        </Tooltip>
                    </Box>
                </Box>
                <ButtonsGroup
                    disabled={disabled}
                    groupObject={configOptions}
                    handleChange={handleProductTypeChange}
                    value={formData.productType || ''}
                    noTranslation
                />
            </Grid>
            {formData.productType === 'Sparkling Wine' && (
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="sparklingWineType">
                            {`${M.get('labels.product.productTypeSparkling')} *`}
                        </InputLabel>
                        <Select
                            fullWidth
                            id="sparklingWineType"
                            label={M.get('labels.product.productTypeSparkling')}
                            name="otherProductType"
                            size="small"
                            required
                            value={formData.otherProductType || 'Sparkling Wine'}
                            onChange={handleOtherProductTypeChange}
                            variant="outlined"
                            disabled={disabled}
                        >
                            {sparklingWineOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {formData.productType === 'Other' && (
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="otherWineType" sx={{ ...(errors.otherProductType && formClasses.invalidSelect) }}>
                            {`${M.get('labels.product.productTypeOther')} *`}
                        </InputLabel>
                        <Select
                            fullWidth
                            id='otherWineType'
                            required
                            label={M.get('labels.product.productTypeOther')}
                            name="otherProductType"
                            size="small"
                            value={formData.otherProductType || ''}
                            error={!!errors.otherProductType}
                            onChange={handleOtherProductTypeChange}
                            variant="outlined"
                            disabled={disabled}
                        >
                            {otherProductTypeOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.otherProductType && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')}</FormHelperText>}
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider sx={errors.productType ? classes.errorHelperText : {}} />
                {errors.productType && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')}</FormHelperText>}
            </Grid>
        </>
    );
});

export default ProductTypeComponent;