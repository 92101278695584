import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
    fieldHeader: {
        margin: '0',
        '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    fieldHeaderText: {
        marginRight: '10px',
        fontWeight: '600',
        fontSize: '14px',
        color: theme.palette.primary.sideBarTextColor,
    },
    formControl: {
        marginTop: '5px',
    },
    fieldHeaderIcon: {
        color: theme.palette.primary.sideBarIconColor,
        opacity: '0.6',
        cursor: 'pointer',
        marginRight: '4px'
    },
    fieldHeaderWithTooltip: {
        display: 'flex',
        alignItems: 'center',
    }
}