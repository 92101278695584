import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, FormControlLabel, Switch, Grid, Box, Typography, FormControl, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Carbohydrates = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;
    const handleCarbohydratesChange = (event) => {
        handleChange({ target: { name: event.target.name, value: event.target.checked } });
        if (event.target.checked) {
            handleChange({ target: { name: 'carbohydrates', value: '' } });
            handleChange({ target: { name: 'sugars', value: '' } });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={classes.fieldHeader}>
                    <Typography sx={classes.fieldHeaderText}>{`${M.get('labels.carbohydratesTitle')} *`}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <FormControlLabel
                        control={
                            <Switch
                                name="negligibleCarbohydrates"
                                checked={formData.negligibleCarbohydrates}
                                onChange={handleCarbohydratesChange}
                                color="primary"
                            />
                        }
                        disabled={disabled}
                        label={M.get('labels.carbohydratesLabel')}
                    />
                </FormControl>
            </Grid>
            {!formData.negligibleCarbohydrates && (
                <>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={M.get('labels.carbohydrates')}
                                name="carbohydrates"
                                required
                                value={formData.carbohydrates || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}
                                variant="outlined"
                                error={!!errors?.carbohydrates}
                                helperText={
                                    errors?.carbohydrates ?
                                        errors.carbohydrates : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <>
                                                  <Typography
                                                        sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                    >
                                                    <b>According to Regulation (EU) 1169/2011</b>, ‘carbohydrate’ means any carbohydrate that is metabolized by humans and includes polyols. This value represents the total carbohydrates in wine, calculated from the entered residual sugar and glycerin (polyol). You may adjust this manually.
                                                  </Typography>
                                                </>
                                              }
                                        >
                                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={M.get('labels.sugars')}
                                name="sugars"
                                required
                                value={formData.sugars || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}
                                variant="outlined"
                                error={!!errors?.sugars}
                                helperText={
                                    errors?.sugars ?
                                        errors.sugars : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <>
                                                  <Typography
                                                        sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                    >
                                                    <b>According to Regulation (EU) 1169/2011</b>, ‘sugars’ means all monosaccharides and disaccharides present in food, but excludes polyols. Sugars are considered negligible if they are ≤ 0.5 g/100ml.
                                                  </Typography>
                                                </>
                                              }
                                        >
                                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                                        </Tooltip>
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider sx={classes.divider} />
            </Grid>
        </Grid>
    );
});

export default Carbohydrates;
