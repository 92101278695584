import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  container: {
    width: '375px',
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px',
    boxShadow: '-6px 4px 15px rgba(0, 0, 0, 0.05), 6px 4px 20px rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,

    '@media (max-width: 640px)': {
      width: '300px',
    },

    '@media (max-width: 320px)': {
      width: '100%',
    }
  },
  title: {
    textAlign: 'left',
    fontSize: 17,
    margin: '0 0 35px 0'
  },
  eyeIcon: {
    padding: '6px'
  },
  requirementContainer: {
    padding: '24px 8px'
  },
  submit: {
    height: '40px',
    textTransform: 'capitalize'
  },
  submitDisabled: {
    height: '40px',
    textTransform: 'capitalize',
    opacity: 0.6,
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  expiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'left'
  },
  authLoading: {
    backgroundColor: 'white'
  },
}