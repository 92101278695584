import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  page: {
    width: '100%',
    height: `calc(100vh - 64px)`,
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    textAlign: 'start',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
  },
  pageContent: {
      flex: '1 1 auto',
      position: 'relative',
      overflowY: 'auto',
      height: `calc(100vh - 64px)`,
      margin: '24px 24px 0 24px',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)',
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  card: {
    marginTop: '24px',
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'block',
    textAlign: 'start',
    '&:last-child': {
      padding: '24px',
    }
  },
  pageFooter: {
    boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.05)',
    zIndex: 1,
    textTransform: 'none'
  },
  footerPart: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '84px',
    backgroundColor: 'white',
    alignContent: 'center',
    padding: '0 32px 0 24px',
    minWidth: '416px',
    '& > button': {
        textTransform: 'none',
        margin: '0 0 0 20px'
    }
  },
  fieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  fieldHeaderText: {
    marginBottom: '5px',
    fontWeight: 500
  },
  ourImpactTitle: {
    fontWeight: '600',
    fontSize: '16px',
  },
  fields: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldValue: {
    wordWrap: 'break-word',
    width: '100%',
    '& > div > input': {
      color: theme.palette.primary.sideBarTextColor,
      '&:disabled': { opacity: 0.6 },
    },
  },
  divider: {
    width: '100%',
    marginBottom: '16px',
    marginTop: '16px'
  }
}