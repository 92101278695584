import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Link } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import AddIcon from '@mui/icons-material/Add';

import routes from 'config/route';
import domainStore from 'store/domainStore';

import M from 'messages';
import classes from './styles';

function EmptyData({ title, onCreateClick = null, icon = null, titleClassName = '', rootClassName = 'root' }) {
  const navigate = useNavigate();
  const location = useLocation();
  const spList = domainStore.getSPList();
  

  const onCreate = () => {
    if (onCreateClick && typeof onCreateClick === 'function') {
      onCreateClick();
    } else {
      navigate(`${location.pathname}/create`);
    }
  }

  return (
    <Box
      sx={{...classes[rootClassName],
        ...(!!titleClassName && rootClassName !== 'createOrderRoot' && classes.backgroundWhite),
      }}
    >
      <Box sx={classes.iconHeader}>
        {icon || <InboxIcon sx={classes.icon}/>}
        <Box sx={classes.container}>
          <Box>
            {
              spList.length ? 
                <>
                  {M.get(`emptyPage.${title}Empty`)}
                </>
                :
                <>
                  {M.get(`emptyPage.createSpForMenuItem`).split("{link}")[0] || ''}
                  {/* eslint-disable-next-line */}
                  <Link component={RouterLink} to={routes.serviceProviderCreate.path} sx={classes.link} >{M.get('emptyPage.serviceProviderText')}</Link>
                  {M.get(`emptyPage.createSpForMenuItem`).split("{link}")[1] || ''}
                </>
            }
          </Box>
        </Box>
        <Box sx={classes.footer}>
           <Grid container spacing={3}>
              {spList.length ? <Grid item xs={12} sm={6}>
                <Button sx={classes.createBtn} variant="contained" color="primary" startIcon={<AddIcon />} onClick={onCreate}>
                  {M.get('actions.createItem')}
                </Button>
              </Grid> : null}
            </Grid>
        </Box>
      </Box>
    </Box>
  );
}

EmptyData.propTypes = {
  title: PropTypes.string.isRequired,
  onCreateClick: PropTypes.func,
  icon: PropTypes.node,
  titleClassName: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default EmptyData;