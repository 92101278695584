import React, { useEffect } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import M from 'messages';
import useDebounce from "components/common/customDebounceHook/useDebounce";
import classes from './styles';


const CustomFilterTextField = ({field, onFilterCallback, searchValue = ''}) => {
  const [searchTerm, setSearchTerm] = React.useState(searchValue);
  const debouncedValue = useDebounce(typeof searchTerm === 'object' ? searchTerm : {[field?.id]: searchTerm}, 1000);

  useEffect(() => {
      setSearchTerm({[field.id]: searchValue});
  }, [field, searchValue]);

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Box sx={classes.box}>
      <FormControl margin='dense' fullWidth variant="outlined">
        <TextField
          sx={searchTerm?.[field.id] && classes[field.selectedClassName]}
          classes={{ root: classes.customTextField }}
          size="small"
          placeholder={M.get(field.label)}
          type={field.type}
          id={field.id}
          value={searchTerm?.[field.id] || ''}
          variant="outlined"
          name={field.id}
          onChange={event => {
            setSearchTerm({[field.id]: event.target.value});
          }}
          InputLabelProps={{style: {fontSize: 14}}}
        />
      </FormControl>
    </Box>
  );
};

CustomFilterTextField.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  searchValue: PropTypes.string,
}

export default CustomFilterTextField;