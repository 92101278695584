import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
    page: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: `calc(100vh - 64px)`,
        borderRadius: 'unset',
        backgroundColor: theme.palette.primary.mainBackground,
        color: theme.palette.primary.main,
        textAlign: 'start',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
    },
    pageContent: {
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'auto',
        height: `calc(100vh - 64px)`,
        margin: '24px 24px 0 24px',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)'
    },
    gridContainer: {
        padding: '32px 0'
    },
    pageFooter: {
        boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        textTransform: 'none'
    },
    title: {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.sideBarTextColor,
        marginRight: '14px'
    },
    secondaryTitle: {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.sideBarTextColor,
        marginRight: '14px',
      },
    subTitle: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.sideBarTextColor,
        marginTop: '10px',
        marginBottom: '10px',
        height: '100%'
    },
    withOpacity: {
        opacity: '0.8',
    },
    subTitleLink: {
        fontWeight: '500',
        fontSize: '14px',
        cursor: 'pointer'
    },
    actionPart: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    boxContainer: {
        borderRadius: '5px 5px 0 0',
        color: theme.palette.primary.sideBarTextColor,
        height: 'fit-content',
        boxShadow: '0px 0px 3px 0px #d4d4d4'
    },
    rightBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        background: 'rgba(194, 194, 194, 0.12)',
        boxShadow: 'none',
    },
    fieldDescription: {
        margin: '16px 0 0 0',
        fontWeight: '300',
        fontSize: '14px',
        lineHeight: '16px',
    },
    helperDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: '19px'
    },
    helperDivCenter: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '19px'
    },
    divider: {
        margin: '12px 0 20px 0'
    },
    dividerSmall: {
        margin: '12px 0 6px 0'
    },
    dividerAccordion: {
        margin: '18px 0'
    },

    buttonActionContainer: {
        borderRadius: '0 0 5px 5px',
        color: theme.palette.primary.sideBarTextColor,
        boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.05)',
    },
    footerPart: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '84px',
        backgroundColor: 'white',
        alignContent: 'center',
        padding: '0 32px 0 24px',
        minWidth: '416px',
        '& > button': {
            textTransform: 'none',
            margin: '0 0 0 20px'
        }
    },
    sectionHeaderText: {
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.sideBarTextColor,
        marginTop: '20px',
    },
    errorHelperText: {
        color: theme.palette.primary.error,
        borderColor: theme.palette.primary.error
    },
    formControl: {
        marginTop: '5px',
        minHeight: '80px',
    },
    fieldHeader: {
        margin: '0',
        '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    fieldHeaderText: {
        marginRight: '10px',
        fontWeight: '600',
        fontSize: '14px',
        color: theme.palette.primary.sideBarTextColor,
    },
    inputBackground: {
        backgroundColor: '#ffffff'
    },
    endAdornment: {
        padding: '0'
    },
    iconCircle: {
        padding: '1px',
        width: '14px',
        height: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        border: '1px solid',
        borderColor: theme.palette.primary.sideBarIconColor,
        opacity: '0.7'
    },
    refreshIcon: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.primary.sideBarIconColor,
        transform: 'scaleX(-1)'
    },
    errorIcon: {
        fill: 'red',
        borderColor: theme.palette.primary.error,
    },
    radioLbl: {
        fontSize: 14,
        fontWeight: 500
    },
    typographyLbl: {
        fontSize: 14,
        fontWeight: 500,
        padding: '0 9px 9px 9px',
    },
    formControlRoot: {
        minWidth: 'fit-content'
    },
    priceAdornment: {
        marginRight: 12
    },
    placeholderColor: {
        marginTop: '0px',
        opacity: 0.7
    },
    withTooltip: {
        display: 'flex',
        alignItems: 'center',
    },
    helpIcon: {
        color: theme.palette.primary.sideBarIconColor,
        opacity: '0.6',
        cursor: 'pointer',
        marginLeft: '4px',
        fontSize: '1.2rem',
        verticalAlign: 'middle',
    },
    select: {
        height: '43px',
        textAlign: 'left'
    },
    checkboxLabel: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0px',
        fontWeight: '600'
    },
    sectionMiddle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px 0'
    },
    superVino: {
        marginTop: '10px'
    },
    invalidSelectContainer: {
        border: '1px solid #f44336',
        borderRadius: '4px',
        '& > fieldset': {
          border: 'none',
        },
        '&:before': {
          borderBottom: '2px solid #f44336',
        },
        '&:after': {
          borderBottom: '2px solid #f44336',
        },
        '&:hover': {
          "&:before": {
            borderColor: "#f44336",
            borderBottomWidth: "2px"
          },
        }
    },
}