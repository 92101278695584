import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
  },
  titleField: {
    width: '100%',
  },
  descField: {
    width: '100%',
  },
  addIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  addIcon: {
    width: '35px',
    height: '20px',
  },
  accordionRoot: {
    margin: '10px 0 !important',
    '&::before': {
        backgroundColor: 'unset'
    }
  },
  accordionExpanded: {
    margin: '10px 0 !important',
    '&:first-of-type': {
        marginTop: '0 !important'
    }
  },
  error: {
    fontSize: '12px',
    color: theme.palette.primary.error,
    borderColor: theme.palette.primary.error
  },
  deleteIcon: {
    width: '35px',
    height: '20px',
    float: 'right',
    backgroundColor: '#f44336',
    '&:hover': {
        backgroundColor: '#d32f2f'
    }
  }
};