import { get, post, put, del } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getKiosksMenus: async (params) => {
    const options = { url: apiEndpoints.menus, params: { ...params } };
    return get(options);
  },
  getKioskMenu: async (id) => {
    const options = { url: apiEndpoints.menu.replace(':id', id) };
    return get(options);
  },
  createKioskMenu: async (data) => {
    const options = { url: apiEndpoints.menus, data };
    return post(options);
  },
  updateKioskMenu: async (data, id) => {
    const options = { url: apiEndpoints.menu.replace(':id', id), data };
    return put(options);
  },
  deleteMenuMany: async (data) => {
    const options = { url: apiEndpoints.menus, data };
    return del(options);
  },
  generateQr: async (id) => {
    const options = { url: apiEndpoints.generateQr.replace(':id', id) };
    return put(options);
  },
  getDefaultsCount: async (id) => {
    const options = { url: apiEndpoints.defaultsCount.replace(':id', id) };
    return get(options);
  }
}