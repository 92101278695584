import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import useDebounce from "components/common/customDebounceHook/useDebounce";
import domainData from "store/domainStore";
import { isSuperAdmin, isPartner } from 'helpers/usersRolesHelper';

import classes from './styles';

const CustomFilterButton = ({ field, onFilterCallback, searchValue = '' }) => {
  const [searchTerm, setSearchTerm] = useState(searchValue);

  const selectedOption = () => {
    let checkedOption;
    field.options.forEach((option) => {
      for (let i=0; i < option.value.length; i+=1) {
        if (searchValue.includes(option.value[i])) {
          checkedOption = option;
        }
      }
    })
    return checkedOption;
  };

  const [selected, setSelected] = useState(searchValue ? selectedOption() : 0);
  const debouncedValue = useDebounce(searchTerm, 500);

  const currentUser = domainData.getCurrentUser();
  const isUserSuperAdmin = isSuperAdmin(currentUser);
  const isUserPartner = isPartner(currentUser);
  const hasAccess = (permission) => permission && field.disabled && field.disabled({ isUserSuperAdmin, isUserPartner });

  const handleClick = (id) => {
    if (id !== selected) {
      const status = field.options.find(item => item.id === id);
      setSearchTerm({[field.id]: status.value});
      setSelected(id);
    }
  };

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Box sx={classes.box}>
      {field.options.map((option) => (
        <Button
          variant='outlined'
          sx={{...classes.colorize, ...(selectedOption().id === option.id && classes[field.selectedClassName])}}
          onClick={() => handleClick(option.id)}
          disabled={hasAccess(option.withPermission)}
          key={option.id}
        >
          {option.title}
        </Button>
      ))}
    </Box>
  );
};

CustomFilterButton.propTypes = {
  searchValue: PropTypes.string,
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
};

export default CustomFilterButton;
