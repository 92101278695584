import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import HotTubIcon from '@mui/icons-material/HotTub';
import PageHeader from 'components/common/pageHeader';

import M from 'messages';
import classes from './styles';

function NotFound() {
  const navigate = useNavigate();

  return (
    <Box sx={classes.root}>
      <PageHeader
        generalPageTitle={M.get('notFound.title')}
        onlyGeneralPageTitle
      />
      <HotTubIcon sx={classes.icon}/>
      <Typography variant="h1" sx={classes.title}> {M.get('notFound.title')} </Typography>
      <Typography variant="h6" sx={classes.description}> {M.get('notFound.description')} </Typography>
      <Button variant="contained" color="secondary" startIcon={<RestoreIcon />} onClick={() => navigate(-1)}>
        {M.get('notFound.goBack')}
      </Button>
    </Box>
  );
}

export default NotFound;