import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
    marginRight: '14px'
  },
  subTitle: {
    marginTop: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
    height: '100%'
  },
  subTitleLink: {
    fontSize: '14px',
    cursor: 'pointer',
    opacity: '0.6',
  },
  description: {
    opacity: '0.8',
    marginTop: '15px',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
    height: '100%'
  },
  displayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}
