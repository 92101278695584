import React, { useEffect, useState  } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Header from 'components/header';
import SideBar from 'components/sidebar';
import CustomThemeProvider from 'config/themes/CustomThemeProvider';
import { isLoggedIn } from 'services/authService';
import { routes } from 'config/index';
import domainData from 'store/domainStore';
import usersStore from 'store/usersStore';
import spStore from 'store/serviceProvidersStore';
import kiosksMenusStore from 'store/kiosksMenusStore';
import Loading from 'components/common/loading';
import catalogueStore from 'store/catalogueStore';
import NotFound from 'pages/notFound';

import classes from './styles';

function PrivateRoute({ component: Component, ...rest }) {
  const token = isLoggedIn();
  const isInitialDataLoaded = domainData.isInitialDataLoaded();
  const currentSP = domainData.getCurrentSP();
  const currentUser = domainData.getCurrentUser();
  usersStore.initializeUserData(currentUser);
  spStore.initializeSpData();
  kiosksMenusStore.initializeKioskMenuData(currentSP);
  catalogueStore.initializeMenuItemData(currentSP);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initData() {
      if (!isInitialDataLoaded) {
        await domainData.initializeData();
        setLoading(false);
      }
      setLoading(false);
    }
    initData();
  }, [isInitialDataLoaded]);

  if (loading) return (<Box sx={classes.loadingRoot}><Loading /></Box>)

  const checkRouteAccess = () => {
    const keysList = Object.keys(routes);
    let routeObj;
    for (let i = 0; i < keysList.length; i += 1) {
      if (routes[keysList[i]].path === rest.path) {
        routeObj = routes[keysList[i]];
        break;
      }
    }

    if (routeObj?.accessRoutes?.includes(token?.usersRole?.name)) {
      return true;
    }
    return false;
  }

  return (
    token ? (
      <Box sx={classes.layout}>
        <CustomThemeProvider>
          <Grid item sm={12} xs={12} md={12}>
            <Header {...rest} />
          </Grid>
          <Box sx={classes.main}>
            <SideBar />
            {checkRouteAccess() ? <Component {...rest} /> : <NotFound />}
          </Box>
        </CustomThemeProvider>
      </Box>
    ) : (
      <Navigate to={{ pathname: routes.login.path }} replace />
    )
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

export default PrivateRoute;