import React from 'react';
import { FormControl, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import M from 'messages';
import classes from './styles';

const MultiSelectButtonsGroup = ({
  groupObject,
  values = [],
  handleChange,
  disabled = false,
  noTranslation = false,
  size = 'medium',
  formStore,
}) => {
  const { fields } = groupObject;

  const getMaxOrder = () => {
    if (!formStore) {
      return 0;
    }
    return formStore.getMaxOrder();
  };

  return (
    <FormControl component="fieldset">
      <Box className={classes.group}>
        {fields.map((item) => (
          <Button
            key={item.value}
            aria-label={item.value}
            variant="outlined"
            sx={{
                ...classes.buttonStyle,
                ...(values.some(v => v.ingredientId === item.value)
                    ? disabled
                        ? classes.buttonStyle['&.disabled']
                        : classes.buttonStyle['&.selected']
                    : classes.buttonStyle['&.not-selected'])
            }}
            onClick={() => handleChange(
                groupObject.name,
                values.some(v => v.ingredientId === item.value)
                    ? values.filter(v => v.ingredientId !== item.value)
                    : [...values, { ingredientId: item.value, order: getMaxOrder() }],
                item,
                values.some(v => v.ingredientId === item.value) ? 'removing' : 'adding'
            )}
            disabled={disabled}
            size={size}
        >
            {noTranslation ? item.label : M.get(item.label)}
        </Button>
        ))}
      </Box>
    </FormControl>
  );
};

MultiSelectButtonsGroup.propTypes = {
  groupObject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
        ingredientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        order: PropTypes.number.isRequired,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  noTranslation: PropTypes.bool,
  size: PropTypes.string,
  formStore: PropTypes.shape({
    getMaxOrder: PropTypes.func.isRequired,
  }),
};

export default MultiSelectButtonsGroup;
