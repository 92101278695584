import { makeAutoObservable, action, computed, extendObservable, set, runInAction } from "mobx";
import SettingsService from 'services/settingsService';

const defaultData = {
  country: '',
  wineType: '',
  wineTypes: [],
  countries: [],
  settingsKeys: [],
  subscriptions: [],
  bulletsChanged: false,
  impactsChanged: false
};

const defaultParams = {
  params: {
    sort: {
      field: "",
      order: ""
    },
    filter: {},
    limit: 50,
    skip: 0,
  }
}

const requiredFields = [];

class Settings {

  getDefaultStoreProps = () => ({
    users: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
    settingsData: JSON.parse(JSON.stringify(defaultData)),
    settingsDataErrors: {},

    listFilteredCountry: [],
    listFilteredWineType: [],

    countriesLoaded: false,
    countriesLoading: false,

    wineTypesLoaded: false,
    wineTypesLoading: false,

    impactsLoaded: false,
    impactsLoading: false,

    plansLoaded: false,
    plansLoading: false,

    isCreateFormChanged: false,

    comingSoonLoaded: false,
    showComingSoon: false,
    
    showEuPlanLoaded: false,
    showEuPlan: false,

    dashboardLoaded: false,
    showDashboard: false,
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  getStoredData = () => this.settingsData;

  @computed
  get dataErrors() {
    return this.settingsDataErrors;
  };

  getCountriesData = async () => {
    if (this.countriesLoaded) {
      return this.settingsData.countries;
    }
    if (this.countriesLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.countriesLoaded) {
            resolve(this.settingsData.countries)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadCountriesData();
    return this.settingsData.countries;
  }

  loadCountriesData = async () => {
    this.countriesLoading = true;
    try {
      const response = await SettingsService.getCountriesOptions();
      if (response) {
        this.settingsData.countries = response.data;
        this.listFilteredCountry = response.data;
        this.countriesLoaded = true;
        this.countriesLoading = false;
      }
    } catch (err) {
      this.countriesLoading = false;
    }
  }

  getWineTypesData = async () => {
    if (this.wineTypesLoaded) {
      return this.settingsData.wineTypes;
    }
    if (this.wineTypesLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.wineTypesLoaded) {
            resolve(this.settingsData.wineTypes)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadWineTypesData();
    return this.settingsData.wineTypes;
  }

  loadWineTypesData = async () => {
    this.wineTypesLoading = true;
    try {
      const response = await SettingsService.getWineTypesOptions();
      if (response) {
        this.settingsData.wineTypes = response.data;
        this.listFilteredWineType = response.data;
        this.wineTypesLoaded = true;
        this.wineTypesLoading = false;
      }
    } catch (err) {
      this.wineTypesLoading = false;
    }
  }

  @action
  getSubscriptionPlansData = async () => {
    if (this.plansLoaded) {
      return this.settingsData.subscriptions;
    }
    if (this.plansLoading) {
      return new Promise(resolve => {
        const checkData = () => {
          if (this.plansLoaded) {
            resolve(this.settingsData.subscriptions)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadPlansData();
    return this.settingsData.subscriptions;
  }

  loadPlansData = async () => {
    this.plansLoading = true;
    try {
      const response = await SettingsService.getSubscriptionPlans();
      if (response) {
        this.settingsData.subscriptions = response.data;
        response.data.forEach(item => {
          this.settingsData[`Bullets${item.id}`] = item.subscriptionPlanBullets;
          this.settingsData[`listFilteredBullets${item.id}`] = item.subscriptionPlanBullets;
          this.settingsData[`bullet_${item.id}`] = '';
        })
        this.plansLoaded = true;
        this.plansLoading = false;
      }
    } catch (err) {
      this.plansLoading = false;
    }
  }

  isDashboardLoaded = () => this.dashboardLoaded;

  getShowDashboard = () => this.showDashboard;

  getDashboard = async () => {
    try {
      const response = await SettingsService.getSetting('dashboard', 'showDashboard');
      runInAction(() => {
        this.showDashboard = response.data.value === 'true';
        this.dashboardLoaded = true;
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  getAllOptionsLoaded = () => this.countriesLoaded || this.wineTypesLoaded || this.impactsLoaded || this.plansLoaded;

  @action
  bulletsChanged = (value = true) => {
    this.settingsData.bulletsChanged = value;
  }

  @action
  impactsChanged = (value = true) => {
    this.settingsData.impactsChanged = value;
  }

  @action
  createFormChanged = (value = true) => {
    this.isCreateFormChanged = value;
  }

  @computed
  get getIsFormChanged() {
    return this.isCreateFormChanged;
  }

  @action
  onChangeField = (event, name) => {
    const { value } = event.target;
    this.settingsData[name] = value;
    
    if (name === 'country') {
      this.listFilteredCountry = this.settingsData.countries.filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
    }
    if (name === 'wineType') {
      this.listFilteredWineType = this.settingsData.wineTypes.filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  @action
  listItemChange = (deleteId, type, listType) => {
    const typeWithFirstLetterUpper = type.charAt(0).toUpperCase() + type.slice(1);
    if (deleteId) {
      this.createFormChanged();
      this.settingsData[listType] = this.settingsData[listType].filter(i => i.id !== deleteId);
      this[`listFiltered${typeWithFirstLetterUpper}`] = this.settingsData[listType].filter(i => i.name.toLowerCase().includes(this.settingsData[type].toLowerCase()));
    } else if (this.settingsData[type]) {
      this.createFormChanged();
      this.settingsData[listType].push({
        id: `newId-${this.settingsData[listType].length}`,
        name: this.settingsData[type]
      });
      this.settingsData[type] = '';
      this[`listFiltered${typeWithFirstLetterUpper}`] = this.settingsData[listType];
    }
  }

  @action
  listBulletItemChange = (deleteId, bulletName, bulletsList) => {
    this.bulletsChanged()
    if (deleteId) {
      this.createFormChanged();
      this.settingsData[bulletsList] = this.settingsData[bulletsList].filter(i => i.id !== deleteId);
      this.settingsData[`listFiltered${bulletsList}`] = this.settingsData[bulletsList].filter(i => i.bullet.toLowerCase().includes(this.settingsData[bulletName].toLowerCase()));
    } else if (this.settingsData[bulletName]) {
      this.createFormChanged();
      this.settingsData[bulletsList].push({
        id: `newId-${this.settingsData[bulletsList].length}`,
        bullet: this.settingsData[bulletName]
      });
      this.settingsData[bulletName] = '';
      this.settingsData[`listFiltered${bulletsList}`] = this.settingsData[bulletsList]
    }
  }

  @action
  onChangeBulletField = (event, currentBullet, bulletsList) => {
    const { value } = event.target;
    this.settingsData[currentBullet] = value;
    this.settingsData[`listFiltered${bulletsList}`] = this.settingsData[bulletsList].filter(i => i.bullet.toLowerCase().includes(value.toLowerCase()));
  }

  @computed
  get getFilteredCountryList() {
    return this.listFilteredCountry;
  }

  @computed
  get getFilteredWineTypesList() {
    return this.listFilteredWineType;
  }

  @action
  getBulletsList = (id) => this.settingsData[`listFilteredBullets${id}`];

  @action
  impactsOnChange = (event, key) => {
    this.createFormChanged();
    this.impactsChanged();
    const { name, value } = event.target;
    const currentItem = this.settingsData.settingsKeys.find(item => item.key === key && item.name === name);
    currentItem.value = value
    if (!value && requiredFields.includes(name)) {
      this.settingsDataErrors[name] = 'required';
    } else {
      delete this.settingsDataErrors[name];
    }
  }

  @action
  onChangeSwitchField = (event, key) => {
    this.createFormChanged();
    this.impactsChanged();
    const { name, checked } = event.target;
    const currentItem = this.settingsData.settingsKeys.find(item => item.key === key && item.name === name);
    currentItem.value = checked.toString();
    this.settingsData[key][name] = checked.toString();
  }

  getOurImpactsData = async () => {
    if (this.impactsLoaded) {
      return this.settingsData.impacts;
    }
    if (this.impactsLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.impactsLoaded) {
            resolve(this.settingsData.impacts)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadOurImpactsData();
    return this.settingsData.impacts;
  }

  loadOurImpactsData = async () => {
    this.impactsLoading = true;
    try {
      const response = await SettingsService.getSettings();
      if (response) {
        this.settingsData.settingsKeys = response.data;
        response.data.forEach(item => {
            requiredFields.push(item.name)
            if (!this.settingsData[`${item.key}`]) {
              this.settingsData[`${item.key}`] = {}
            }
            this.settingsData[item.key][item.name] = item.value
          }
        )
        this.impactsLoaded = true;
        this.impactsLoading = false;
      }
    } catch (err) {
      this.impactsLoading = false;
    }
  }

  @action
  handleAddImpactRow = () => {
    this.createFormChanged();
    this.settingsData.impacts.push({
      name: '',
      count: '',
      index: this.settingsData.impacts.length
    });
  }

  @action
  handleRemoveImpactRow = (index) => {
    this.createFormChanged();
    this.settingsData.impacts = this.settingsData.impacts.filter((_item, impactIndex) => impactIndex !== index);
  }

  @action
  onChangeImpact = (event, optionIndex) => {
    this.createFormChanged();
    const { value, name } = event.target;
    const data = this.settingsData.impacts.find((_item, index) => index === optionIndex);

    if (data) {
      data[name] = value;
    }
    return true;
  }


  getComingSoon = async () => {
    try {
      const response = await SettingsService.getSetting('comingSoon', 'showComingSoon');
      this.showComingSoon = response.data.value === 'true';
      this.comingSoonLoaded = true;
      return response;
    } catch (err) {
      return err;
    }
  }

  isComingSoonLoaded = () => this.comingSoonLoaded;

  getShowComingSoon = () => this.showComingSoon;

  getIsShowEuPlan = async () => {
    try {
      const response = await SettingsService.getSetting('comingSoon', 'showEuPlan');
      runInAction(() => {
        this.showEuPlan = response.data.value === 'true';
        this.showEuPlanLoaded = true;
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  isShowEuPlanLoaded = () => this.showEuPlanLoaded;

  getShowEuPlan = () => this.showEuPlan;

}

export default new Settings();