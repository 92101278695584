import { makeAutoObservable, action, toJS } from "mobx";
import WineLabelsService from 'services/wineLabelsService';

class WineLableQRCodeStore {
    QRData = {
        labelId: null,
        qrColor: '#000000',
        backgroundColor: '#ffffff',
        logo: null,
        qrStyle: 'dots',
        eyeStyle: [
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] }
        ],
        qrUrl: '',
        qrRedirectUrl: ''
    };

    initialState = {};

    constructor() {
        makeAutoObservable(this);
        this.initialState = toJS({
            QRData: this.QRData,
        });
    }

    resetStore() {
        const initialStateCopy = toJS(this.initialState); // Deep copy to avoid mutations
        Object.assign(this, initialStateCopy);
    }

    @action
    getQRData = () => this.QRData;

    setQrColor = (color) => {
        this.QRData.qrColor = color;
    }

    setBackgroundColor = (color) => {
        this.QRData.backgroundColor = color;
    }

    setLogo = (logo) => {
        this.QRData.logo = logo;
    }

    setQrStyle = (style) => {
        this.QRData.qrStyle = style;
    }

    setEyeStyle = (eyeStyle) => {
        this.QRData.eyeStyle = eyeStyle;
    }

    @action
    setWineLableQRData = (data) => {
        const { id, qrUrl, qrRedirectUrl, qrColor, logo, qrStyle, eyeStyle, backgroundColor } = data;
        this.QRData.id = id;
        this.QRData.qrUrl = qrUrl;
        this.QRData.qrRedirectUrl = qrRedirectUrl;
        this.QRData.qrColor = qrColor || '#000000';
        this.QRData.logo = logo || null;
        this.QRData.qrStyle = qrStyle || 'dots';
        this.QRData.eyeStyle = eyeStyle || [
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
            { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] }
        ];
        this.QRData.backgroundColor = backgroundColor || '#ffffff';
    }

    @action
    loadData = async (id) => {
        const response = await WineLabelsService.getQRCode(id);
        if (response) {
            this.setWineLableQRData(response.data);
        }
    }

    @action
    updateQRCode = async (data, id) => {
        const payloadId = id || this.QRData.id;
        const payloadData = data || this.QRData;
        const response = await WineLabelsService.updateQRCode(payloadData, payloadId);
        return response;
    }
}

const wineLableQRCodeStore = new WineLableQRCodeStore();
export default wineLableQRCodeStore;