import { get, post, put, del } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getCatalogues: async (params) => {
    const options = { url: apiEndpoints.catalogs, params: { ...params } };
    return get(options);
  },
  getCatalog: async (id) => {
    const options = { url: apiEndpoints.catalog.replace(':id', id) };
    return get(options);
  },
  createCatalog: async (data) => {
    const options = { url: apiEndpoints.catalogs, data };
    return post(options);
  },
  deleteCatalogMany: async (data) => {
    const options = { url: apiEndpoints.catalogs, data };
    return del(options);
  },
  updateCatalog: async (data, id) => {
    const options = { url: apiEndpoints.catalog.replace(':id', id), data };
    return put(options);
  },
  getCatalogCount: async (params) => {
    const options = { url: apiEndpoints.getCatalogCount, params: { ...params } };
    return get(options);
  }
}
