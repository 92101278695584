import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  container: {
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px',
    boxShadow: '-6px 4px 15px rgba(0, 0, 0, 0.05), 6px 4px 20px rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1
  },
  title: {
    textAlign: 'left',
    fontSize: '20px',
    margin: '0px'
  },
  description: {
    textAlign: 'left',
    fontSize: '16px',
    margin: '16px 0 32px 0'
  },
  send: {
    height: '40px',
    textTransform: 'none'
  },
  sendDisabled: {
    height: '40px',
    textTransform: 'none',
    opacity: 0.6,
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  backContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '46px 0 24px 0'
  },
  arrow: {
    color: theme.palette.primary.main,
    marginRight: '12px'
  },
  back: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  backTxt: {
    margin: 0,
    fontWeight: 500,
    fontSize: '18px'
  },
  successDescription: {
    textAlign: 'left',
    fontSize: '16px',
    margin: '16px 0 0 0'
  },
  emailImg: {
    marginBottom: '32px'
  },
  activeText: {
    color: theme.palette.primary.main,
  }
}