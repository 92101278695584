import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, TableCell, Typography } from '@mui/material';
import { getCellPadding } from 'helpers/tableStyleHelper';
import classes from './styles';

const UserName = (props) => {
  const {data, cellItem } = props;
  const {cellPaddingRight, cellPaddingLeft} = cellItem;

  return (
    <TableCell
      align={cellItem.textAlign || "left"}
      sx={classes.tableCellItem}
      style={{...getCellPadding(cellPaddingRight, cellPaddingLeft)}}
    >
      <Box sx={classes.content}>
        <Avatar src={data.image} sx={classes.avatar}>{data.name.charAt(0)}</Avatar>
        <Box sx={classes.rightPart}>
          <Typography sx={classes.name}>{data.name}</Typography>
          <Typography sx={classes.email}>{data.email}</Typography>
        </Box>
      </Box>
    </TableCell>
  )
}

UserName.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
   }).isRequired,
  cellItem: PropTypes.shape({
    textAlign: PropTypes.string,
    cellPaddingRight: PropTypes.string,
    cellPaddingLeft: PropTypes.string
  }),
}

export default UserName;