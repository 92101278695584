import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  container: {
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px'
  },
  title: {
    textAlign: 'left',
    fontSize: '17px',
    margin: '0 0 35px 0'
  },
  img: {
    height: '40px',
    width: '180px',
    marginTop: '64px',
    backgroundSize: 'contain',
  },
  backgroundImg: {
    position: 'absolute',
    left: 0,
    bottom: 0
  },
  submit: {
    height: '40px',
    textTransform: 'capitalize'
  },
  forgotPasswordLink: {
    color: theme.palette.primary.sideBarTextColor,
    opacity: 0.6,
    textDecoration: 'none',
    margin: '24px 0 16px 0'
  },
  contactUsLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  logoContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  logoWine: {
    maxWidth: '160px',
  },
}