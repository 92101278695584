import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  optionItem: {
    '&:hover': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(27, 88, 135, 0.1)'
    },
  },
  chipElement: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    fontSize: '14px',
    maxWidth: '137px'
  },
  colorizeSelected: {
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& > svg": {
      color: theme.palette.primary.contrastText,
    },
    "& div": {
      color: theme.palette.primary.contrastText,
    }
  },
  endAdornment: {
    '& > button': {
      color: theme.palette.primary.contrastText,
    }
  },
  textField: {
    '& > div:first-of-type': {
      fontWeight: 500,
      fontSize: '14px'
    }
  },
  paper: {
    minWidth: '400px',
    fontWeight: 400,
    fontSize: '14px',
  },
  removeAll: {
    minHeight: '20px',
    width: '100%',
    padding: '8px 0',
    alignItems: 'center'
  },
  listBox: {
    '& > li:last-child': {
      position: 'absolute',
      bottom: -37,
      left: 0,
      minWidth: '400px',
      backgroundColor: 'white',
      zIndex: 1,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      '&:hover': {
        backgroundColor: '#e8eef3',
      },
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px'
    },
  },
  liElement: {
    display: 'flex',
    alignItems: 'center'
  },
  optionIcon: {
    padding: '0 10px',
    color: theme.palette.primary.main,
  }
}