import catalogueStore from 'store/catalogueStore';
import kiosksMenusStore from 'store/kiosksMenusStore';

import usersStore from 'store/usersStore';
import serviceProvidersStore from 'store/serviceProvidersStore';
import domainData from 'store/domainStore';
import sidebarStore from 'store/ui/sidebarStore';

export default () => {
    // NOTE: uncomment this line if need to delete all local storage data
    // localStorage.clear();

    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentServiceProvider');
    localStorage.removeItem('appTheme');   
    domainData.reset();
    sidebarStore.reset();
    catalogueStore.reset();
    kiosksMenusStore.reset();
    usersStore.reset();
    serviceProvidersStore.reset();
}
