import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

import M from 'messages';
import useDebounce from "components/common/customDebounceHook/useDebounce";
import domainData from 'store/domainStore';
import classes from './styles';

const DEFAULT_SELECT_MAX_WIDTH = 250;

const CustomFilterSelect = ({field, onFilterCallback, searchValue = ''}) => {
  const [searchTerm, setSearchTerm] = React.useState(searchValue);
  const debouncedValue = useDebounce(typeof searchTerm === 'object' ? searchTerm : {[field?.id]: searchTerm}, 1000);
  const [modifiedOptions, setModifiedOptions] = useState([]);

  useEffect(() => {
      setSearchTerm({[field.id]: searchValue});
  }, [field, searchValue]);

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const getCurrentServiceProvider = () => {
    const currentSP = domainData.getCurrentSP();
    const currSP = currentSP && currentSP !== 'allSP' ? { currentServiceProvider: currentSP } : {};
    return currSP;
  }

  useEffect(() => {
    if(typeof field.options === 'function') {
      const newParams = { params: {...getCurrentServiceProvider()} };
      field.options(newParams).then(res => {
        setModifiedOptions(field.adapterCallback(res.data.data));
      })
    } else {
      setModifiedOptions(field.options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  const handleOnChang = (e) => {
    const {value} = e.target;
    setSearchTerm({[field.id]: value})
  }

  return (
    <Box sx={classes.box} style={field.maxWidth ? { maxWidth: field.maxWidth } : {maxWidth: DEFAULT_SELECT_MAX_WIDTH}}>
      <FormControl margin='dense' fullWidth variant="outlined">
         <InputLabel size='small' sx={classes.label} shrink={false}>{searchTerm?.[field.id] ? "" : M.get(`${field.label}`)}</InputLabel>
        <Select
          variant="outlined"
          sx={{
            ...classes.selectElement,
            ...(searchTerm?.[field.id] && [classes[field.selectedClassName]]),
            ...classes.selectRoot
          }}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }}
          value={searchTerm?.[field.id] ?? ''}
          onChange={handleOnChang}
          name={field.id}
        >
          <MenuItem value="" key='empty'> <em><Box sx={classes.menuItem}>{field.insteadNone || M.get('filterFields.none')}</Box></em> </MenuItem>
          {modifiedOptions?.map((opt) => (
            <MenuItem key={opt.id || opt.value} value={opt.id || opt.value}>
              <Box sx={classes.menuItem} style={field.maxWidth ? { maxWidth: field.maxWidth } : {}}>
                {opt.title}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CustomFilterSelect.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  searchValue:  PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default CustomFilterSelect;