import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';

import CustomFilter from 'components/common/customFilter';
import CustomSearch from 'components/common/customSearchV2';
import CustomToolbarAction from 'components/common/customToolbarAction';

import M from 'messages';
import classes from './styles';

const CustomTableToolbar = (props) => {
  const {
    onSearchCallback,
    filteredParams,
    filterFields = [],
    onFilterCallback = null,
    openModalApprove,
    isApproveActionActive = false,
    openModalReject
  } = props;

  const searchValue = filteredParams?.params?.search?.value || '';

  return (
    <Toolbar sx={classes.root}>
      <Box sx={classes.filters}>
        <CustomSearch
          onSearchCallback={onSearchCallback}
          searchValue={searchValue}
        />
        <CustomFilter
          onFilterCallback={onFilterCallback}
          filterFields={filterFields}
          filteredParams={filteredParams}
        />
      </Box>
      <Box sx={classes.actions}>
        {isApproveActionActive && <CustomToolbarAction
          handleOnClick={() => openModalReject()}
          btnText={M.get('actions.reject')}
          tooltipText={M.get('actions.reject')}
          startIcon={<ClearIcon />}
        />}
        {isApproveActionActive && <CustomToolbarAction
          handleOnClick={() => openModalApprove()}
          btnText={M.get('actions.approve')}
          tooltipText={M.get('actions.approve')}
          startIcon={<DoneAllIcon />}
        />}
      </Box>
    </Toolbar>
  );
};

CustomTableToolbar.propTypes = {
  onSearchCallback: PropTypes.func.isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  onFilterCallback: PropTypes.func,
  filterFields: PropTypes.instanceOf(Array),
  openModalApprove: PropTypes.func.isRequired,
  isApproveActionActive: PropTypes.bool,
  openModalReject: PropTypes.func.isRequired,
};

export default CustomTableToolbar;