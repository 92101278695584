import React from 'react';
import { observer } from 'mobx-react';
import { DialogContentText } from '@mui/material';
import Modal from 'components/common/modal';

const SetInMarketModal = observer(({ open, handleClose, handleSubmit, classes }) => (
    <Modal
      open={open}
      leftBtnText="No, Cancel"
      rightBtnText="Yes, Activate for Market"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Are you sure you want to &apos;Activate for Market&apos; this item?"
    >
      <DialogContentText className={classes.moveToModalView}>
        By clicking &apos;Activate for Market&apos; you confirm that the provided information is accurate, as known from the production phase. Once you hit the button, the information cannot be modified to meet EU laws, preventing fraud and misleading consumers. We recommend activating this button once the QR code is finalized and/or placed on the physical label.
      </DialogContentText>
    </Modal>
  )
);

export default SetInMarketModal;
