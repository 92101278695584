import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import formStore from 'store/wineLabelsFormStore';

// Define styles before they are used
const styles = {
  mobileFrame: {
    width: '320px',
    height: `calc(100vh - 64px - 25px - 84px - 50px)`,
    border: '16px solid black',
    borderRadius: '36px',
    position: 'relative',
    overflow: 'hidden',
    margin: '0 auto',
  },
  speaker: {
    width: '60px',
    height: '5px',
    backgroundColor: '#333',
    borderRadius: '10px',
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
};

const MobilePreview = observer(() => { 
  const iframeRef = useRef(null);

  const sendMessageToIframe = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage('fetch-data', formStore.iframeUrl);
    }
  };

  useEffect(() => {
    // Set the sendMessageToIframe function in the store, or call it as needed
    formStore.refreshIframe = sendMessageToIframe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStore.iframeUrl]);

  return (
    <div style={styles.mobileFrame}>
        <div style={styles.speaker} />
        <iframe
        ref={iframeRef}
        src={formStore.iframeUrl}
        title="Page Preview"
        style={styles.iframe}
        />
    </div>
  );
});

export default MobilePreview;