import ServiceProvidersService from 'services/serviceProvidersService';
import { serviceProvidersOptionData } from 'helpers/adapter';

const tableOptions = {
  catalogue: {
    fields: [
      { id: 'id', label: 'table.kiosksMenu.id', type: 'text', sortable: true, width: '10%', textAlign: 'left', headAlign: 'left', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
      { id: 'serviceProviderId', label: 'menuItem.serviceProvider', type: 'text', sortable: true, width: '25%', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
      { id: 'producer', label: 'menuItem.producer', type: 'text', sortable: true, width: '25%' },
      { id: 'name', label: 'menuItem.name', type: 'text', sortable: true, width: '25%' },
      { id: 'country', orderField: 'include_countries_name', label: 'table.sp.country', type: 'text', sortable: true, width: '15%' },
      { id: 'year', label: 'menuItem.year', type: 'text', sortable: true, width: '10%' },
      { id: 'color', label: 'menuItem.color', type: 'text', sortable: true, width: '10%' },
      { id: 'isSuperVino', label: 'table.menuMenuItems.isSuperVino', type: 'bool', showIcon: false, sortable: true, textAlign: 'right', headAlign: 'right', width: '15%' },
      { id: 'tableOrWineNumber', label: 'table.menuMenuItems.tableNumber', type: 'text', sortable: true, textAlign: 'right', headAlign: 'right', width: '15%' },
      { id: 'createdAt', label: 'table.menuMenuItems.createdAt', width: '20%', sortable: true, textAlign: 'right', headAlign: 'right' }
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [
      {id: 'serviceProviderId_in', label: 'menuItem.filter.partner', type: 'custom-multi-autocomplete', options: ServiceProvidersService.getServiceProvidersNames, adapterCallback: serviceProvidersOptionData, withoutParams: true, priority: 1,  optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true, show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
    ],
    searchFields: ['name'],
  },
  pairingGroup: {
    label: 'menuItem.pairingTitle',
    name: 'pairing',
    defaultValue: 'none',
    fields: [
      {value: "Apéritif", label: "Apéritif"},
      {value: "Asian", label: "Asian"},
      {value: "Barbecue", label: "Barbecue"},
      {value: "Burgers", label: "Burgers"},
      {value: "Buffet", label: "Buffet"},
      {value: "Charcuterie", label: "Charcuterie"},
      {value: "Cheese", label: "Cheese"},
      {value: "Chicken", label: "Chicken"},
      {value: "Dairy", label: "Dairy"},
      {value: "Dessert", label: "Dessert"},
      {value: "Fish", label: "Fish"},
      {value: "Foie Gras", label: "Foie Gras"},
      {value: "Gluten-Free", label: "Gluten-Free"},
      {value: "Grilled", label: "Grilled"},
      {value: "Indian", label: "Indian"},
      {value: "Low-Carb", label: "Low-Carb"},
      {value: "Low-Fat", label: "Low-Fat"},
      {value: "Meat", label: "Meat"},
      {value: "Mediterranean", label: "Mediterranean"},
      {value: "Mexican", label: "Mexican"},
      {value: "Moroccan", label: "Moroccan"},
      {value: "Mushroom", label: "Mushroom"},
      {value: "Nut-Free", label: "Nut-Free"},
      {value: "Pasta", label: "Pasta"},
      {value: "Pizza", label: "Pizza"},
      {value: "Roasted", label: "Roasted"},
      {value: "Risotto", label: "Risotto"},
      {value: "Salad", label: "Salad"},
      {value: "Sandwiches", label: "Sandwiches"},
      {value: "Seafood", label: "Seafood"},
      {value: "Soup", label: "Soup"},
      {value: "Spicy Food", label: "Spicy Food"},
      {value: "Steak", label: "Steak"},
      {value: "Stir-fry", label: "Stir-fry"},
      {value: "Sushi", label: "Sushi"},
      {value: "Tacos", label: "Tacos"},
      {value: "Tapas", label: "Tapas"},
      {value: "Vegan", label: "Vegan"},
      {value: "Vegetarian", label: "Vegetarian"},
      {value: "Veggie", label: "Veggie"}
    ]
  },
  colorGroup: {
    label: 'menuItem.colorTitle',
    name: 'color',
    defaultValue: 'red',
    fields: [
      {value: 'red', label: 'menuItem.red'},
      {value: 'white', label: 'menuItem.white'},
      {value: 'orange', label: 'menuItem.orange'},
      {value: 'rose', label: 'menuItem.rose'},
    ]
  },
  sweetnessGroup: {
    label: 'menuItem.sweetnessTitle',
    name: 'sweetness',
    defaultValue: 'red',
    fields: [
      {value: 'brutNature', label: 'menuItem.brutNature'},
      {value: 'extraBrut', label: 'menuItem.extraBrut'},
      {value: 'brut', label: 'menuItem.brut'},
      {value: 'extraDry', label: 'menuItem.extraDry'},
      {value: 'dry', label: 'menuItem.dry'},
      {value: 'demiSec', label: 'menuItem.demiSec'},
      {value: 'doux', label: 'menuItem.doux'},
      {value: 'semiDry', label: 'menuItem.semiDry'},
      {value: 'semiSweet', label: 'menuItem.semiSweet'},
      {value: 'sweet', label: 'menuItem.sweet'}
    ]
  },
  styleGroup: {
    label: 'menuItem.styleTitle',
    name: 'style',
    defaultValue: 'still',
    fields: [
      {value: 'still', label: 'menuItem.still'},
      {value: 'sparkling', label: 'menuItem.sparkling'},
      {value: 'fortified', label: 'menuItem.fortified'},
      {value: 'desert', label: 'menuItem.desert'},
    ]
  },
}

export default tableOptions;