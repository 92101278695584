import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import userProfileStore from 'store/userProfileStore';
import { getMessage } from 'helpers/helper';
import UsersService from 'services/usersService';
import M from 'messages';
import classes from './styles';

const ChangeEmailModal = observer(({ open, handleClose }) => {
  const dataErrors = userProfileStore.getChangeEmailDataErrors();
  const data = userProfileStore.getChangeEmailData();
  const { enqueueSnackbar } = useSnackbar();
  const [sendConfirmationCodeLoading, setSendConfirmationCodeLoading] =
    useState(false);
  const [confirmEmailChangeLoading, setConfirmEmailChangeLoading] =
    useState(false);
  const handleSubmitNewEmail = async () => {
    try {
      setSendConfirmationCodeLoading(true);
      await UsersService.changeEmailSendCode({ newEmail: data.newEmail });
      userProfileStore.onChangeEmailData({
        target: { name: 'step', value: 1 },
      });
    } catch (error) {
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), {
        variant: 'error',
      });
    } finally {
      setSendConfirmationCodeLoading(false);
    }
  };

  const handleConfirmEmailChange = async () => {
    try {
      setConfirmEmailChangeLoading(true);
      await UsersService.confirmEmailChange({
        newEmail: data.newEmail,
        oldEmailCode: data.oldEmailCode,
        newEmailCode: data.newEmailCode,
      });
      handleClose(null, null, true);
    } catch (error) {
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), {
        variant: 'error',
      });
    } finally {
      setConfirmEmailChangeLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{M.get('profile.changeEmail.title')}</DialogTitle>
      <DialogContent>
        {data.step === 0 ? (
          <TextField
            sx={classes.emailInput}
            label={M.get('profile.changeEmail.newEmail')}
            id="email"
            name="newEmail"
            value={data.newEmail}
            onChange={userProfileStore.onChangeEmailField}
            required
            error={!!dataErrors.newEmail}
            helperText={
              dataErrors.newEmail
                ? M.get(`forgotPassword.errors.invalidEmail`)
                : ''
            }
          />
        ) : (
          <>
            <Typography>
              {M.get('profile.changeEmail.confirmationSent')}
            </Typography>
            <TextField
              label={M.get('profile.changeEmail.oldEmailCode')}
              fullWidth
              margin="normal"
              name="oldEmailCode"
              value={data.oldEmailCode}
              onChange={userProfileStore.onChangeEmailData}
            />
            <TextField
              label={M.get('profile.changeEmail.newEmailCode')}
              fullWidth
              margin="normal"
              name="newEmailCode"
              value={data.newEmailCode}
              onChange={userProfileStore.onChangeEmailData}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {data.step === 0 ? (
          <Button
            sx={classes.button}
            variant="contained"
            disabled={
              sendConfirmationCodeLoading ||
              !data.newEmail ||
              !!dataErrors.newEmail
            }
            onClick={handleSubmitNewEmail}
          >
            {sendConfirmationCodeLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              M.get('profile.changeEmail.sendConfirmationCode')
            )}
          </Button>
        ) : (
          <Button
            sx={classes.button}
            variant="contained"
            disabled={
              confirmEmailChangeLoading ||
              !data.oldEmailCode ||
              !data.newEmailCode
            }
            onClick={handleConfirmEmailChange}
          >
            {confirmEmailChangeLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              M.get('profile.changeEmail.confirmEmailChange')
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});

ChangeEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeEmailModal;
