import { variables } from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  main: {
    display: 'flex',
    backgroundColor: theme.palette.primary.backgroundColor,
    marginTop: variables.headerHeight,
    minHeight: `calc(100vh - ${variables.headerHeight})`,
  },
  loadingRoot: {
    textAlign: 'center',
  },
};