import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    overflow: 'hidden',
  },
  cardContent: {
    display: 'block',
    textAlign: 'start',
    '&:last-child': {
      padding: '24px',
    }
  },
  button: {
    backgroundColor: theme.palette.primary.mainBackground,
    boxShadow: 'unset',
    color: theme.palette.primary.main,
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#eff2f5',
      color: theme.palette.primary.main,
      boxShadow: 'unset',
    },
  },
  rowKey: {
    fontWeight: 'bold'
  },
  rowValue: {
    fontWeight: '400'
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  avatarIcon: {
    width: '84px',
    height: '84px',
    marginBottom: '12px'
  },
  editButton: {
    marginLeft: '5px',
    textTransform: 'none',
    padding: '5px 30px'
  },
  accountDetails: {
    width: '55%',
  },
  actionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    textTransform: 'none',
    marginLeft: '16px',
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  subscriptionTitleContainer: {
    marginTop: '25px',
    marginBottom: '40px',
  },
  subscriptionTitleText: {
    fontWeight: '500',
    fontSize: '25px',
  },
  buttonMargin: {
    marginTop: '70px',
  },
  primaryIconButton: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    },
    textTransform: 'none',
    opacity: 1,
    borderRadius: '4px',
    border: '1px solid #1A5787'
  },
  inactiveButton: {
    backgroundColor: 'unset',
    color: '#1A5787',
  },
  primaryButtonHover: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    border: '1px solid #1A5787',
  },
  subscribeButton: {
    width: '90%',
    height: '50px',
    minWidth: '109px',
    fontWeight: '500',
    marginTop: '16px',
  },
  disabledButton: {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  divider: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },

  activePlan: {
    backgroundColor: 'rgb(237, 247, 237)',
  },
  warningPlan: {
    backgroundColor: 'rgb(255, 244, 229)',
  },
  errorPlan: {
    backgroundColor: 'rgb(253, 240, 239)'
  },
  planCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: '500',
    minHeight: '500px'
  },
  planTitle: {
    fontSize: '28px',
    fontWeight: '600',
  },
  planDesc: {
    textAlign: 'center'
  },
  currencyRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontSize: '50px',
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  currency: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '5px 5px 0 0',
  },
  currencyDesc: {
    fontSize: '16px',
  },
  textArea: {
    textAlign: 'center',
  },
  everythingText: {
    fontWeight: '500',
  },
  icon: {
    color: theme.palette.primary.main
  },
  text: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',

    '& p': {
      textAlign: 'left'
    }
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    
    '& p': {
      fontSize: '12px',
      fontWeight: '500',
    }
  },
  saleText: {
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '5px',
    padding: '2px 5px',
    borderRadius: '10px',
    color: '#fff',
  },
  saleTextActive: {
    backgroundColor: '#DD3834',
    transition: '0.5s'
  },
  saleTextDisabled: {
    backgroundColor: '#FAC1BF',
    transition: '0.5s'
  },
  customerPortal: {
    alignContent: 'center',
    textAlign: 'right'
  },
  errorMessage: {
    width: '100%',
    marginBottom: '25px',
  },
  discountedPrice: {
    position: 'relative',
    color: 'grey',
    marginLeft: '5px',
    fontSize: '40px',
    fontWeight: 400,
    "&::after": {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      borderTop: '2px solid grey; / Reduced thickness /',
      transform: 'translateY(-50%)',
    }
  }
}