import React from 'react';
import PropTypes from 'prop-types';

import domainData from 'store/domainStore';
import CustomFilterFormItem from './customFilterFormItem';

const CustomFilter = (props) => {
  const { onFilterCallback, filterFields = [], filteredParams } = props;
  const currentSp = domainData.getCurrentSP();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const currentSpData = domainData.getCurrentSpData();

  const fieldsView = filterFields.map((field) => {
    let show = true;
    if(field.show) {
      if(!field.show({ isUserSuperAdmin, currentSp, currentSpData})) {
        show = false;
      }
    }
    return show ? (
    <CustomFilterFormItem
      field={field}
      onFilterCallback={onFilterCallback}
      searchValue={filteredParams?.params.filter?.[field.id] || ''}
      key={field.id}
      filteredParams={filteredParams}
    />
    ) : null
  });

  return (
    <>
      {fieldsView}
    </>
  );
};

CustomFilter.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  filterFields: PropTypes.instanceOf(Array),
  filteredParams: PropTypes.instanceOf(Object).isRequired,
};

export default CustomFilter;