import ServiceProvidersService from 'services/serviceProvidersService';
import { serviceProvidersOptionData } from 'helpers/adapter';

const tableOptions = {
  fields: [
    { id: 'id', label: 'table.kiosksMenu.id', type: 'text', sortable: true, width: '10%', show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
    { id: 'serviceProviderId', label: 'table.kiosksMenu.partner', type: 'text', sortable: true, width: '20%', show: ({ isUserSuperAdmin }) => isUserSuperAdmin},
    { id: 'menuName', label: 'table.kiosksMenu.name', type: 'text', sortable: true, width: '30%' },
    { id: 'menuItemsCount', label: 'table.kiosksMenu.itemsCount', type: 'text', sortable: false, width: '15%', textAlign: 'right', headAlign: 'right'},
    { id: 'shouldResetWineRating', label: 'table.kiosksMenu.resetWineRating', type: 'bool', showIcon: false, sortable: true, width: '15%', textAlign: 'right', headAlign: 'right'},
    { id: 'isDefault', label: 'table.kiosksMenu.isDefault', type: 'bool', showIcon: false, sortable: true, textAlign: 'right', headAlign: 'right', width: '15%' },
    { id: 'createdAt', label: 'table.kiosksMenu.createdDate', type: 'text', sortable: true, width: '15%', textAlign: 'right', headAlign: 'right'},
  ],
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  filterFields: [
    // {id: 'menuName', label: 'table.kiosksMenu.name', type: 'text', priority: 1},
    {id: 'serviceProviderId_in', label: 'kiosksMenu.filter.partner', type: 'custom-multi-autocomplete', options: ServiceProvidersService.getServiceProvidersNames, adapterCallback: serviceProvidersOptionData, withoutParams: true, priority: 1,  optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true, show: ({ isUserSuperAdmin }) => isUserSuperAdmin },
  ],
  searchFields: ['menuName'],
}

export default tableOptions;