import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  menuItem: {
    maxHeight: 'inherit'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemIcon: {
    minWidth: '40px'
  },
  linkActive: {
    color: theme.palette.primary.main,
  },
  listItemText: {
    '& > span:first-of-type': {
      fontWeight: '500',
      fontSize: '14px'
    }
  }
}