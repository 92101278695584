import { runInAction, makeAutoObservable, action, extendObservable, set, computed } from "mobx";
import SubscriptionService from 'services/subscriptionService';
import ServiceProvidersService from 'services/serviceProvidersService';

import { isSuperAdmin, isPartner, isSuperAdminOrPartner } from "helpers/usersRolesHelper";
import lsConstants from 'constants/local-storage';

import catalogueStore from './catalogueStore';
import kiosksMenusStore from './kiosksMenusStore';

class DomainData {

  getDefaultStoreProps = () => ({
    currentUser: null,
    currentSP: null,
    SPlist: [],
    initialdataLoaded: false,
    initialdataLoading: false,
    initialdata: {},
    dashboardAccess: {
      loaded: false,
      loading: false,
      access: false
    },
    labelAccess: {
      loaded: false,
      loading: false,
      access: false
    }
  })

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  isInitialDataLoaded = () => this.initialdataLoaded;

  @action
  resetInitialData = () => {
    this.currentUser = null;
    this.currentSP = null;
    this.SPlist = [];
    this.initialdataLoaded = false;
    this.initialdataLoading = false;
    this.initialdata = {};
  }

  setCurrentUser = (user) => {
    this.currentUser = user;
    localStorage.setItem(lsConstants.CURRENT_USER, JSON.stringify(user));
  }

  getCurrentUser = () => {
      if (this.currentUser) {
        return this.currentUser;
      }
      runInAction(() => {
        this.currentUser = JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER));
      });
      return this.currentUser;
  };

  userIsSuperAdmin = () => {
    const curr = this.getCurrentUser();
    return isSuperAdmin(curr);
  }

  userIsPartner = () => {
    const curr = this.getCurrentUser();
    return isPartner(curr);
  }

  userIsSuperAdminOrPartner = () => {
    const curr = this.getCurrentUser();
    return isSuperAdminOrPartner(curr);
  }

  initializeData = async () => {
    if (this.initialdataLoaded) {
        return true;
    }
    if (this.initialdataLoading) {
        return new Promise(resolve => {
        const checkData =() => {
            if (this.initialdataLoaded) {
                resolve(true)
            } else {
                setTimeout(checkData, 1000)
            }
        };
        checkData();
        });
    }
    await this.loadInitialData();
    return true;
  }

  loadInitialData = async () => {
    this.initialdataLoading = true;
    try {
      this.currentUser = JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER));
      if (isSuperAdmin(this.currentUser)) {
        const response = await ServiceProvidersService.getServiceProvidersNames();
        runInAction(() => {
          if (response) {
              this.SPlist = response.data.data;
          }
          this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
          this.initialdataLoading = false;
          this.initialdataLoaded = true;
        });
      } else {
        this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
        const response = await ServiceProvidersService.getServiceProvider(this.currentSP);
        runInAction(() => {
          if (response) {
              this.SPlist = [response.data];
          }
          this.initialdataLoading = false;
          this.initialdataLoaded = true;
        });
      }
    } catch (err) {
      this.initialdataLoading = false;
      // enqueueSnackbar('Get kiosks lists error', { variant: 'error' });
    }
  }

  getSPList = () => this.SPlist;

  // get service provider data
  getSPListNames = async() => {
    if (this.SPlist) {
      return this.SPlist;
    }
    const response = await ServiceProvidersService.getServiceProvidersNames();
    if (response) {
      this.SPlist = response.data.data;
    }
    return response.data.data;
  };

  getSPListName = async(id) => {
    if (this.SPlist) {
      return (this.SPlist.find(i => +i.id === +id));
    }
    const response = await ServiceProvidersService.getServiceProvidersNames();
    if (response) {
        this.SPlist = response.data.data;
    }
    return (response.data.data.find(i => +i.id === +id));
  };

  getCurrentSP = () => {
    if (this.currentSP) {
        return this.currentSP;
    }
    runInAction(() => {
      this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
    });
    if (this.userIsPartner()) {
      catalogueStore.setServiceProviderId(this.currentSP);
      kiosksMenusStore.setServiceProviderId(this.currentSP);
    }
    return this.currentSP;
  }

  setCurrentSP = (value) => {
    this.currentSP = value;
    localStorage.setItem(lsConstants.CURRENT_SERVICE_PROVIDER, value);
    catalogueStore.setServiceProviderId(this.currentSP);
    kiosksMenusStore.setServiceProviderId(this.currentSP);
  }

  getCurrentSpData = () => this.SPlist?.find(sp => sp.id === Number(this.currentSP));

  isUserHaveDashboardAccess = async () => {
    if (this.dashboardAccess.loaded) {
      return this.dashboardAccess.access;
    }
    if (this.dashboardAccess.loading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.dashboardAccess.loaded) {
            resolve(this.dashboardAccess.access)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.getDashboardPermission();
    return this.dashboardAccess.access;
  }

  getDashboardPermission = async () => {
    this.dashboardAccess.loading = true;
    try {
      const response = await SubscriptionService.checkDashboardAccess();
      if (response) {
        runInAction(() => {
          this.dashboardAccess = {
            loaded: true,
            loading: false,
            access: response.data.access
          };
        });
      }
    } catch (err) {
      this.dashboardAccess.loading = false;
    }
  }

  checkDashboardAccess = () => {
    this.dashboardAccess = {
      loaded: false,
      loading: false,
      access: false
    };
  }

  @computed
  get isDashboardAccessLoaded() {
    return this.dashboardAccess.loaded;
  }

  isUserHaveLabelAccess = async () => {
    if (this.labelAccess.loaded) {
      return this.labelAccess.access;
    }
    if (this.labelAccess.loading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.labelAccess.loaded) {
            resolve(this.labelAccess.access)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.getLabelPermission();
    return this.labelAccess.access;
  }

  getLabelPermission = async () => {
    this.labelAccess.loading = true;
    try {
      const response = await SubscriptionService.checkLabelAccess();
      if (response) {
        this.labelAccess = {
          loaded: true,
          loading: false,
          access: response.data.access
        };
      }
    } catch (err) {
      this.labelAccess.loading = false;
    }
  }

  checkLabelAccess = () => {
    this.labelAccess = {
      loaded: false,
      loading: false,
      access: false
    };
  }

  @computed
  get isLabelAccessLoaded() {
    return this.labelAccess.loaded;
  }
}

export default new DomainData();
