import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  selectRoot: {
    maxHeight: '40px',
    '&:focus': {
      backgroundColor: 'unset'
    },
    '& > div:first-of-type': {
      fontWeight: 500
    }
  },
  box: {
    minWidth: '200px',
    marginRight: '16px',
    fontSize: '14px',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.primary.sideBarTextColor,
    opacity: 0.5,
    "&.Mui-focused": {
      color: theme.palette.primary.sideBarTextColor,
      opacity: 0.5
    },
  },
  colorizeSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& > svg": {
      color: theme.palette.primary.contrastText,
    },
    "& div": {
      color: theme.palette.primary.contrastText,
    }
  },
  selectElement: {
    fontWeight: 500,
    fontSize: '14px',
  },
  menuItem: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 400,
    fontSize: '14px',
  },
  menuPaper: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    fontWeight: 500,
    fontSize: '14px',
  }
}