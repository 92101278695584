import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import formClasses from 'components/common/form/styles';
import domainData from 'store/domainStore';
import WineInfo from './wineInfo';
import ProducerInfo from './producerInfo';
import LocationInfo from './locationInfo';

const CardInfo = observer(({ formStore, handleChange, classes, M, isUserSuperAdmin, isEdit, disabled = false }) => {
    const maxSymbols = 255;
    const { formData, errors } = formStore;
    const currentSPId = domainData.getCurrentSP();

    const isSPDisabled = () => {
        if (isEdit) {
          return true;
        }
        return currentSPId && currentSPId !== 'unset' && currentSPId !== 'allSP';
    };
    return (
        <>
            <WineInfo formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
            <ProducerInfo formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
            {isUserSuperAdmin &&
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" size='small'>
                    <InputLabel id="sp-select-label" sx={{ ...(errors.serviceProviderId && formClasses.invalidSelect) }}>
                      {`${M.get('labels.serviceProvider')} *`}
                    </InputLabel>
                    <Select
                      id="sp-select"
                      size='small'
                      variant="outlined"
                      name="serviceProviderId"
                      labelId='sp-select-label'
                      label={M.get('labels.serviceProvider')}
                      required
                      disabled={disabled || isSPDisabled()}
                      value={formData.serviceProviderId ?? ''}
                      defaultValue={formData.serviceProviderId}
                      error={!!errors.serviceProviderId}
                      onChange={handleChange}
                      MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                      }}
                    >
                      {
                        formStore.getSpListLoading ?
                          <MenuItem value={formData.serviceProviderId} disabled>
                            {M.get('labels.loading')}
                          </MenuItem>
                          : !isEdit
                            ? formStore.getSpList?.map((opt) => (
                              <MenuItem key={opt.id || opt.value} value={opt.id || opt.value}>
                                {opt.name}
                              </MenuItem>
                            ))
                            : <MenuItem value={formData.serviceProviderId}>
                                {formData.serviceProvider?.name}
                            </MenuItem> 
                      }
                    </Select>
                    {errors.serviceProviderId && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                  </FormControl>
                </Grid>
              }
            <LocationInfo formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label={M.get('labels.alcoholVolume')}
                    id="alcoholByVolume"
                    name="alcoholByVolume"
                    type="text"
                    size="small"
                    variant="outlined"
                    required
                    autoComplete="off"
                    value={formData.alcoholByVolume || ''}
                    disabled={formStore.disabled || disabled}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Allow values with only digits or digits followed by a dot and more digits
                      const floatRegex = /^\d*\.?\d*$/;
                      // Allow typing values like '1.' temporarily, but prevent invalid values as final
                      if (floatRegex.test(value)) {
                          handleChange(e);
                      }
                    }}
                    error={!!errors.alcoholByVolume}
                    helperText={
                        errors.alcoholByVolume ? errors.alcoholByVolume : null
                    }
                    InputProps={{
                      inputProps: { maxLength: maxSymbols },
                      endAdornment: (
                        <InputAdornment position="end">
                          % vol
                          <Tooltip
                              placement="top"
                              title={
                                  <>
                                    <Typography
                                          sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                      >
                                      According to Regulation (EU) 1169/2011, the alcoholic strength shall be determined at 20 °C.
                                    </Typography>
                                  </>
                                }
                          >
                            <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                          </Tooltip>
                        </InputAdornment>
                      ),
                  }}
                />
            </Grid>
        </>
    );
});

export default CardInfo;
