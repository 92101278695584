import React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import dashboardStore from 'store/dashboardStore';

const DataTableSection = observer(() => (
  <Grid container spacing={2} sx={{ mt: 3 }}>
    <Grid size={{ xs: 12, md: 4 }} >
      <Paper elevation={2} sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Visits by Country</Typography>
        <DataGrid
          rows={dashboardStore.viewsByCountry}
          columns={[
            { field: 'country', headerName: 'Country', flex: 1 },
            { field: 'views', headerName: 'Visits', flex: 1, type: 'number' },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSize={5}
          sx={{ minHeight: 6 * 52 + 59 }}
          pageSizeOptions={[5]}
          disableSelectionOnClick
        />
      </Paper>
    </Grid>
    <Grid size={{ xs: 12, md: 4 }}>
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Views by Wine</Typography>
        <DataGrid
          rows={dashboardStore.viewsByWine}
          columns={[
            { field: 'wineName', headerName: 'Wine Name', flex: 1 },
            { field: 'views', headerName: 'Views', flex: 1, type: 'number' },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={{ minHeight: 6 * 52 + 59 }}
          pageSizeOptions={[5]}
          disableSelectionOnClick
        />
      </Paper>
    </Grid>
    <Grid size={{ xs: 12, md: 4 }}>
      <Paper elevation={2} sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Average Rating by Wine</Typography>
        <DataGrid
          rows={dashboardStore.averageRatingByWine}
          columns={[
            { field: 'wineName', headerName: 'Wine Name', flex: 1 },
            { field: 'averageRating', headerName: 'Avg Rating', flex: 1 },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={{ minHeight: 6 * 52 + 59 }}
          pageSize={5}
          pageSizeOptions={[5]}
          disableSelectionOnClick
        />
      </Paper>
    </Grid>
  </Grid>
));

export default DataTableSection;
