import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import PropTypes from 'prop-types';
import SidebarStore from "store/ui/sidebarStore";
import M from 'messages';

import classes from "./styles";

function SideBarFooter({isSidebarOpened}) {
  const downSM = useMediaQuery('(max-width:980px)');
  useEffect(() => {
    SidebarStore.toggleSidebarByValue(!downSM);
  }, [downSM])

  return (
    <footer>
      <Box 
        sx={{...classes.root,
          ...(isSidebarOpened && classes.rootDrawerOpen),
          ...(!isSidebarOpened && classes.rootDrawerClose)
        }}
      >
        {isSidebarOpened && <Box sx={classes.footerTexts}>
          <Box sx={classes.versionText}>
            <Typography sx={{...classes.footerTypographies, ...classes.version}}>
              {`${M.get('footer.version')} 0.1.0`}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{...classes.footerTypographies, ...classes.copyRight}}>
              {M.get('footer.copyright')}
            </Typography>
          </Box>
        </Box>}
      </Box>
    </footer>
  );
}

SideBarFooter.propTypes = {
  isSidebarOpened: PropTypes.bool.isRequired
};

export default SideBarFooter;