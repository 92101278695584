import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress, Avatar, Tooltip, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useSnackbar } from 'notistack';
import { getMessage } from 'helpers/helper';

import FS from 'services/fileService';
import M from 'messages';
import classes from './styles';

function ProfileImageInput({ defaultValue = null, onChange, label, userName, acceptFiles = 'image/*' }) {
  const { enqueueSnackbar } = useSnackbar();

  const [preview, setPreview] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPreview(defaultValue);
  }, [defaultValue]);

  const onFileUpload = async (file) => {
    if(!file) return;
    setLoading(true);
    try {
      onChange(null);
      const response = await FS.upload(file);
      setPreview(URL.createObjectURL(file));
      onChange(response.data.response_data.Location);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const dropzoneOptions = {
    onDrop: (acceptedFiles) => onFileUpload(acceptedFiles[0]),
    accept: acceptFiles,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const onDelete = () => {
    setPreview(null);
    onChange(null, null, null);
    // TODO: remove from server or it will be done using cron job on server side
  };

  return (
    <Box display="flex" flexDirection="column">
      {loading ? (
          <Box sx={classes.loadingProgress}> <CircularProgress size={64}/> </Box>
        ) : (
          <Box sx={classes.main}>
            <Box {...getRootProps()} sx={classes.inputArea}>
              <Box sx={classes.imageRemoveContent}>
                <Avatar src={preview} sx={classes.avatar}>{userName}</Avatar>
              </Box>
              <Button color="primary" sx={classes.avatarEditTxt}> {label} </Button>
              <input {...getInputProps()} />
            </Box>
            {(preview) &&
            <Tooltip title={M.get('actions.removeImage')}>
              <IconButton
                sx={classes.removeBtn}
                aria-label="remove image"
                onClick={onDelete}
                size="large">
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>}
          </Box>
        )}
    </Box>
  );
}

ProfileImageInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  acceptFiles: PropTypes.string,
  userName: PropTypes.string.isRequired
};

export default ProfileImageInput;
