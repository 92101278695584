import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '8px',
    marginBottom: '6px',
  },
  colorize: {
    border: '1px solid',
    display: 'inline-flex',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    textTransform: 'none',
    marginRight: '16px',
    color: 'rgba(61, 61, 61, 0.87)'
  },
  active: {
    background: '#1B5887',
    color: '#FFFFFF',
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
}
