import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  Fab,
  InputLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import cataloguesStore from 'store/catalogueStore';
import PriceUnitsOptions from 'constants/price-units';

import M from 'messages';
import classes from './styles';

const PriceUnits = observer(({ data, disabled }) => {
  const hasAllData = !data.length || (data.length && data.every((item) => item.unit && item.price));
  return (
    <Box sx={classes.root} mt={1}>
      {data.map((item, index) => (
        <Grid container spacing={3} >
          <Grid item xs={3} mb={2}>
            <FormControl fullWidth>
              <InputLabel id="unit-select-label" >{M.get('menuItem.unit')}</InputLabel>
              <Select
                variant="outlined"
                id="unit"
                name="unit"
                value={item?.unit}
                animation="false"
                labelId='unit-select-label'
                label={M.get('menuItem.unit')}
                disabled={disabled}
                onChange={(event) =>
                  cataloguesStore.onChangePriceUnit(event, item, index)
                }
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                }}
              >
                {PriceUnitsOptions.map((unit) => {
                  const isHasOption = data?.find(
                    (storeData) => storeData.unit === unit.value
                  );
                  return (
                    <MenuItem
                      key={unit.label}
                      value={unit.value}
                      disabled={!!isHasOption}
                    >
                      {unit.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} mb={2}>
            <TextField
              variant="outlined"
              id="price"
              name="price"
              label={M.get('menuItem.price')}
              value={item?.price}
              disabled={disabled || !item?.unit}
              onChange={(event) => {
                const { value } = event.target;
                // Regex to match the pattern: Up to 8 digits before the decimal point, and up to 2 digits after.
                // It also allows values without a decimal point.
                const pattern = /^\d{0,8}(\.\d{0,2})?$/;
                if (pattern.test(value) || value === '') {
                  cataloguesStore.onChangePriceUnit(event, item, index);
                }
              }}
              sx={classes.priceField}
            />
          </Grid>
          <Grid item xs={4} mb={2}>
            <TextField
              variant="outlined"
              id="discountedPrice"
              name="discountedPrice"
              label={M.get('menuItem.discountedPrice')}
              value={item?.discountedPrice}
              disabled={disabled || !item?.unit}
              onChange={(event) => {
                const { value } = event.target;
                // Regex to match the pattern: Up to 8 digits before the decimal point, and up to 2 digits after.
                // It also allows values without a decimal point.
                const pattern = /^\d{0,8}(\.\d{0,2})?$/;
                if (pattern.test(value) || value === '') {
                  cataloguesStore.onChangePriceUnit(event, item, index);
                }
              }}
              sx={classes.priceField}
            />
          </Grid>
          <Grid item xs={1} mb={2} sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
            <Fab
              color="primary"
              disabled={disabled}
              onClick={() => cataloguesStore.handleRemovePriceRow(index)}
              sx={classes.deleteIcon}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        </Grid>
      ))}
      <Grid item sx={classes.addIconContainer}>
        <Fab
          color="primary"
          disabled={disabled || !hasAllData || data.length >= PriceUnitsOptions.length || data.length > 2}
          onClick={cataloguesStore.handleAddPriceRow}
          sx={classes.addIcon}
        >
          <AddIcon />
        </Fab>
      </Grid>
    </Box>
  )
});

PriceUnits.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default PriceUnits;
