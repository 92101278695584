import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  FormControl,
  TextField,
  Typography,
  Divider,
  CircularProgress,
  Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getMessage } from 'helpers/helper';
import kiosksMenuStore from 'store/kiosksMenusStore';
import KiosksMenuService from 'services/kiosksMenuService';
import M from 'messages';
import classes from '../styles';

const QRSection = observer(({ menuID, data, dataErrors, isUserSuperAdmin, disabled }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateQR = async () => {
    setLoading(true);
    try {
      const response = await KiosksMenuService.generateQr(menuID);
      kiosksMenuStore.updateData(response.data);
    } catch (error) {
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    kiosksMenuStore.onChangeField(event);
  };

  const renderTextField = (id, name, label, value, error, helperText, editable = true) => (
    <FormControl fullWidth variant="outlined">
      <TextField
        type="text"
        label={label}
        id={id}
        name={name}
        value={value}
        disabled={disabled || !editable}
        required
        onChange={handleChange}
        variant="outlined"
        error={!!error}
        inputProps={{ maxLength: 255 }}
        helperText={helperText}
      />
    </FormControl>
  );

  const getHelperText = (error, fieldValue) =>
    error ? (
      M.get('form.errors.required')
    ) : (
      <Box component="span" sx={classes.qrHelperDiv}>
        <Box component="span">
          {fieldValue?.length || 0}/{kiosksMenuStore.getMaxMenuNameSymbols()}
        </Box>
      </Box>
    );
  return (
    <Grid container spacing={2} sx={classes.menuItemsArea}>
      <Divider sx={classes.divider} />

      {!data.isQrGenerated ? (
        <Grid item xs={12} sm={6} sx={classes.menuItemsMarginTop}>
          <Button
            onClick={handleGenerateQR}
            sx={classes.primaryIconButton}
            variant="contained"
            color="primary"
            disabled={loading || disabled}
          >
            {loading ? <CircularProgress size={24} /> : M.get('kiosksMenu.qr.generate')}
          </Button>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <Box sx={classes.fieldHeader}>
              <Typography sx={{...classes.fieldHeaderText, ...classes.fieldHeaderWithTooltip}}>
                {M.get('kiosksMenu.qr.qrImage')}
                <Tooltip placement="top" title={M.get('kiosksMenu.qr.qrImageTooltip')}>
                  <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                </Tooltip>
              </Typography>
            </Box>
            <img src={data.qrImage} alt="QR Code" style={classes.qrImage} />
          </Grid>
          { isUserSuperAdmin && <Grid item xs={12} sm={12}>
            <Box sx={classes.fieldHeader}>
              <Typography sx={classes.fieldHeaderText}>
                {M.get('kiosksMenu.qr.redirectUrl')}
              </Typography>
            </Box>
            {renderTextField(
              'qrRedirectUrl',
              'qrRedirectUrl',
              M.get('kiosksMenu.qr.redirectUrl'),
              data.qrRedirectUrl,
              dataErrors.qrRedirectUrl,
              getHelperText(dataErrors.qrRedirectUrl, data.qrRedirectUrl)
            )}
          </Grid>
          }
        </>
      )}
    </Grid>
  );
});

export default QRSection;