import { makeAutoObservable, action, computed, extendObservable, set } from "mobx";

class SideBar {

  getDefaultStoreProps = () => ({
    isSideBarOpen: true,
    activeLink: '',
  })

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  clickedSideBarLink(link) {
    this.activeLink = link;
  }

  @computed
  get activeSideBarLink() {
    return this.activeLink;
  }

  @action
  toggleSidebarByValue(value) {
    this.isSideBarOpen = value;
  }

  @computed
  get sidebarState() {
    return this.isSideBarOpen;
  }
}

export default new SideBar();