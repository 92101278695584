import qs from 'qs';
import M from 'messages';

const cleanObject = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      cleanObject(v);
    }
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v === ''
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        // eslint-disable-next-line no-param-reassign
        delete object[k];
      }
    }
  });
  return object;
};

const replacePath = (navigate, filter) => {
  const newFilter = cleanObject(filter);
  const search = qs.stringify({ ...newFilter.params }, { skipNulls: true });
  navigate({ search }, { replace: true });
};

const pad = (num, size)  => {
    let numString = num.toString();
    while (numString.length < size) numString = `0${numString}`;
    return numString;
}

const getMessage = (error, variant = 'success')  => {
  const message = error && error.message ? error.message : error && error.error ? error.error : variant === 'error' ? M.get('actionMsg.error.unknownError') : M.get('actionMsg.success.operationSucceeded')
  return message;
}

const deleteAllSpacesFromStr = (str) => str.replace(/\s/g, '');

const stringTrimValidation = (value) => value.trim().replace(/\s\s+/g, ' ') === value;

const isPositiveNumber = (number) => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const positiveNumberRegexp = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
  return positiveNumberRegexp.test(number);
}

function isNumeric(str) {
  // eslint-disable-next-line security/detect-unsafe-regex
  const pattern = /^-?(0|[1-9]\d*|(?=\.))(\.\d+)?$/;
  if (str.length > 1 && str[str.length - 1] === '.') {
    return true;
  }
  return pattern.test(str);
}

const isNumberOrMinus = (symbol) => {
  if (isNumeric(symbol)) {
    return true;
  }
  if (symbol === "-") {
    return true;
  }
  return false;
}

export {
  replacePath,
  pad,
  getMessage,
  deleteAllSpacesFromStr,
  stringTrimValidation,
  isPositiveNumber,
  isNumeric,
  isNumberOrMinus,
};