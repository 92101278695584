import { makeAutoObservable, action, extendObservable, computed } from "mobx";
import {deleteAllSpacesFromStr} from 'helpers/helper';
import { isEmail } from "helpers/validatorHelper";

const defaultDataForgot = {
    email: '',
};

const defaultDataReset = {
    newPassword: '',
    repeatNewPassword: ''
};

const defaultDataUIState = {
    showNewPassword: false,
    showRepeatPassword: false,
};

const requiredFieldsForgot = ['email'];

const requiredFieldsReset = ['newPassword', 'repeatNewPassword'];

class ForgotPassword {

    getDefaultStoreProps = () => ({
        forgotPasswordData: JSON.parse(JSON.stringify(defaultDataForgot)),
        forgotPasswordDataErrors: {},
        resetPasswordData: JSON.parse(JSON.stringify(defaultDataReset)),
        resetPasswordDataErrors: {},
        resetPasswordUIState: JSON.parse(JSON.stringify(defaultDataUIState)),
        resetPasswordsChanged: {
            newPassword: false,
            repeatNewPassword: false
        }
    });
  
    constructor() {
        makeAutoObservable(this);
        extendObservable(this, { ...this.getDefaultStoreProps()});
    }

    @computed
    getForgotPasswordData = () => this.forgotPasswordData;

    @computed
    getResetPasswordData = () => this.resetPasswordData;


    @action
    onChangeFieldFPW = (e) => {
        const { name, value } = e.target;
        const newValue = deleteAllSpacesFromStr(value);
        this.forgotPasswordData[name] = newValue;
        if (!newValue && requiredFieldsForgot.includes(name)) {
            this.forgotPasswordDataErrors[name] = 'required';
        } else if (newValue && !isEmail(newValue)) {
            this.forgotPasswordDataErrors[name] = 'invalidEmail';
        } else {
            delete this.forgotPasswordDataErrors[name];
        }
        return true;
    }

    @action
    onChangeFieldRPW = (e) => {
        const { name, value } = e.target;
        this.resetPasswordsChanged[name] = true;
        const newValue = deleteAllSpacesFromStr(value);
        this.resetPasswordData[name] = newValue;
        if (!newValue && requiredFieldsReset.includes(name)) {
            this.resetPasswordDataErrors[e.target.name] = 'required';
        } else {
            delete this.resetPasswordDataErrors[name];
        }
        return true;
    }

    @computed
    getResetPSWChanged = () => this.resetPasswordsChanged.newPassword;

    @computed
    getResetNewPSWChanged = () => this.resetPasswordsChanged.repeatNewPassword;

    @computed
    validateResetPasswords = () => this.resetPasswordData.newPassword === this.resetPasswordData.repeatNewPassword;

    @computed
    getForgotPasswordDataErrors = () => this.forgotPasswordDataErrors;

    @computed
    getResetPasswordDataErrors = () => this.resetPasswordDataErrors;

    @action
    clearDataErrors = () => {
        this.forgotPasswordDataErrors = {};
        this.resetPasswordDataErrors = {};
    }

    @action
    clearData = () => {
        this.forgotPasswordData = JSON.parse(JSON.stringify(defaultDataForgot));
        this.resetPasswordData = JSON.parse(JSON.stringify(defaultDataReset));
    }

    @computed
    forgotPasswordDataHasError = (shownReqFields) => {
        let hasError = false;
        shownReqFields.forEach(i => {
            if (!this.forgotPasswordData[i]) {
                this.forgotPasswordDataErrors[i] = 'Required';
                hasError = true;
            } else {
                this.forgotPasswordDataErrors[i] = '';
            }
        });
        return hasError;
    };

    @computed
    resetPasswordDataHasError = (shownReqFields) => {
        let hasError = false;
        shownReqFields.forEach(i => {
            if (!this.resetPasswordData[i]) {
                this.resetPasswordDataErrors[i] = 'Required';
                hasError = true;
            } else {
                this.resetPasswordDataErrors[i] = '';
            }
        });
        return hasError;
    };

    @action
    setEyeIconStateByField = (eyeId) => {
        this.resetPasswordUIState[eyeId] = !this.resetPasswordUIState[eyeId];
    }

    @computed
    getShowNewPasswordState = () => this.resetPasswordUIState.showNewPassword;

    @computed
    getShowRepeatPasswordState = () => this.resetPasswordUIState.showRepeatPassword;

    @computed
    isSubmitBtnActive = () => {
        if (!this.resetPasswordData.newPassword || !this.resetPasswordData.repeatNewPassword) {
            return false;
        }
        return this.resetPasswordData.newPassword === this.resetPasswordData.repeatNewPassword;
    }
}

export default new ForgotPassword();