import M from 'messages';

export default [
  {
    value: 'glass',
    label: M.get('menuItem.glass'),
  },
  {
    value: 'first_glass',
    label: M.get('menuItem.firstGlass'),
  },
  {
    value: 'two_five_glass',
    label: M.get('menuItem.twoFiveGlass'),
  },
  {
    value: 'flight',
    label: M.get('menuItem.flight'),
  },
  {
    value: 'half_bottle',
    label: M.get('menuItem.halfBottle'),
  },
  {
    value: 'carafe',
    label: M.get('menuItem.carafe'),
  },
  {
    value: 'bottle',
    label: M.get('menuItem.bottle'),
  },
  {
    value: 'magnum_1.5l',
    label: M.get('menuItem.magnum1_5l'),
  },
  {
    value: 'magnum_3l',
    label: M.get('menuItem.magnum3l'),
  },
  {
    value: 'half_case',
    label: M.get('menuItem.halfCase'),
  },
  {
    value: 'case',
    label: M.get('menuItem.case'),
  },
  {
    value: 'three_cases',
    label: M.get('menuItem.threeCases'),
  },
  {
    value: 'five_cases',
    label: M.get('menuItem.fiveCases'),
  },
  {
    value: 'ten_cases',
    label: M.get('menuItem.tenCases'),
  },
  {
    value: 'twenty_plus_cases',
    label: M.get('menuItem.twentyPlusCases'),
  },
];