import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useSnackbar } from 'notistack';
import { getMessage } from 'helpers/helper';

import FS from 'services/fileService';
import M from 'messages';
import classes from './styles';

function ImageInput({
  defaultValue = null,
  onChange,
  label,
  disabled = false,
  setDisableSave = null,
  acceptFiles = {
    'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
  },
  sizes = null,
  previewClassName = 'preview',
  smallPreview = false,
  type = '',
  isNewVersion = false,
  errorStyle = false,
  uploadText = M.get('form.dropFileOrUpload'),
  inputAreaClass = 'inputArea',
  isSignUp = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const initialPreview = isSignUp && defaultValue && defaultValue instanceof File ? URL.createObjectURL(defaultValue) : '';
  const [preview, setPreview] = useState(initialPreview);
  const [loading, setLoading] = useState(false);

  const defaultFileUpload = async (file) => {
    if(!file) return;
    if (setDisableSave) {
      setDisableSave(true);
    }
    setLoading(true);
    try {
      onChange(null);
      const response = await FS.upload(file, type);
      setPreview(URL.createObjectURL(file));
      if (Array.isArray(response.data.response_data)) {
        const originalImage = response.data.response_data.find(i => i.originalImage);
        const mediumImage = response.data.response_data.find(i => i.mediumImage);
        const smallImage = response.data.response_data.find(i => i.smallImage);
        onChange(originalImage.Location, mediumImage.Location || '', smallImage.Location || '');
      } else {
        onChange(response.data.response_data.Location);
      }
      setLoading(false);
      if (setDisableSave) {
        setDisableSave(false);
      }
    } catch (error) {
      setLoading(false);
      if (setDisableSave) {
        setDisableSave(false);
      }
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
    }
  }


  const signUpFileUpload = async (file) => {
    if (!file) return;
    if (setDisableSave) {
      setDisableSave(true);
    }
    setLoading(true);
    try {
      onChange(null);
      setPreview(URL.createObjectURL(file));
      onChange(file)
      setLoading(false);
      if (setDisableSave) {
        setDisableSave(false);
      }
    } catch (error) {
      setLoading(false);
      if (setDisableSave) {
        setDisableSave(false);
      }
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const onFileUpload = isSignUp ? signUpFileUpload : defaultFileUpload;

  const dropzoneOptions = {
    onDrop: (acceptedFiles) => {
      if (sizes) {
        const file = acceptedFiles[0];
        const img = new Image();

        img.onload = () => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (sizes.compareType === 'max' && img.width <= sizes.maxWidth && img.height <= sizes.maxHeight) {
              return onFileUpload(acceptedFiles[0]);
            }
            if (sizes.compareType === 'min' && img.width >= sizes.minWidth && img.height >= sizes.minHeight) {
              return onFileUpload(acceptedFiles[0]);
            }
            if (sizes.compareType === 'minMax' && img.width >= sizes.minWidth && img.width <= sizes.maxWidth && img.height >= sizes.minHeight && img.height <= sizes.maxHeight) {
              return onFileUpload(acceptedFiles[0]);
            }
            let message = '';
            if (sizes.compareType === 'max') {
              message = M.get('actionMsg.error.imageMaxSize').replace('{width}', sizes.maxWidth).replace('{height}', sizes.maxHeight);
            } else if (sizes.compareType === 'min') {
              message = M.get('actionMsg.error.imageMinSize').replace('{width}', sizes.minWidth).replace('{height}', sizes.minHeight);
            } else {
              message = M.get('actionMsg.error.imageMinMaxSize').replace('{minWidth}', sizes.minWidth).replace('{minHeight}', sizes.minHeight).replace('{maxWidth}', sizes.maxWidth).replace('{maxHeight}', sizes.maxHeight);
            }
            enqueueSnackbar(message, { variant: 'error' });
            return false;
          }
        }
        img.src = URL.createObjectURL(file);
        return true;
      }
      return onFileUpload(acceptedFiles[0]);
    },
    accept: acceptFiles,
  };
  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);
  const onDelete = () => {
    setPreview(null);
    onChange(null, null, null);
    // TODO: remove from server or it will be done using cron job on server side
  };

  const getInputField = () => {
    if (!disabled) {
      return (
        <Box {...getRootProps()} sx={{...classes[inputAreaClass], ...(errorStyle && classes.errorColor)}}>
          <Typography color="textPrimary" sx={ smallPreview && { paddingLeft: "8px", paddingRight: "8px" } }>
            {uploadText}
          </Typography>
          <input {...getInputProps()} />
        </Box>
      )
    }

    return (<></>)
  }
  return (
    <Box display="flex" flexDirection="column">
      {label && <Typography variant="caption" sx={classes.label}>
        {/* {label} (jpg, png) */}
        {label}
      </Typography>}
      {getInputField()}

      {loading && <Box sx={isNewVersion ? classes.newLoadingProgress : classes.loadingProgress}> <CircularProgress size={50}/> </Box>}

      {(!loading && (preview || defaultValue)) && (
        <Box m={1} sx={classes.previewBox}>
          {!disabled && (
            <IconButton
              sx={classes.removeBtn}
              aria-label="remove image"
              onClick={onDelete}
              size="large">
              <RemoveCircleIcon />
            </IconButton>
          )}
          <Box component='img' sx={smallPreview ? { width: "80px"} : classes[previewClassName]} src={preview || defaultValue} alt="preview" />
        </Box>
      )}
    </Box>
  );
}

ImageInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  setDisableSave: PropTypes.func,
  acceptFiles: PropTypes.string,
  sizes: PropTypes.shape({
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    compareType: PropTypes.string.isRequired
  }),
  previewClassName: PropTypes.string,
  type: PropTypes.string,
  isNewVersion: PropTypes.bool,
  errorStyle: PropTypes.bool,
  uploadText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  smallPreview: PropTypes.bool,
  inputAreaClass: PropTypes.string,
  isSignUp: PropTypes.bool,
};

export default ImageInput;
