import { makeAutoObservable, action, extendObservable, computed } from "mobx";
import {deleteAllSpacesFromStr} from 'helpers/helper';
import { isEmail } from "helpers/validatorHelper";

const defaultData = {
    email: '',
    password: ''
};

const defaultDataUIState = {
    showPassword: false,
};

const requiredFields = ['email', 'password'];


class LoginForm {

    getDefaultStoreProps = () => ({
        loginFormData: JSON.parse(JSON.stringify(defaultData)),
        loginFormDataErrors: {},
        loginFormUIState: JSON.parse(JSON.stringify(defaultDataUIState)),
        fieldChanged: false
    })
    
    constructor() {
        makeAutoObservable(this);
        extendObservable(this, { ...this.getDefaultStoreProps()});
    }

    @computed
    getLoginFormData = () => this.loginFormData;

    @action
    onChangeLoginFormField = (e) => {
        this.fieldChanged = true;
        const { name, value } = e.target;
        const newValue = deleteAllSpacesFromStr(value);
        this.loginFormData[name] = newValue;
        if (!newValue && requiredFields.includes(name)) {
            this.loginFormDataErrors[name] = 'required';
        } else {
            delete this.loginFormDataErrors[name];
        }
        return true;
    }

    @action
    onChangeLoginFormEmailField = (e) => {
        const { name, value } = e.target;
        const newValue = deleteAllSpacesFromStr(value);
        this.loginFormData[name] = newValue;
        if (!newValue && requiredFields.includes(name)) {
            this.loginFormDataErrors[name] = 'required';
        } else if (newValue && !isEmail(newValue)) {
            this.loginFormDataErrors[name] = 'invalidEmail';
        } else {
            delete this.loginFormDataErrors[name];
        }
        return true;
    }

    @computed
    getLoginFormDataErrors = () => this.loginFormDataErrors;

    @action
    clearData = () => {
        this.loginFormData = JSON.parse(JSON.stringify(defaultData));
        this.loginFormDataErrors = {};
        this.loginFormUIState = JSON.parse(JSON.stringify(defaultDataUIState));
        this.fieldChanged = false;
    }

    @computed
    loginFormDataHasError = (shownReqFields) => {
        let hasError = false;
        shownReqFields.forEach(i => {
            if (!this.loginFormData[i]) {
                this.loginFormDataErrors[i] = 'Required';
                hasError = true;
            } else {
                this.loginFormDataErrors[i] = '';
            }
        });
        return hasError;
    };

    @action
    setEyeIconStateByField = (eyeId) => {
        this.loginFormUIState[eyeId] = !this.loginFormUIState[eyeId];
    }

    @computed
    getShowPasswordState = () => this.loginFormUIState.showPassword;

    clearPassword = () => {
        this.loginFormData.password = defaultData.password;
    }
}

export default new LoginForm();