import React from 'react';
import { Box, FormControl, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import M from 'messages';
import useDebounce from "components/common/customDebounceHook/useDebounce";
import classes from './styles';

const CustomSearch = ({onSearchCallback, searchValue = '', disableFieldsOnSearch = null}) => {
    const [searchTerm, setSearchTerm] = React.useState(searchValue);
    const debouncedValue = useDebounce(searchTerm, 1000);

    const handleDisableFieldsOnSearch = () => {
        if (disableFieldsOnSearch && typeof disableFieldsOnSearch === 'function') {
            disableFieldsOnSearch(true);
        }
    }

    React.useEffect(() => {
        setSearchTerm(searchValue);
    }, [searchValue]);

    React.useEffect(() => {
        onSearchCallback(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
        <Box sx={classes.box}>
            <FormControl margin='dense' fullWidth variant="outlined">
                <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder={M.get('filterFields.search')}
                    sx={classes.searchInput}
                    value={searchTerm}
                    label={M.get('filterFields.search')}
                    onChange={event => {
                        setSearchTerm(event.target.value);
                        handleDisableFieldsOnSearch();
                    }}
                    InputLabelProps={{style: {fontSize: 14, fontWeight: 500, opacity: 0.8}}}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                              <SearchIcon />
                          </InputAdornment>),
                        classes: {
                            adornedEnd: classes.adornedEnd
                        }
                      }}
                    id="search"
                />
        </FormControl>
    </Box>
    );
};

CustomSearch.propTypes = {
    onSearchCallback: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    disableFieldsOnSearch: PropTypes.func,
}

export default CustomSearch;