import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  table: {
    minWidth: '850px',
    tableLayout: 'fixed',
  },
  tableBody: {
    display: 'flex',
    justifyContent: 'center'
  },
  skeletonContainer: {
    display: 'flex'
  },
  skeletonItem: {
    minWidth: '250px',
    height: '40px',
    margin: '10px'
  },
  tableCellAction: {
    padding: '6px 16px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemText: {
    color: theme.palette.primary.main
  },
  imgItem : {
    width: '24px',
    height: '28px',
    marginTop: '6px'
  },
  tableRow: {
    height: '52px',
    '& > td': {
      fontWeight: '400'
    }
  },
  tableRowCursor: {
    cursor: 'pointer',
  },
  rowError: {
    backgroundColor: theme.palette.primary.tableRowError,
    '&:hover': {
      backgroundColor: theme.palette.primary.tableRowError,
    },
  },
  rowDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },  
  paperWithShadow: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    '& > div:first-of-type': {
      overflowY: 'hidden'
    }
  },
  paginationRoot: {
    minHeight: '61px',
    paddingRight: '12px'
  },
  tablePagination: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};