import M from 'messages';

export default [
  {
    value: 1,
    title: M.get('users.approved')
  },
  {
    value: 0,
    title: M.get('users.notApproved')
  }
]