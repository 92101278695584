import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import M from 'messages';
import classes from './style';

const PasswordValidationSignUp = ({ passwordErrors, passwordLength, hasError = false }) => (
  <>
    <Box sx={classes.requirementGroup}>
      <CheckCircleOutlineIcon
        sx={
          passwordErrors?.minCharacter || !hasError
            ? classes.requirement
            : classes.validRequirement
        }
      />
      <Typography
        sx={
          passwordErrors?.minCharacter
            ? classes.requirementTxtError
            : classes.requirementTxt
        }>
        {M.get('passwordValidation.minCharacter').replace('{passwordLength}', passwordLength)}
      </Typography>
    </Box>

    <Box sx={classes.requirementGroup}>
      <CheckCircleOutlineIcon
        sx={
          passwordErrors?.upperAndLowerCaseCharacters || !hasError
            ? classes.requirement
            : classes.validRequirement
        }
      />
      <Typography
        sx={
          passwordErrors?.upperAndLowerCaseCharacters
            ? classes.requirementTxtError
            : classes.requirementTxt
        }>
        {M.get('passwordValidation.upperAndLowerCaseCharacters')}
      </Typography>
    </Box>

    <Box sx={classes.requirementGroup}>
      <CheckCircleOutlineIcon
        sx={
          passwordErrors?.specialCharacter || !hasError
            ? classes.requirement
            : classes.validRequirement
        }
      />
      <Typography
        sx={
          passwordErrors?.specialCharacter
            ? classes.requirementTxtError
            : classes.requirementTxt
        }>
        {M.get('passwordValidation.specialCharacter')}
      </Typography>
    </Box>
  </>
);

PasswordValidationSignUp.propTypes = {
  passwordErrors: PropTypes.PropTypes.instanceOf(Object).isRequired,
  passwordLength: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
};

export default PasswordValidationSignUp;