import M from 'messages';

const UsersRoles = {
    superAdmin: {
        name: 'superAdmin',
        canCreate: ['partner'],
        canEdit: ['partner'],
        canDelete: ['partner'],
    },
    partner: {
        name: 'partner',
        canCreate: [],
        canEdit: [],
        canDelete: []
    },
};

const isSuperAdmin = (user) => user?.usersRole?.name === UsersRoles.superAdmin.name;

const isPartner = (user) => user?.usersRole?.name === UsersRoles.partner.name;

const isSuperAdminOrPartner = (user) => user?.usersRole?.name === UsersRoles.superAdmin.name || user?.usersRole?.name === UsersRoles.partner.name;

const adaptUsersRolesData = (usersRoles, currentUser, isEditMode = false) => {
    if (!usersRoles.length || !currentUser) {
        return [];
    }
    let canCreateList;

    if (isEditMode) {
        canCreateList = UsersRoles[currentUser?.usersRole?.name]?.canEdit;
    } else {
        canCreateList = UsersRoles[currentUser?.usersRole?.name]?.canCreate;
    }
    const result = (usersRoles?.filter(userRole => canCreateList.includes(userRole.name)))?.map(i => ({...i, value: i.id, label: M.get(`usersRoles.${i.name}`)}));
    const sorted = result.sort((a, b) => a.label > b.label ? 1 : -1);
    return sorted;
}

export {
    UsersRoles,
    isSuperAdmin,
    isPartner,
    adaptUsersRolesData,
    isSuperAdminOrPartner
};