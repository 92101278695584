import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    minHeight: `calc(100vh - ${variables.headerHeight} - 60px)`,
    backgroundColor: theme.palette.primary.mainBackground,
    fontFamily: 'Montserrat, sans-serif',
    paddingTop: '21%',
    textAlign: 'center'
  },
  title: {
    margin: '20px 0',
    fontSize: '32px',
    fontWeight: 'bold',
  },
  description: {
    margin: '0 0 30px 0',
    fontSize: '16px',
    fontWeight: '400',
  },
  transferTable: {
    paddingTop: '15%',
    textAlign: 'center',
    minHeight: '300px',
  },
  createOrderTable: {
    paddingTop: '15%',
    textAlign: 'center',
    minHeight: '300px',
  },
  createOrderModal: {
    textAlign: 'center',
    maxHeight: '550px',
    minHeight: '260px',
    paddingTop: '120px'
  },
  activityLoading: {
    textAlign: 'center',
    background: 'none',
    padding: 0,
    minHeight: 'unset'
  },
  authLoading: {
    backgroundColor: 'white'
  },
  componetLoading: {
    textAlign: 'center',
    background: 'none',
    padding: '30px',
    minHeight: 'unset'
  },
  hostCustomerLoading: {
    minHeight: `calc(38vh - ${variables.headerHeight})`,
    backgroundColor: 'white',
  }
};
