import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import M from 'messages';

import classes from './style';

const PasswordValidation = ({ requirements, passwordLength }) => (
    <>
      <Box sx={classes.requirementGroup}>
        <CheckCircleOutlineIcon
          sx={
            requirements.length < passwordLength
              ? classes.requirement
              : classes.validRequirement
          }
        />
        <Typography sx={classes.requirementTxt}> {M.get('passwordValidation.minCharacter').replace('{passwordLength}', passwordLength)} </Typography>
      </Box>
      <Box sx={classes.requirementGroup}>
        <CheckCircleOutlineIcon
          sx={
            requirements.contains.includes('lowercase') &&
            requirements.contains.includes('uppercase')
              ? classes.validRequirement
              : classes.requirement
          }
        />
        <Typography sx={classes.requirementTxt}> {M.get('passwordValidation.upperAndLowerCaseCharacters')} </Typography>
      </Box>
      <Box sx={classes.requirementGroup}>
        <CheckCircleOutlineIcon
          sx={
            requirements.contains.includes('number') &&
            requirements.contains.includes('symbol')
              ? classes.validRequirement
              : classes.requirement
          }
        />
        <Typography sx={classes.requirementTxt}> {M.get('passwordValidation.specialCharacter')} </Typography>
      </Box>
    </>
);

PasswordValidation.propTypes = {
  passwordLength: PropTypes.number.isRequired,
  requirements: PropTypes.shape({
    contains: PropTypes.instanceOf(Array),
    length: PropTypes.number.isRequired,
  }).isRequired,
};

export default PasswordValidation;