export default {
  customTextField: {
    "& input::placeholder": {
      fontSize: "10px"
    }
  },
  box: {
    width: '245px',
    maxWidth: '400px',
    marginRight: '16px'
  }
}