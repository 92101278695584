import UserName from "../commonCellComponent/userName/UserName";

const tableOptions = {
  user: {
    fields: [
      { id: 'name', label: 'table.user.user', component: UserName, type: 'customComponent', textAlign: 'left', headAlign: 'left', sortable: true, width: '35%', cellPaddingLeft: '24px' },
      { id: 'serviceProviderId', label: 'table.user.serviceProvider', type: 'text', sortable: true, width: '15%' },
      { id: 'usersRole', orderField: 'usersRolesId', label: 'table.user.role', type: 'text', sortable: true, width: '20%' },
      { id: 'lastLoginDate', label: 'table.user.lastActivity', type: 'text', sortable: true, width: '30%', textAlign: 'center', headAlign: 'center', },
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [],
    searchFields: ['name', 'email']
  }
};

export default tableOptions;