import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
    FormControl,
    Fab,
    InputAdornment,
    Autocomplete,
    Box,
    Divider,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const GrapeVarietyComponent = observer(({ formStore, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;

    let hasAllData = true;
    let totalPercentage = 0;

    if (formData.grapeVarieties.length > 0) {
        hasAllData = formData.grapeVarieties.every((item) => item.typeId);
        totalPercentage = formData.grapeVarieties.reduce((acc, item) => acc + parseFloat(item.percentage || 0), 0);
    }

    const handleAddRow = () => {
        if (hasAllData && totalPercentage <= 100) {
            formStore.handleAddGrapeVarietyRow();
        }
    };

    // Filter out grape varieties that have already been selected
    const availableGrapeVarieties = formStore.wineTypesOptions.filter(
        option => !formData.grapeVarieties.some(item => item.typeId === option.id)
    );
    return (
        <>
            <Grid item xs={12}>
                <Box>
                    <Typography sx={classes.fieldHeaderText} component='h1'>
                        {M.get('labels.wine.type')}
                    </Typography>
                </Box>
            </Grid>
            {formData.grapeVarieties.map((item, index) => (
                <Grid container item spacing={2} key={item.typeId}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="standard" key={errors[`grapeVarieties[${index}].typeId`]}>
                            <Autocomplete
                                id="typeId"
                                size="small"
                                variant="outlined"
                                clearOnBlur
                                selectOnFocus
                                forcePopupIcon
                                handleHomeEndKeys
                                disabled={formStore.disabled || disabled}
                                value={formStore.getSelectedWineType(item) || null}
                                defaultValue={formStore.getSelectedWineType(item) || null}
                                onChange={(e, value) =>
                                    formStore.onChangeGrapeVarietyAutocomplete(value, 'typeId', 'menuItemTypes', item, index)
                                }
                                options={[
                                    ...(formStore.getSelectedWineType(item) ? [formStore.getSelectedWineType(item)] : []),  // Include only if it's not empty
                                    ...availableGrapeVarieties
                                ]}
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                getOptionDisabled={(option) => option.isDisabled}
                                renderOption={(props, option) => {
                                    const { key, ...restProps } = props; // Destructure and remove `key`
                                    return <li key={key} {...restProps}>{option.name}</li>; // Spread the remaining props
                                }}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        required
                                        label={M.get('labels.wine.type')}
                                        variant="outlined"
                                        error={!!errors[`grapeVarieties[${index}].typeId`]}
                                        helperText={!!errors.typeId && M.get('form.errors.required')}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} container alignItems="center">
                        <Grid item xs>
                            <TextField
                                fullWidth
                                size="small"
                                label={M.get('labels.percentage')}
                                id="percentage"
                                name="percentage"
                                type="text"
                                variant="outlined"
                                autoComplete="off"
                                value={item.percentage}
                                disabled={formStore.disabled || disabled}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if(floatRegex.test(value) || value === '' || value === null) {
                                        formStore.onChangePercentage(event, item, index);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                error={!!errors[`grapeVarieties[${index}].percentage`]}
                                helperText={!!errors[`grapeVarieties[${index}].percentage`] && errors[`grapeVarieties[${index}].percentage`]}
                            />
                        </Grid>
                        <Grid item>
                            <Fab
                                color="error"
                                size="small"
                                onClick={() => formStore.handleRemoveGrapeVarietyRow(index)}
                                disabled={formStore.disabled || disabled}
                                sx={{ ml: 2 }}
                            >
                                <DeleteIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            {errors.grapeVarieties ? (
                <>
                    <Divider sx={classes.error} />
                    <Box component="span" sx={classes.error}>
                        {M.get('form.errors.required')}
                    </Box>
                </>
            ) : (
                <Grid item sx={classes.addIconContainer}>
                    <Fab
                        size="small"
                        color="primary"
                        disabled={formStore.disabled || !hasAllData || totalPercentage >= 100 || disabled}
                        onClick={handleAddRow}
                        sx={classes.addIcon}
                    >
                        <AddIcon />
                    </Fab>
                </Grid>
            )}
        </>
    );
});

GrapeVarietyComponent.propTypes = {
    formStore: PropTypes.instanceOf(Object).isRequired,
    handleChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    M: PropTypes.object.isRequired,
};

export default GrapeVarietyComponent;