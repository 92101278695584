import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Grid, Button, DialogContentText, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import qs from 'qs';

import M from 'messages';
import {ReactComponent as EmptyKioskMenu} from 'assets/emptyKioskMenu.svg';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CustomTable from 'components/common/customTableV2';
import Loading from 'components/common/loading';
import EmptyData from 'components/common/emptyData';
import PageHeader from 'components/common/pageHeader';
import KiosksMenusServices from 'services/kiosksMenuService';
import CataloguesServices from 'services/cataloguesService';
import kiosksMenusStore from 'store/kiosksMenusStore';
import SidebarStore from 'store/ui/sidebarStore';
import domainData from 'store/domainStore';
import { adaptKiosksMenusTableData } from 'helpers/adapter';
import { replacePath, getMessage } from 'helpers/helper';
import { routes } from 'config';
import { useModal } from 'hooks/common';
import Modal from 'components/common/modal';
import CustomTableToolbar from './toolbar';

import tableOptions from './config/config';

import classes from './styles';

const KiosksMenu = observer(({path}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [loading, setLoading] = useState(true);
  const [catalogLoading, setCatalogLoading] = useState(true);
  const [fromFilter, setFromFilter] = useState(true);
  const [kiosksMenusData, setKiosksMenusData] = useState({});
  const [isCatalogEmpty, setIsCatalogEmpty] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [moveLoading, setMoveLoading] = useState(false);
  const isUserSuperAdmin = domainData.userIsSuperAdmin();

  const [isOpenMove, openModalMove, closeModalMove] = useModal(false);

  const isSidebarOpened = SidebarStore.sidebarState;

  const filteredParamsStore = kiosksMenusStore.filter;
  const { searchFields, filterFields } = tableOptions;

  // NOTE: cut / symbol
  const pageItem = path.substring(1);

  const setFilter = (filter) => {
    replacePath(navigate, filter);
    kiosksMenusStore.setFilter(filter);
  };

  const getCurrentServiceProvider = () => {
    const currentSP = domainData.getCurrentSP();
    const currSP = currentSP && currentSP !== 'allSP' ? {currentServiceProvider: currentSP} : {};
    return currSP;
  }

  const getKiosksMenusLists = useCallback(
    async (params, withFilter = true) => {
      try {
        // setLoading(true);
        setFromFilter(withFilter);
        const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        if ((urlParams?.filter && Object.keys(urlParams.filter).length) || urlParams?.search) {
          setFromFilter(true);
        }
        replacePath(navigate, params);
        kiosksMenusStore.setFilter(params);
        const newParams = { params: {...params.params, ...getCurrentServiceProvider() }};
        const response = await KiosksMenusServices.getKiosksMenus(newParams);
        if (response) {
          kiosksMenusStore.setStoreKiosksMenus(response.data);
          setKiosksMenusData({
            count: response.data.count,
            data: adaptKiosksMenusTableData(response.data.data),
            createAbility: response.data.createAbility
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const queryParams = {
    params: {
      sort: pageParams?.sort || filteredParamsStore.params.sort,
      filter: pageParams?.filter || filteredParamsStore.params.filter,
      search: pageParams?.search  || filteredParamsStore.params.search,
      limit: pageParams?.limit || filteredParamsStore.params.limit,
      skip: pageParams?.skip || filteredParamsStore.params.skip,
    }
  }

  const getCataloguesLists = useCallback(
    async (filterParams) => {
      try {
        const response = await CataloguesServices.getCatalogCount({ params: getCurrentServiceProvider()});
        if (response) {
          if (response.data > 0) {
            const hasSearchData = !!(filterParams.params.search || (filteredParamsStore.params.search && Object.keys(filteredParamsStore.params.search).length > 0));
            getKiosksMenusLists(filterParams, hasSearchData);
            setIsCatalogEmpty(false);
          } else {
            setIsCatalogEmpty(true);
            setLoading(false);
          }
          setCatalogLoading(false);
        }
      } catch (err) {
        setCatalogLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    kiosksMenusStore.setPageName(pageItem);
    getCataloguesLists(queryParams);
    setSelectedRows([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCataloguesLists]);

  const handleCreateMenus = () => {
    navigate(`${location.pathname}/create`);
  };

  const onSearchCallback = (value) => {
    let oldValue = queryParams.params?.search?.value;
    if (oldValue === undefined) {
      oldValue = '';
    }
    if (searchFields && (oldValue !== value)) {
      const newFilter = {params: {...queryParams.params, search: {...queryParams.params.search, value, fields: searchFields}, skip: 0}}
      if (!value) {
        delete newFilter.params.search;
      }
      setFilter(newFilter);
      getCataloguesLists(newFilter);
    }
  };

  const handleClickDeleteIcon = () => {
    setMoveLoading(false);
    openModalMove()
  }

  const handleDeleteMany = async () => {
    try {
      setMoveLoading(true);
      const response = await KiosksMenusServices.deleteMenuMany({ids: selectedRows});
      if (response) {
        enqueueSnackbar(getMessage(response.data), { variant: 'success' });
      }
      await getKiosksMenusLists(queryParams, false);
      setMoveLoading(false);
      setSelectedRows([]);
    } catch (err) {
      setMoveLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    closeModalMove()
  };

  const onFilterCallback = (filterObj) => {
    if (filterObj && !Array.isArray(filterObj)) {
      const fieldKeyList = Object.keys(filterObj);
      let newFilter = {};
      fieldKeyList.forEach(i => {
        const fieldValue = filterObj[i];
        let oldValue = queryParams.params?.filter?.[i];
        if (oldValue === undefined) {
          oldValue = '';
        }
        if (filterFields && (oldValue !== fieldValue) && fieldValue !== undefined) {
          newFilter = {...newFilter, params: {...queryParams.params, ...newFilter.params, filter: {...queryParams.params.filter, ...newFilter?.params?.filter, [i]: fieldValue}, skip: 0}}
        }
      });
      if (Object.keys(newFilter).length) {
        setFilter(newFilter);
        getCataloguesLists(newFilter);
      }
    }
  };

  const createButton = (disabled) => (
    <Button
      onClick={handleCreateMenus}
      startIcon={<AddIcon sx={classes.primaryIcon}/>}
      sx={classes.primaryIconButton}
      variant="contained"
      disabled={disabled}
      color="primary">
        {M.get('actions.create')} 
    </Button>
  )

  const toolbarView = (
    <CustomTableToolbar
      onSearchCallback={onSearchCallback}
      filteredParams={queryParams}
      numSelected={selectedRows.length}
      handleDelete={handleClickDeleteIcon}
      filterFields={filterFields}
      onFilterCallback={onFilterCallback}
    />
  );

  const openPath = (row) => `${location.pathname}/${row.id}`;

  if (loading) { return (<Loading />); }

  return (
    <>
        <Modal
          open={isOpenMove}
          leftBtnText={M.get('actions.cancel')}
          rightBtnText={M.get('actions.delete')}
          handleClose={closeModalMove}
          handleSubmit={handleDeleteMany}
          title={M.get('kiosksMenu.deleteConfirmationTitle')}
          submitLoading={moveLoading}
          disableSave={moveLoading}
        >
          <DialogContentText sx={classes.moveToModalView}>
            {M.get('kiosksMenu.deleteConfirmationText')}
          </DialogContentText>
        </Modal>
        <Box
          sx={{...classes.root,
            ...(isSidebarOpened && classes.rootDrawerOpen),
            ...(!isSidebarOpened && classes.rootDrawerClose)
          }}
        >
          <PageHeader
            generalPageTitle={M.get('kiosksMenu.pageTitle')}
            element={(<Grid sx={classes.actionPart} item xs={12}>
              {!isCatalogEmpty && 
                (!isUserSuperAdmin && !kiosksMenusData.createAbility
                ? <Tooltip title={M.get('kiosksMenu.createLimit')} placement="top">
                  <span>
                    {createButton(true)}
                  </span>
                </Tooltip>
                : createButton()
              )}
            </Grid>
            )}
          />
          {isCatalogEmpty && !catalogLoading ? (
            <EmptyData
              icon={<MenuBookIcon sx={classes.emptyIcon} />}
              title="menuItem"
              titleClassName="emptyPageTitleNoMargin"
              withCreate={false}
              hasLink
              link={routes.catalogueCreate.path}
              linkText={M.get('emptyPage.catalog')}
            />
          ) :
          !fromFilter && !kiosksMenusData?.data?.length ? (
            <EmptyData
              icon={<EmptyKioskMenu />}
              title={pageItem}
              titleClassName="emptyPageTitle"
              withCreate={false}
            />
          ) : (
            <Grid item xs={12}>
              <Box sx={classes.tableRoot}>
                <Grid item container sx={classes.container}>
                  <Box sx={classes.tabsContainer}>
                    <CustomTable
                      tableSources={kiosksMenusData}
                      tableOptions={tableOptions}
                      loading={loading}
                      filteredParams={queryParams}
                      getDataOnChange={getKiosksMenusLists}
                      setFilteredParams={setFilter}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                      openPath={openPath}
                      withOpenAction={false}
                      withEditAction
                      rowIsNotSelectable
                      toolbarView={toolbarView}
                      disableCondition={(row) => !row.active}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          )}
        </Box>
    </>
  )
});

KiosksMenu.propTypes = {
  path: PropTypes.string.isRequired
}

export default KiosksMenu;