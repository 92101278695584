import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  container: {
    boxSizing: 'border-box',
    marginTop: '36px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '24px 40px',
    boxShadow: '-6px 4px 15px rgba(0, 0, 0, 0.05), 6px 4px 20px rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1
  },
  title: {
    textAlign: 'left',
    fontSize: '17px',
    margin: '0 0 35px 0'
  },
  submit: {
    height: '40px',
    textTransform: 'none'
  },
  submitDisabled: {
    opacity: 0.6,
    height: '40px',
    textTransform: 'none'
  },
  forgotPasswordLink: {
    color: theme.palette.primary.sideBarTextColor,
    opacity: 0.6,
    textDecoration: 'none',
    margin: '24px 0 16px 0',
    fontSize: '14px'
  },
  contactUsLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px'
  },
  blockedDescription: {
    textAlign: 'left',
    fontSize: 14,
    margin: '16px 0 0 0'
  },
  reset: {
    textTransform: 'none',
    marginTop: '32px'
  },
  resetDisabled: {
    textTransform: 'none',
    marginTop: '32px',
    height: '40px',
    opacity: 0.6,
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  successDescription: {
    textAlign: 'left',
    fontSize: 16,
    margin: '16px 0 0 0'
  },
  emailImg: {
    marginBottom: '32px'
  },
  emailColor: {
    color: theme.palette.primary.main,
  }
}