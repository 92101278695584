import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Menu,
  Tooltip,
  Avatar,
  useMediaQuery,
} from '@mui/material';

// icons
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import CustomMenuItem from 'components/common/customMenuItem';
import { routes } from 'config/index';
import logOut from 'helpers/authHelper';
import M from 'messages';
import domainData from 'store/domainStore';
import SidebarStore from 'store/ui/sidebarStore'
import lsConstants from 'constants/local-storage';
import { isSuperAdmin } from 'helpers/usersRolesHelper';

import shareVinoLogo from 'assets/shareVinoLogo.png';
import authService from 'services/authService';

import classes from './styles';


const Header = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const currentUser = JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER));
  const isUserSuperAdmin = isSuperAdmin(currentUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const downSM = useMediaQuery('(max-width:980px)');
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    switch (e.currentTarget.name) {
      case "menu":
        setAnchorEl(e.currentTarget)
        break;
      case "sidebar":
        SidebarStore.toggleSidebarByValue(!SidebarStore.sidebarState);
        break;
      default:
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserRouteByRole = () => routes.profileEdit.path.replace(':id', currentUser.id);

  const handleUsersClickByRole = () => {
    const baseRoute = getUserRouteByRole();
    navigate(baseRoute);
    setAnchorEl(null);
  }

  const handleSettingsClick = () => {
    const baseRoute = routes.settings.path;
    navigate(baseRoute);
    setAnchorEl(null);
  }

  const handleLogOut = async () => {
    await authService.logout()
    logOut();
    domainData.resetInitialData();
    navigate({ pathname: routes.login.path });
  }

  const getHeaderUserEmail = () => currentUser.email;

  const renderIcon = () => (
    <Box sx={classes.spView} >
      <Box component='img' src={shareVinoLogo} variant="body2" sx={classes.logoWine} />
    </Box>
  )

  return (
    <AppBar position="fixed" sx={classes.appBar} >
      <Toolbar sx={classes.toolBar}>
        {downSM && <IconButton name='sidebar' onClick={handleClick} size="large">
          {SidebarStore.sidebarState ?
              <CloseIcon sx={classes.menuIcon} />
            : <MenuIcon sx={classes.menuIcon} />
          }
        </IconButton>}
        {renderIcon()}
        <Box sx={classes.rightPart}>
          <Tooltip title={getHeaderUserEmail()} >
            <IconButton name='menu' onClick={handleClick} size="large">
              <Avatar src={currentUser.image} sx={classes.avatar}>{currentUser.name?.charAt(0)}</Avatar>
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
          >
            <CustomMenuItem isLinkActive={location.pathname.includes(`/${routes.profileEdit.path.split('/')?.[1]}`)} text={M.get('pages.profile')} onClick={handleUsersClickByRole}>
              <AccountCircle />
            </CustomMenuItem>
            {!!isUserSuperAdmin && <CustomMenuItem isLinkActive={location.pathname.includes(routes.settings.path)} text={M.get('pages.settings')} onClick={handleSettingsClick}>
              <SettingsIcon />
            </CustomMenuItem>}
            <CustomMenuItem text="Logout" onClick={handleLogOut}>
              <PowerSettingsNewIcon />
            </CustomMenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
})

Header.propTypes = {
  title: PropTypes.string
}

export default Header;