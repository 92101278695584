import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { TextField, Grid, Box, Typography, Button, Modal, Card, CardContent, Divider, InputAdornment } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CalculateIcon from '@mui/icons-material/Calculate';

const EnergyInputComponent = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatorType, setCalculatorType] = useState('automatic'); // 'automatic', 'manual'
  const { formData, culculationData, errors } = formStore;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (formData.alcoholByVolume) {
      formStore.setCalculatedField('alcoholEthanolVal', Math.round(formData.alcoholByVolume * 0.8 * 10000) / 10000);
      formStore.setCalculatedField('polyolsGlycerinVal', Math.round(formData.alcoholByVolume * 0.8 * 0.079 * 10000) / 10000);
    }
    if (formData.sugars) {
      formStore.setCalculatedField('residualSugar', formData.sugars);
    }
  };

  const calculateEnergyAutomatic = () => {
    const residualSugar = parseFloat(culculationData.residualSugar) || 0;
    const organicAcid = parseFloat(culculationData.organicAcid) || 0;
    const polyolsGlycerinVal = parseFloat(culculationData.polyolsGlycerinVal) || 0;
    const alcoholEthanolVal = parseFloat(culculationData.alcoholEthanolVal) || 0;

    const kJ = residualSugar * 17 +
      (polyolsGlycerinVal) * 10 +
      (alcoholEthanolVal) * 29 +
      organicAcid * 13;

    const kcal = residualSugar * 4 +
      (polyolsGlycerinVal) * 2.4 +
      (alcoholEthanolVal) * 7 +
      organicAcid * 3;

    return { kJ, kcal };
  };

  const calculateEnergyManual = () => {
    const residualSugar = parseFloat(culculationData.residualSugar) || 0;
    const organicAcid = parseFloat(culculationData.organicAcid) || 0;
    const protein = parseFloat(culculationData.protein) || 0;
    const fat = parseFloat(culculationData.fat) || 0;
    const salatrims = parseFloat(culculationData.salatrims) || 0;
    const fibre = parseFloat(culculationData.fibre) || 0;
    const polyolsGlycerinVal = parseFloat(culculationData.polyolsGlycerinVal) || 0;
    const alcoholEthanolVal = parseFloat(culculationData.alcoholEthanolVal) || 0;

    const kJ = residualSugar * 17 +
      (polyolsGlycerinVal) * 10 +
      (alcoholEthanolVal) * 29 +
      organicAcid * 13 +
      protein * 17 +
      fat * 37 +
      salatrims * 25 +
      fibre * 8;

    const kcal = residualSugar * 4 +
      (polyolsGlycerinVal) * 2.4 +
      (alcoholEthanolVal) * 7 +
      organicAcid * 3 +
      protein * 4 +
      fat * 9 +
      salatrims * 6 +
      fibre * 2;

    return { kJ, kcal };
  };

  const energyValues = calculatorType === 'automatic'
    ? calculateEnergyAutomatic()
    : calculateEnergyManual();

  const applyCalculatedEnergy = () => {
    handleChange({ target: { name: 'energyKJ', value: energyValues.kJ.toFixed(2) } });
    handleChange({ target: { name: 'energyKcal', value: energyValues.kcal.toFixed(2) } });
    toggleModal();
  };

  const onChangeCulculationData = (e) => {
    const { name, value } = e.target;
    const floatRegex = /^\d*\.?\d*$/;
    if (floatRegex.test(value) || value === '') {
      formStore.onChangeCulculationData(name, value);
      if (calculatorType === 'manual') {
        const { kJ, kcal } = calculateEnergyManual();
        formStore.onChangeCulculationData('energyKJ', kJ.toFixed(2));
        formStore.onChangeCulculationData('energyKcal', kcal.toFixed(2));
      } else {
        const { kJ, kcal } = calculateEnergyAutomatic();
        formStore.onChangeCulculationData('energyKJ', kJ.toFixed(2));
        formStore.onChangeCulculationData('energyKcal', kcal.toFixed(2));
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h6"><b>{M.get('labels.energy.title')}</b></Typography>
            <Tooltip
              title={
                  <Typography
                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                  >
                      The conversion factors for calculating the energy value are listed in Regulation (EU) 1169/2011. Our calculator allows you to calculate the energy value accurately and strictly comply with the regulations. Energy values in both kJ (kilojoules) and kcal (kilocalories) are required. Use the conversion 1 kcal = 4.184 kJ for manual input.
                  </Typography>
              }
              placement='top'
            >
              <HelpOutlineIcon sx={classes.helpIcon} />
            </Tooltip>
          </Box>

          {/* Primary Energy Input Fields */}
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" color="textSecondary" mb={2}>
                {M.get('labels.energy.subTitle')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={M.get('labels.energy.energyKJLabel')}
                    name="energyKJ"
                    value={formData.energyKJ || ""}
                    required
                    onChange={(e) => {
                      const { value } = e.target;
                      const floatRegex = /^\d*\.?\d*$/;
                      if (floatRegex.test(value) || value === '') {
                        handleChange(e)
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!errors.energyKJ}
                    helperText={
                      errors.energyKJ ? errors.energyKJ : null
                    }
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={M.get('labels.energy.energyKcalLabel')}
                    name="energyKcal"
                    value={formData.energyKcal || ""}
                    required
                    onChange={(e) => {
                      const { value } = e.target;
                      const floatRegex = /^\d*\.?\d*$/;
                      if (floatRegex.test(value) || value === '') {
                        handleChange(e)
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!errors.energyKcal}
                    helperText={
                      errors.energyKcal ? errors.energyKcal : null
                    }
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    onClick={toggleModal}
                    fullWidth
                    sx={{ height: '40px' }}
                    disabled={disabled}
                  >
                    <Box sx={classes.withTooltip}>
                      {M.get('labels.energy.useCalculator')}
                      <CalculateIcon sx={{ marginLeft: '4px' }} />
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider style={{ marginTop: "20px" }} />
        </Grid>
      </Grid>

      {/* Modal for Calculations */}
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}>
          <Typography variant="h6" mb={2}>{M.get('labels.energy.modalTitle')}</Typography>
          <Typography variant="subtitle1">{M.get('labels.energy.modalSubTitle')}</Typography>
          <Box mt={2}>
            <Button
              variant={calculatorType === 'automatic' ? 'contained' : 'outlined'}
              onClick={() => setCalculatorType('automatic')}
              sx={{ mr: 1 }}
            >
              {M.get('labels.energy.automaticCalculator')}
            </Button>
            <Button
              variant={calculatorType === 'manual' ? 'contained' : 'outlined'}
              onClick={() => setCalculatorType('manual')}
            >
              {M.get('labels.energy.manualCalculator')}
            </Button>
          </Box>

          <Box mt={3}>
            <Card variant="outlined">
              <CardContent>
                {calculatorType === 'automatic' && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.organicAcidLabel')}
                        name="organicAcid"
                        value={culculationData.organicAcid || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Enter the total organic acids contributing to the wine’s acidity. Organic acids include the sum of Tartaric Acid, Malic Acid, Citric Acid, Lactic Acid, Acetic Acid, and Succinic Acid.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.residualSugarLabel')}
                        name="residualSugar"
                        value={culculationData.residualSugar || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Enter the amount of residual sugar in g/100ml remaining after fermentation.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.polyolsLabel')}
                        name="polyolsGlycerinVal"
                        value={culculationData.polyolsGlycerinVal || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Glycerin content is automatically calculated from ABV. ‘Polyols’ are alcohols with more than two hydroxyl groups. Unless other polyols are present, only glycerin is included using the formula: Glycerin (g/100ml) = Alcohol (g/100ml) × 0.079. You may adjust this manually.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.alcoholLabel')}
                        name="alcoholEthanolVal"
                        value={culculationData.alcoholEthanolVal || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      ABV is pre-filled based on your earlier input. 1% ABV equates to about 8 g/L or 0.8 g/100ml of alcohol.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {calculatorType === 'manual' && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.organicAcidLabel')}
                        name="organicAcid"
                        value={culculationData.organicAcid || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Enter the total organic acids contributing to the wine’s acidity. Organic acids include the sum of Tartaric Acid, Malic Acid, Citric Acid, Lactic Acid, Acetic Acid, and Succinic Acid.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.residualSugarLabel')}
                        name="residualSugar"
                        value={culculationData.residualSugar || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Enter the amount of residual sugar in g/100ml remaining after fermentation.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.polyolsLabel')}
                        name="polyolsGlycerinVal"
                        value={culculationData.polyolsGlycerinVal || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      Glycerin content is automatically calculated from ABV. ‘Polyols’ are alcohols with more than two hydroxyl groups. Unless other polyols are present, only glycerin is included using the formula: Glycerin (g/100ml) = Alcohol (g/100ml) × 0.079. You may adjust this manually.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.alcoholLabel')}
                        name="alcoholEthanolVal"
                        value={culculationData.alcoholEthanolVal || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      ABV is pre-filled based on your earlier input. 1% ABV equates to about 8 g/L or 0.8 g/100ml of alcohol.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.proteinLabel')}
                        name="protein"
                        value={culculationData.protein || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      <b>According to Regulation (EU) 1169/2011</b>, protein content is calculated using the formula: protein = total Kjeldahl nitrogen × 6,25. This component generally does not exist or is present in negligible amounts in wines.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.fatLabel')}
                        name="fat"
                        value={culculationData.fat || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      <b>According to Regulation (EU) 1169/2011</b>, ‘fat’ means total lipids, and includes phospholipids. This component generally does not exist or is present in negligible amounts in wines.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.salatrimsLabel')}
                        name="salatrims"
                        value={culculationData.salatrims || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      This component generally does not exist or is present in negligible amounts in wines.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={M.get('labels.energy.fibreLabel')}
                        name="fibre"
                        value={culculationData.fibre || ""}
                        onChange={onChangeCulculationData}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    <Typography
                                      sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                    >
                                      This component generally does not exist or is present in negligible amounts in wines.
                                    </Typography>
                                  </>
                                }
                              >
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1"><b>{M.get('labels.energy.calculatedEnergy')}</b></Typography>
                <Typography>{M.get('labels.energy.energyKJLabel')}: {energyValues.kJ.toFixed(2)}</Typography>
                <Typography>{M.get('labels.energy.energyKcalLabel')}: {energyValues.kcal.toFixed(2)}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applyCalculatedEnergy}
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  {M.get('labels.energy.useThisValues')}
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Modal>
    </>
  );
});

export default EnergyInputComponent;
