
export default {
  icon: {
    opacity: 0.7,
    textTransform: 'none',
    margin: '10px 0 0 0'
  },
  box: {
    minWidth: 'max-content',
    marginLeft: '10px'
  },
  buttonInsertAll: {
    opacity: 0.8,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)'
  },
  primaryIconButton: {
    height: '36px',
    minWidth: '109px',
    fontWeight: '500',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    },
    textTransform: 'none',
    opacity: 1
  },
};