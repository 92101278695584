import React from 'react';
import { Box } from '@mui/material';
import shareVinoLogo from 'assets/shareVinoLogo.png';
import loveWino from 'assets/loveWine.png';
import classes from './style';

const withAuthHOC = (WrappedComponent, hideImage) => () => (
    <Box sx={classes.logoContainer} >
      <Box component='img' src={shareVinoLogo} variant="body2" sx={classes.logoWine} />
      <WrappedComponent />
      {!hideImage && <Box component='img' src={loveWino} alt="login" sx={classes.backgroundImg} />}
    </Box>
);

export default withAuthHOC;
