import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@mui/material';
import M from 'messages';
import classes from './styles';

function Modal({
  children = null,
  handleClose,
  handleSubmit = null,
  open,
  title,
  leftBtnText = M.get('actions.cancel'),
  rightBtnText = M.get('actions.save'),
  disableSave = false,
  submitLoading = false,
  description = '',
  modalClassName = '',
  dialogContentClassName = 'dialogContent',
  dialogDescriptionClassName = 'dialogDescription',
  footerBtnStyle = '',
  customSubmitButton = null,
  dialogHeader = 'dialogTitle',
  dialogTitle = '',
  hideSubmitBtn = false,
  actionFooterStyle = '',
  submitBtnVariant = 'text',
  handleActionMidBtn = null,
  showMidBtn = false,
  midBtnText = '',
  midBtnLoading = false,
  disableMidBtn = false,
  disableLeftBtn = false
}) {
  return (
    <Box sx={classes.container}>
      <Dialog fullWidth sx={{ '& .MuiDialog-paperScrollPaper': classes[modalClassName] }} open={open} onClose={handleClose}>
        <DialogTitle sx={classes[dialogHeader]} >
          <Box component='span' sx={classes[dialogTitle]}>{title}</Box>
          {description && <Typography sx={classes[dialogDescriptionClassName]}>
            {description}
          </Typography>}
        </DialogTitle>
        <DialogContent dividers sx={classes[dialogContentClassName]}>
          {children}
        </DialogContent>
        <DialogActions sx={{...classes.actionFooter, ...classes[actionFooterStyle]}}>
          <Button sx={{...classes.secondary, ...classes.textStyle, ...classes[footerBtnStyle]}} disabled={disableLeftBtn} onClick={handleClose} color="secondary">
            {leftBtnText}
          </Button>
          {
            showMidBtn && (<Button sx={{...classes.textStyle, ...classes[footerBtnStyle]}} disabled={disableMidBtn} onClick={handleActionMidBtn} variant="outlined" color="primary">
              {midBtnLoading && <CircularProgress size={16} />}
              {!midBtnLoading && midBtnText}
            </Button>)
          }
          {
            !hideSubmitBtn && (customSubmitButton || (<Button variant={submitBtnVariant} sx={{...classes.textStyle, ...classes[footerBtnStyle], ...(submitBtnVariant === 'contained' ? null : classes.primary)}} disabled={disableSave} onClick={handleSubmit} color="primary">
              {submitLoading && <CircularProgress size={16} />}
              {!submitLoading && rightBtnText}
            </Button>))
          }
        </DialogActions>
      </Dialog>
    </Box>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  leftBtnText: PropTypes.string,
  rightBtnText: PropTypes.string,
  disableSave: PropTypes.bool,
  submitLoading: PropTypes.bool,
  description: PropTypes.string,
  modalClassName: PropTypes.string,
  dialogContentClassName: PropTypes.string,
  customSubmitButton: PropTypes.node,
  dialogDescriptionClassName: PropTypes.string,
  footerBtnStyle: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogHeader: PropTypes.string,
  hideSubmitBtn: PropTypes.bool,
  submitBtnVariant: PropTypes.string,
  actionFooterStyle: PropTypes.string,
  handleActionMidBtn: PropTypes.func,
  showMidBtn: PropTypes.bool,
  midBtnText: PropTypes.string,
  midBtnLoading: PropTypes.bool,
  disableMidBtn: PropTypes.bool,
  disableLeftBtn: PropTypes.bool
};

export default Modal;