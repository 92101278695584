import React from 'react';
import { Container, Typography } from '@mui/material';

const ComingSoon = () => (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant="body1" paragraph>
        We&apos;re working hard to bring you something amazing! Stay tuned for updates.
      </Typography>
    </Container>
  );

export default ComingSoon;