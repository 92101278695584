export default [
    "Apéritif",
    "Asian",
    "Barbecue",
    "Burgers",
    "Buffet",
    "Charcuterie",
    "Cheese",
    "Chicken",
    "Dairy",
    "Dessert",
    "Fish",
    "Foie Gras",
    "Gluten-Free",
    "Grilled",
    "Indian",
    "Low-Carb",
    "Low-Fat",
    "Meat",
    "Mediterranean",
    "Mexican",
    "Moroccan",
    "Mushroom",
    "Nut-Free",
    "Pasta",
    "Pizza",
    "Roasted",
    "Risotto",
    "Salad",
    "Sandwiches",
    "Seafood",
    "Soup",
    "Spicy Food",
    "Steak",
    "Stir-fry",
    "Sushi",
    "Tacos",
    "Tapas",
    "Vegan",
    "Vegetarian",
    "Veggie"
]