import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Box, ListItem, ListItemText, ListItemButton, Tooltip, Icon, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import domainData from 'store/domainStore';

import classes from './styles';

const SideBarLink = observer(({ sidebarStore, item = {groupClassName: ''}, link: linkWithParameters, icon = null, label, containerClassName = '', iconPosition = '', height = 48, isLastElement = false }) => {
  const downSM = useMediaQuery('(max-width:980px)');
  const location = useLocation();

  const currentSpData = domainData.getCurrentSpData();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const isAdminOrHigher = domainData.userIsSuperAdminOrPartner();

  useEffect(() => {
    runInAction(() => {
      sidebarStore.clickedSideBarLink(location.pathname);
    });
  }, [location.pathname, sidebarStore]);

  const link = typeof linkWithParameters === 'function' ? linkWithParameters({ isUserSuperAdmin, domainData }) : linkWithParameters;
  const storedActiveLink = sidebarStore.activeSideBarLink;
  const isLinkActive = link && storedActiveLink ? storedActiveLink.includes(link) : location.pathname.includes(link);
  const openDrawer = sidebarStore.sidebarState;

  const toggleCollapse = () => {
    runInAction(() => {
      sidebarStore.clickedSideBarLink(link);
    });
    if (link && downSM) {
      sidebarStore.toggleSidebarByValue(false);
    }
  };

  return (
    <Tooltip title={item.isParent || openDrawer ? '': label} aria-label={item.label} placement="right">
      <Box sx={{...classes[containerClassName], ...(isLastElement && classes.lastElement)}} >
        <ListItem
          component={link && Link}
          sx={{
            ...classes.listItem,
            ...(!openDrawer && classes.listItemClosedDrawer),
          }}
          onClick={toggleCollapse}
          to={link}
          style={{
            height,
            display: !item.show ? true : item.show({isSidebarOpened: openDrawer, isUserSuperAdmin, isAdminOrHigher, currentSpData }) ? true : 'none',
          }}
        >
          <ListItemButton
            disabled={item.disable ? item.disable() : false}
            disableRipple
          >
            {icon && iconPosition === 'start' && (
              <Icon
                sx={{
                  ...(openDrawer ? classes.listItemIcon : classes.listItemIconClosedDrawer),
                  ...(isLinkActive && classes.iconActive),
                  ...(item.hideOpacity && classes.hideOpacity)
                }}
              >
                {icon}
              </Icon>
            )}
            <ListItemText
              sx={{
                ...classes.listItemText,
                ...(isLinkActive && classes.linkActive),
              }}
              classes={{
                '& .MuiListItemText-primary': {
                  ...classes.listItemTextChild,
                  ...classes[item.groupClassName]
                }
              }}
              primary={label}
            />
            {icon && iconPosition === 'end' && (
              <Icon
                sx={{
                  ...(openDrawer ? classes.listItemIcon : classes.listItemIconClosedDrawer),
                  ...(!openDrawer && isLinkActive && classes.iconActive),
                }}
              >
                {icon}
              </Icon>
            )}
          </ListItemButton>
        </ListItem>
      </Box>
    </Tooltip>
  );
})

SideBarLink.propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  iconPosition: PropTypes.string,
  height: PropTypes.number,
  item: PropTypes.instanceOf(Object),
  isLastElement: PropTypes.bool
};

export default SideBarLink;