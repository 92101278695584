import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  main: {
    display: 'flex',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
  },
  inputArea: {
    cursor: 'pointer',
    width: '100%',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  imageRemoveContent: {
    display: 'flex',
  },
  preview: {
    width: '180px'
  },
  secondaryLogo: {
    maxWidth: '100px'
  },
  previewBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

  },
  removeBtn: {
    color: '#f44336',
    alignSelf: 'flex-start',
    padding: 0
  },
  loadingProgress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    color: theme.palette.primary.main,
  },
  avatar: {
    width: '84px',
    height: '84px',
    marginBottom: '12px'
  },
  avatarEditTxt: {
    fontWeight: '500',
    padding: '0px 8px',
    borderRadius: '4px',
    textTransform: 'capitalize'
  }
}