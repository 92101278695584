export default [
    {
      name: 'email',
      label: 'login.email',
      type: 'text',
      required: true,
    },
    {
      name: 'password',
      label: 'login.email',
      type: 'text',
      required: true,
    },
  ]