import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import domainData from 'store/domainStore';
import useDebounce from "components/common/customDebounceHook/useDebounce";

import M from 'messages';

import classes from './styles';

const CustomAutocompleteRange = ({ field, onFilterCallback, searchValue = '', multiple = false, filteredParams }) => {
  const memoizedFilterValue = useMemo(
    () => ({ [field.id]: searchValue }), [field.id, searchValue]
  );
  const [searchTerm, setSearchTerm] = useState(memoizedFilterValue);

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const debouncedValue = useDebounce(searchTerm, 1000);

  const [anchorEl, setAnchorEl] = useState(null);
  const [modifiedOptions, setModifiedOptions] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [isNone, setNone] = useState(false);

  const [checked, setChecked] = useState(
    searchTerm && searchTerm[field.id]
      ? Array.isArray(searchTerm?.[field.id])
        ? field.optionsType === 'string' ? searchTerm?.[field.id]?.map(String)
        : searchTerm?.[field.id]?.map(Number) || []
        : searchTerm?.[field.id] || ''
      : []
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getCurrentServiceProvider = () => {
    const currentSP = domainData.getCurrentSP();
    const currSP = currentSP && currentSP !== 'allSP' ? { currentServiceProvider: currentSP } : {};
    return currSP;
  }

  const fetchData = () => {
    if(typeof field.options === 'function') {
      let params = {};
      if (!field.withoutParams) {
        params = field.callbackParams || { params: {...getCurrentServiceProvider()} }
      }
      field.options(params).then(res => {
        let fieldData = res.data;
        if (res.data && res.data.data) {
          fieldData = res.data.data;
        }
        setModifiedOptions(field.adapterCallback(fieldData));
        setOptionsList(field.adapterCallback(fieldData));
      })
    } else {
      setModifiedOptions(field.options);
      setOptionsList(field.options);
    }
    const paramsNoneField = filteredParams?.params?.filter;
    
    const hasNone = !!(paramsNoneField && Object.keys(paramsNoneField).includes(field.noneField));
    setNone(hasNone);

    setSearchTerm({ [field.id]: searchValue });
  }

  useEffect(() => {
    if (field.rerender || field.notClearable) {
      fetchData();
    }
    return () => {
      // NOTE: if changing SP should not clear selected data pass notClearable=true parameter
      if (!field.notClearable) {
        setChecked([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, field.rerender, field.options])

  useEffect(() => {
    if (!field.notClearable) {
      fetchData();
    }

    return () => {
      // NOTE: if changing SP should not clear selected data pass notClearable=true parameter
      if (!field.notClearable) {
        setChecked([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, field.options])

  useEffect(() => {
    if (onFilterCallback) {
      onFilterCallback(debouncedValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setField = (newChecked = []) => {
    if (field.noneField) {
      setNone(false);
      setSearchTerm({[field.id]: newChecked,[field.noneField]: null});
    } else {
      setSearchTerm({[field.id]: newChecked});
    }
  }

  const handleClear = () => {
    if (checked.length || (searchTerm[field.id] && searchTerm[field.id].length) || typeof searchTerm[field.id] === 'number' || isNone) {
      setSearchFieldValue('');
      setOptionsList(modifiedOptions);
      setChecked([]);
      setField();
    }
  };

  const handleItemClickMultiple = (item) => {
    const {value} = item;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSearchFieldValue('');
    setOptionsList(modifiedOptions);
    setChecked(newChecked);
    setField(newChecked);
  };

  const handleItemClickSingle = (item) => {
    const {value} = item;
    const newChecked = value;
    if (checked === value) {
      setChecked('');
      setSearchTerm({[field.id]: ''});
    } else {
      setChecked(newChecked);
      setField(newChecked);
    }
    setSearchFieldValue('');
    setOptionsList(modifiedOptions);

  };

  const handleItemClick = (item) => {
    if (multiple) {
      handleItemClickMultiple(item);
    } else {
      handleItemClickSingle(item);
    }
  };

  const itemChecked = (item) => multiple ? checked.indexOf(item.value) !== -1 : checked === item.value;

  const sorter = (a, b) => {
    if(multiple && checked.includes(a.value)){
      return -1;
    };
    if(multiple && checked.includes(b.value)){
      return 1;
    };
    return 0;
};

  const listView = () => (
    <List sx={classes.root}>
      {optionsList.length ?
        (field.sortBySelected ? [...optionsList].sort(sorter) : optionsList).map((item) => (
          <ListItem selected={itemChecked(item)} sx={classes.listItem} key={item.value} role={undefined} dense button onClick={() => handleItemClick(item)}>
            <ListItemIcon classes={{root: classes.listItemIcon}}>
              <Checkbox
                color="primary"
                edge="start"
                checked={itemChecked(item)}
              />
            </ListItemIcon>
            <ListItemText id={item.value} primary={item.title ? `${item.title}` : `${item.label}`} />
          </ListItem>)) : (
            <Box sx={classes.noFound}>
              <HelpIcon sx={classes.helpIcon} color="primary" style={{ height: '54px', width: '54px' }}/>
              <Typography sx={classes.noMatchesText}>{M.get('filterFields.noMatches')}</Typography>
            </Box>
          )
      }
    </List>
  );

  const getSelectedValue = () => {
    let result = [];
    if (searchTerm?.[field.id] && searchTerm?.[field.id].length && multiple) {
      result = (field.sortBySelected ? [...modifiedOptions].sort(sorter) : modifiedOptions).filter( (i) =>
          searchTerm?.[field.id].includes(i.value) || searchTerm?.[field.id].includes(i.value.toString())
      );
    } else {
      result = (field.sortBySelected ? [...modifiedOptions].sort(sorter) : modifiedOptions).filter((i) =>
          searchTerm?.[field.id] === i.value || searchTerm?.[field.id] === i.value.toString()
      );
    }
    const selectedValue = result.length ? result[0].label : '';
    return {
      selectedValue,
      selectedCount: result.length > 1 ? result.length : '',
    };
  };

  const handleSearchChange = (event) => {
    const {value} = event.target;
    setSearchFieldValue(value);
    const newOptions = modifiedOptions.filter(i => i.label.toLowerCase().includes(value.toLowerCase()));
    setOptionsList(newOptions);
  }

  const handleSearchClear = () => {
    setSearchFieldValue('');
    const newOptions = [...modifiedOptions];
    setOptionsList(newOptions);
  }

  const handleNone = () => {
    if (isNone) {
      setSearchTerm({[field.id]: [],[field.noneField]: null});
    } else {
      setSearchTerm({[field.id]: [],[field.noneField]: 'null'});
    }
    setNone(!isNone);
    setSearchFieldValue('');
    setOptionsList(modifiedOptions);
    setChecked([]);
  }

  const {selectedValue, selectedCount} = getSelectedValue();

  if (field.withHide && !optionsList.length && !searchFieldValue.length) return null;

  return (
    <Box sx={classes.box}>
      <FormControl margin="dense" fullWidth variant="outlined">
        <Box
          sx={{...classes.mainElement, ...((selectedValue || isNone) && [classes[field.selectedClassName]])}}
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <Box sx={classes.valueTextContainer}>
            {selectedValue ?
              (<Box sx={classes.valueText}>
                  <Box sx={classes.fieldValue}>{selectedValue}</Box>
                  {selectedCount && <Box style={{marginLeft: 6}}> +{selectedCount-1}</Box>}
              </Box>) 
              : isNone ? 
              <Box sx={classes.valueText}>
                  <Box sx={classes.fieldValue}>{M.get('actions.none')}</Box>
              </Box> :  
              (<Box sx={classes.placeholder}>{M.get(`${field.label}`)}</Box>)
            }
          </Box>
          <Box sx={classes.arrowIcon}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          classes={{
            root: classes.popover,
            paper: classes.paper
          }}
        >
          <Box sx={{...classes.popoverContainer, ...(!optionsList.length && classes.popoverNoOptions)}} >
            <FormControl fullWidth variant="outlined">
              <Box sx={classes.root}>
                <TextField
                  variant="standard"
                  autoComplete="off"
                  type="text"
                  size="small"
                  id="search"
                  placeholder={M.get('filterFields.search')}
                  value={searchFieldValue}
                  onChange={handleSearchChange}
                  InputLabelProps={{style: {fontSize: 14, fontWeight: 500, opacity: 0.8}}}
                  sx={{
                    '& .MuiInput-root': classes.searchRoot,
                    '& .MuiInput-input': classes.searchInput,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={classes.adornedEnd} onClick={handleSearchClear} position='end'>
                          <CloseIcon />
                      </InputAdornment>),
                  }} />
                {field.noneField && !!optionsList.length && (
                  <ListItemButton selected={isNone} role={undefined} dense sx={{...classes.listItem, ...classes.noneField}} onClick={handleNone}>
                    <ListItemIcon classes={{root: classes.listItemIcon}}>
                      <Checkbox
                        color="primary"
                        edge="start"
                        checked={isNone}
                      />
                    </ListItemIcon>
                    <ListItemText primary={M.get('actions.none')} />
                  </ListItemButton>
                )}

                {listView()}
                {optionsList.length > 0 && <Button onClick={handleClear} sx={classes.clearOptionsBtn}>{M.get('actions.clearSelection')}</Button>}
              </Box>
            </FormControl>
          </Box>
        </Popover>
      </FormControl>
    </Box>
  );
};

CustomAutocompleteRange.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  searchValue:  PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  multiple: PropTypes.bool,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
};

export default CustomAutocompleteRange;
