import { post } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  upload: (file, type) => {
    const data = new FormData();
    data.append('file', file);
    let url = apiEndpoints.fileUpload;
    if (type) {
      url += `?type=${type}`;
    }
    const options = {
      url,
      data,
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    return post(options);
  },
}