import { get, post, put } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getServiceProviders: async (params) => {
    const options = { url: apiEndpoints.serviceProviders, params: { ...params } };
    return get(options);
  },
  getServiceProvidersNames: async (params) => {
    const options = { url: apiEndpoints.serviceProvidersNames, params: { ...params } };
    return get(options);
  },
  getServiceProvider: async (id) => {
    const options = { url: apiEndpoints.serviceProvider.replace(':id', id) };
    return get(options);
  },
  createServiceProvider: async (data) => {
    const options = { url: apiEndpoints.serviceProviders, data };
    return post(options);
  },
  updateServiceProvider: async (data, id) => {
    const options = { url: apiEndpoints.serviceProvider.replace(':id', id), data };
    return put(options);
  },
  approvePartners: async (ids) => {
    const options = { url: apiEndpoints.serviceProvidersApprove, data: { ids } };
    return put(options);
  },
  rejectPartners: async (ids) => {
    const options = { url: apiEndpoints.serviceProvidersReject, data: { ids } };
    return put(options);
  }
}
