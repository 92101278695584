import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  inviteUserModal: {
    maxWidth: '754px',
    height: '520px',
  },
  inviteUserModalSmall: {
    maxWidth: '754px',
    height: '390px',
  },
  save: {
    backgroundColor: '#283593',
    color: 'white',
    '&:hover': {
      backgroundColor: '#001064'
    }
  },
  textStyle: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none'
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.primary.sideBarTextColor,
  },
  dialogHeader: {
    padding: '16px 24px 0 24px',
  },
  dialogContent: {
    padding: '24px 24px 0 24px',
    '& > p:first-of-type': {
      marginBottom: '0px'
    }
  },
  actionFooter: {
    height: '52px',
    padding: '0 8px',
  },
  dialogDescription: {
    fontSize: '14px',
    fontWeight: '400',
    opacity: '0.8',
    marginBottom: '24px'
  },
  modalContent: {
    borderTop: 'unset',
  },
  footerBtnStyle: {
    textTransform: 'none'
  },
  noContentStyle: {
    padding: '12px 24px',
    border: 'none'
  },
  userInviteTitle: {
    fontSize: '17px',
    fontWeight: '600',
  },
  accountModals: {
    width: '375px',
    height: '564px',
  },
  accountModalContent: {
    maxHeight: '360px',
    padding: '10px 40px',
    borderTop: 'unset',
    borderBottom: 'unset',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  accountBtnStyle: {
    width: '100%',
    padding: '10px 0',
    margin: '0 0 8px 0 !important',
    textTransform: 'none',
    '& > :hover': {
    }
  },
  accountActionColumn: {
    padding: '10px 40px',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: 'fit-content',
    '& > button:first-of-type': {
      color: theme.palette.primary.sideBarTextColor
    },
    '& > button:last-child': {
      color: 'white'
    },
    '& > button:disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.6
    }
  },
  changePasswordModalTitle: {
    textAlign: 'center',
    padding: '24px 40px 16px 40px',
  },
  changeSubscriptionTitle: {
    fontWeight: '500',
    fontSize: '20px',
  },
  deleteUserTitle: {
    fontSize: '17px',
    fontWeight: '600',
  },
  approveUserTitle: {
    fontSize: '17px',
    fontWeight: '500',
  },
};