import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
    boxShadow: 'unset'
  },
  boxContainer: {
    borderRadius: '5px',
    color: theme.palette.primary.main,
    height: 'fit-content',
    boxShadow: '0px 0px 3px 0px #d4d4d4'
  },
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.backgroundColor
  },
  tabsHeader: {
    backgroundColor: theme.palette.primary.backgroundColor,
    color: 'black',
    boxShadow: '0 1px 0px #e4e4e4',
    marginBottom: '20px',
  },
  tabPanel: {
    padding: '16px 0 0 0',
    '& > div:first-of-type': {
      padding: '16px 0 0 0',
    },
  },
  editBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  edit: {
    backgroundColor: theme.palette.primary.mainBackground,
    boxShadow: 'unset',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#eff2f5',
      color: theme.palette.primary.main,
      boxShadow: 'unset',
    },
  },
  actions: {
    backgroundColor: theme.palette.primary.mainBackground,
    boxShadow: 'unset',
    color: '#3D3D3D',
    opacity: '.8',
    marginRight: '10px',
    padding: '6px 10px',
    '&:hover': {
      backgroundColor: '#eff2f5',
      color: '#3D3D3D',
      boxShadow: 'unset',
      opacity: '1',
    },
  },
  select: {
    height: '43px',
    textAlign: 'left'
  },
  tabFormInvalid: {
    color: '#f44336'
  },
  invalidSelect: {
    color: '#f44336',
    "&.Mui-focused": {
      color: "#f44336",
    },
  },
  errorHelperText: {
    color: '#f44336'
  },
  menuPaper: {
    maxHeight: '450px',
  },
  invalidSelectContainer: {
    '&:before': {
      borderBottom: '2px solid #f44336',
    },
    '&:after': {
      borderBottom: '2px solid #f44336',
    },
    '&:hover': {
      "&:before": {
        borderColor: "#f44336",
        borderBottomWidth: "2px"
      },
    }
  },
  boxContainerTabs: {
    padding: '0'
  },
  footerPart: {
    display: 'flex',
    padding: '15px 24px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginTop: '40px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '5px 10px 0 0',
  },
  disabledError: {
    color: 'red',
    marginLeft: '10px',
    fontSize: '14px'
  },
  optionItem: {
    '&[aria-disabled="true"]': {
      opacity: 1
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '30px',
    alignItems: 'flex-end',
    borderRadius: '4px',
  },
  itemImage: {
    borderRadius: '4px',
  },
  itemSize: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.notActiveTextColor
  },
  smallContainer: {
    margin: '0 30px 0 6px'
  },
  formTitle: {
    marginBottom: '16px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
  },
  formTextDisabled: {
    opacity: '0.5',
  },
  formDescription: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    margin: '0',
  },
  selectLine: {
    paddingBottom: '32px',
    textAlign: 'left',
    borderBottom: '1px solid #DADADA',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  scheduleButton: {
    margin: '5px 5px 0 0',
    textTransform: 'capitalize',
    padding: '8px 12px',
    minWidth: '56px'
  },
  scheduleButtonRequired:{
    margin: '5px 5px 0 0',
    textTransform: 'capitalize',
    padding: '8px 12px',
    minWidth: '56px',
    border: `1px solid ${theme.palette.primary.error}`,
    color: theme.palette.primary.error,
  },
  timeBox: {
    display: 'flex',
  },
  timeFieldControl: {
    margin: '20px 10px 0 0',
  },
  timeField: {
    '& input': {
      '&[type=time]::-webkit-calendar-picker-indicator': {
        fontSize: '20px',
        padding: '0'
      },
      '&[type=time]:disabled::-webkit-calendar-picker-indicator':{
        display: 'none'
      },
      '&[type=time]:required:invalid::-webkit-datetime-edit':{
        display: 'none'
      },
      '&[type=time]::disabled::-webkit-inner-spin-button': {
        color: 'red',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      padding: '0 8px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '& label':{
      transform: 'translate(13px, 12px) scale(1)'
    },
    margin: '20px 12px 30px 0',
  },
  minAllowedTimeField: {
    margin: '20px 12px 30px 0',
    '& input': {
      padding: '10.5px 8px',
    },
    '& label':{
      transform: 'translate(13px, 12px) scale(1)'
    },
  },
  timeFieldWithoutValue: {
    '& input[type=time]::-webkit-datetime-edit': {
        display: 'none'
    },
  },
  timeIcon: {
    fontSize: '21px',
    transform: 'translate(6px, 0px)',
    padding: '0',
  },
  payLater: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxField: {
    margin: '0',
  },
  marginBottom: {
    marginBottom: '16px',
  },
  percent: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '12px',
  },
  fieldError: {
    color: theme.palette.primary.error,
    '& input': {
      color: theme.palette.primary.error,
    }
  },
  switch: {
    marginLeft: '12px'
  }
}
