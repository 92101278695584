import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import dashboardService from "services/dashboardService";
import logger from "logger";

class DashboardStore {
  loading = false;

  KPIDataLoading = true;

  dashboardUrl = '';

  dateRange = { start: dayjs().subtract(6, 'day').format('YYYY-MM-DD'), end: 'today' };

  totalWines = 0;

  totalViews = 0;

  totalRatings = 300;

  averageRating = 4.2;

  visitsByCountry = [];

  viewsByWine = [];

  averageRatingByWine = [];

  viewsByColor = [];

  viewsBySweetness = [];

  viewsByStyle = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Placeholder function for API call to fetch KPI data
  fetchKPIData = async () => {
    try {
      // Fetch data from API (replace with actual API call)
      const { data } = await dashboardService.getKPIData(this.dateRange.start, this.dateRange.end);

      // For now, using hardcoded values
      this.totalWines = data.totalWines; // Update with `data.totalWines`
      this.totalViews = data.totalViews; // Update with `data.totalViews`
      this.totalRatings = data.totalRatings; // Update with `data.totalRatings`
      this.averageRating = data.averageRating[0].averageRating; // Update with `data.averageRating`
      this.viewsByWine = data.viewsByWine; // Update with `data.viewsByWine`
      this.KPIDataLoading = false;
    } catch (error) {
      this.KPIDataLoading = false;
      logger.error("Failed to fetch KPI data", error);
    }
  };

  // Placeholder function for API call to fetch Visits by Country data
  fetchVisitsByWineColors = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByColor(this.dateRange.start, this.dateRange.end);
      this.viewsByColor = response.data.viewsByColor;
    } catch (error) {
      logger.error("Failed to fetch visits by country data", error);
    }
  };

  // Placeholder function for API call to fetch Average Rating by Wine data
  fetchAverageRatingByWine = async () => {
    try {
      const response = await dashboardService.getAverageRatingByWine(this.dateRange.start, this.dateRange.end);
      this.averageRatingByWine = response.data.averageRatingByWine;
    } catch (error) {
      logger.error("Failed to fetch average rating by wine data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Sweetness data
  fetchViewsByWineSweetness = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsBySweetness(this.dateRange.start, this.dateRange.end);
      this.viewsBySweetness = response.data.viewsBySweetness;
    } catch (error) {
      logger.error("Failed to fetch views by sweetness data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Style data
  fetchViewsByWineStyle = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByStyle(this.dateRange.start, this.dateRange.end);
      this.viewsByStyle = response.data.viewsByStyle
    } catch (error) {
      logger.error("Failed to fetch views by style data", error);
    }
  };

  // Placeholder function for API call to fetch Views by Style data
  fetchViewsByCountry = async () => {
    try {
      // Replace with actual API call
      const response = await dashboardService.getViewsByCountry(this.dateRange.start, this.dateRange.end);
      this.viewsByCountry = response.data.viewsByCountry
    } catch (error) {
      logger.error("Failed to fetch views by style data", error);
    }
  };

  setDateRange = (newDateRange) => {
    this.dateRange = newDateRange;
  }


  setDashboardUrl = (url) => {
    this.dashboardUrl = url;
  }
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
