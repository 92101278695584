import logOut from 'helpers/authHelper';
import { routes } from 'config/index';

export default function onUnauthorized(error, reactRouterHistory, reactNavigate) {
  logOut();

  if (reactRouterHistory.pathname !== routes.login.path) {
    reactNavigate(routes.login.path, { state: { url: reactRouterHistory.pathname } });
  }
}
