
import { variables} from 'config';

export default {
  root: {
    padding: '10px 10px 10px 22px',
    minWidth: '850px',
    textAlign: 'start',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: variables.tableToolBarHeight,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    flexGrow: 1,
  },
  filters: {
    display: 'flex',
    WebkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
  },
  actions: {
    minHeight: variables.tableToolBarHeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignSelf: 'start',
  }
};