export default {
  group: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  buttonStyle: {
    textTransform: 'none',
    marginRight: '8px',
    marginTop: '8px',
    border: '1px solid #1A5787',
    '&:hover': {
      backgroundColor: '#1A5787',
      color: '#ffffff',
      border: '1px solid #1A5787',
    },
  },
  largeButtonStyle: {
    fontSize: '1rem',
    padding: '10px 16px',
  },
};
