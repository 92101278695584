const logger = {
  error: (message, error) => {
    // eslint-disable-next-line no-console
    console.error(message, error);
    // todo: send logs to a monitoring service here
  },
  log: (message, data) => {
    // eslint-disable-next-line no-console
    console.log(message, data);
    // todo: send logs to a monitoring service here
  },
};

export default logger;
