import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.notActiveIconColor,
    minHeight: `calc(100vh - ${variables.headerHeight} - 60px)`,
    backgroundColor: theme.palette.primary.mainBackground,
    fontFamily: 'Montserrat, sans-serif',
  },
  icon: {
    height: '216px',
    width: '216px',
    paddingTop: '100px',
    color: theme.palette.primary.notActiveIconColor
  },
  title: {
    margin: '20px 0',
    fontSize: '32px',
    fontWeight: 'bold'
  },
  description: {
    margin: '0 0 30px 0',
    fontSize: '16px',
    fontWeight: '400'
  }
}

