import React from 'react';
import { observer } from 'mobx-react';
import { TextField, FormHelperText, Select, MenuItem, InputLabel, Grid, Box, Typography, FormControl } from '@mui/material';
import ImageInput from 'components/common/form/imageInput';

const WineInfo = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const maxSymbols = 255;
    const years = Array.from({ length: 61 }, (_, index) => (2030 - index).toString());
    const yearOptions = ['NV', ...years,];
    const { formData, errors } = formStore;

    return (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    size="small"
                    label={M.get('labels.wine.name')}
                    id="name"
                    name="name"
                    value={formData.name}
                    required
                    onChange={handleChange}
                    variant="outlined"
                    error={!!errors.name}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                        errors.name ? errors.name : null
                    }
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="year-select-label">
                        {M.get('labels.wine.year')}
                    </InputLabel>
                    <Select
                        id="year-select-label"
                        name="year"
                        label={M.get('menuItem.wineYear')}
                        value={formData.year || ''}
                        onChange={handleChange}
                        error={!!errors.year}
                        MenuProps={{
                            classes: { paper: classes.menuPaper },
                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        }}
                        disabled={disabled}
                    >
                        <MenuItem key="None" value="">
                            None
                        </MenuItem>
                        {yearOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.year && (
                        <FormHelperText sx={classes.errorHelperText}>
                            {M.get('form.errors.required')}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box>
                    <Typography sx={classes.fieldHeaderText}>{M.get('labels.wine.image')}</Typography>
                </Box>
                <FormControl fullWidth variant="filled">
                    <ImageInput
                        onChange={(e) => handleChange({ target: { value: e, name: 'image' } })}
                        defaultValue={formData.image}
                        errorStyle={!!errors.image}
                        smallPreview
                        disabled={disabled}
                    />
                </FormControl>
            </Grid>
        </>
    );
});

export default WineInfo;
