import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classes from './styles';

export default function PageHeader({ title = '', element, generalPageTitle = null, onlyGeneralPageTitle = false, elementGrid = null, navigation = null, description = null }) {
  return (
    <Grid item container>
      <Helmet>
        <title>{generalPageTitle || title}</title>
      </Helmet>
      {!onlyGeneralPageTitle && <>
        <Grid item xs={12} sx={classes.titleContainer}>
          <Typography sx={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={element ? elementGrid || 8 : 12}>
          {description &&
            <Grid item xs={12}>
              <Typography sx={classes.description}>
                {description}
              </Typography>
            </Grid>
          }
          {navigation &&
            <Box sx={classes.subTitle}>
              {navigation.map(item => (
                <React.Fragment key={item.id || item.title}>
                  <Typography onClick={item.handelClick} sx={classes.subTitleLink}>
                    {item.title}
                  </Typography>
                  {item.withChevronRightIcon && <ChevronRightIcon />}
                  {item.subTitle && <Box component='span' sx={classes.displayName} >{item.subTitle}</Box>}
                </React.Fragment>
              ))}
            </Box>
          }
        </Grid>
        {element}
      </>}
    </Grid >
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  generalPageTitle: PropTypes.string,
  onlyGeneralPageTitle: PropTypes.bool,
  elementGrid: PropTypes.number,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  navigation: PropTypes.instanceOf(Array),
  element: PropTypes.node
}