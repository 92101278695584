import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    minHeight: '556px',
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: `calc(${variables.headerHeight} + 24px)`
  },
  backgroundWhite: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    placeContent: 'center'
  },
  icon: {
    height: '150px',
    width: '150px',
    color: theme.palette.primary.notActiveIconColor
  },
  title: {
    margin: '20px 0',
    fontSize: '32px',
    fontWeight: '400'
  },
  description: {
    margin: '0 0 30px 0',
    fontSize: '16px',
    fontWeight: '400'
  },
  emptyPageTitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.primary.sideBarTextColor,
    marginTop: '30px',
  },
  emptyPageTitleNoMargin: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.primary.sideBarTextColor,
    fontFamily: 'Roboto'
  },
  iconHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container:{
    width: '35%',
  },
  link: {
    textDecoration: 'unset',
    alignSelf: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  createOrderRoot: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'unset'
  },
  createOrderTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.sideBarTextColor,
    marginTop: '32px'
  },
  customersTitle: {
    margin: '30px auto',
    fontSize: '18px',
    fontWeight: '400',
    color: theme.palette.primary.sideBarTextColor,
    width: '50%'
  },
  footer: {
    marginTop: '32px'
  },
  createBtn: {
    minWidth: '145px',
    textTransform: 'capitalize',
    height: '36px'
  },
  downloadBtn: {
    minWidth: '145px',
    textTransform: 'capitalize',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
    },
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    height: '36px'
  }
}

