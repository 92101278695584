import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    minHeight: '556px',
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: `calc(${variables.headerHeight} + 24px)`
  },
  backgroundWhite: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    height: '216px',
    width: '216px',
    color: theme.palette.primary.notActiveIconColor
  },
  title: {
    margin: '20px 0',
    fontSize: '32px',
    fontWeight: '400'
  },
  description: {
    margin: '0 0 30px 0',
    fontSize: '16px',
    fontWeight: '400'
  },
  emptyPageTitle: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.primary.sideBarTextColor,
    marginTop: '30px',
    fontFamily: 'Roboto'
  },
  emptyPageTitleNoMargin: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.primary.sideBarTextColor,
    fontFamily: 'Roboto'
  },
  main: {
    width: '100%'
  },
  container:{
    marginTop: '30px'
  },
  link: {
    textDecoration: 'unset',
    alignSelf: 'center'
  },
  createOrderRoot: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'unset'
  },
  createOrderTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.sideBarTextColor,
    marginTop: '32px'
  },
  customersTitle: {
    margin: '30px auto',
    fontSize: '18px',
    fontWeight: '400',
    color: theme.palette.primary.sideBarTextColor,
    width: '50%'
  },
  userMain: {
    width: '35%',
  },
  userEmptyPageTitle: {
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.primary.sideBarTextColor,
    marginTop: '30px',
    fontFamily: 'Roboto'
  },
  newEmptyPage: {
    width: '100%',
    alignSelf: 'center',
    minHeight: '500px',
    color: theme.palette.primary.notActiveIconColor,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'unset'
  },
  newTitle: {
    margin: '30px auto',
    fontSize: '18px',
    fontWeight: '400',
    color: theme.palette.primary.sideBarTextColor,
    width: '50%'
  }
}

