export default {
  tableCellItem: {
    wordBreak: 'break-all',
    overflowWrap: 'anywhere'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  avatar: {
    height: '38px',
    width: '38px',
    fontSize: '14px'
  },
  rightPart: {
    marginLeft: '12px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 500,
    margin: '8px 0px 4px 0px',
    fontFamily: 'inherit',
  },
  email: {
    fontSize: '14px',
    margin: '4px 0px 8px 0px',
    fontFamily: 'inherit',
  },
  noWrapItem: {
    whiteSpace: 'nowrap'
  },
};