import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DateRangeFilter from "./DateRangeFilter";
import KPISection from "./content/KPISection";
import PieChartSection from "./content/PieChartSection";
import DataTableSection from "./content/DataTableSection";

const DashboardLayout = observer(({ dashboardStore }) => {
  const fetchDashboardData = () => {
    dashboardStore.fetchKPIData();
    dashboardStore.fetchVisitsByWineColors();
    dashboardStore.fetchViewsByWineSweetness();
    dashboardStore.fetchViewsByWineStyle();
    dashboardStore.fetchViewsByCountry();
    dashboardStore.fetchAverageRatingByWine();
  }
  useEffect(() => {
    // Fetch initial data
    fetchDashboardData();
  }, []);

  const onDateRangeChange = (range) => {

    dashboardStore.setDateRange(range);
    fetchDashboardData(); // Refresh data based on the selected date range
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        {/* Title on the left */}
        <Grid item>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Dashboard</Typography>
        </Grid>

        {/* DateRangeFilter on the right */}
        <Grid item>
          <DateRangeFilter onDateRangeChange={onDateRangeChange} />
        </Grid>
      </Grid>
      <KPISection />
      <PieChartSection />
      <DataTableSection />
    </Box>
  );
});

export default DashboardLayout;
