
import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    padding: '10px 10px 10px 22px',
    minWidth: '850px',
    textAlign: 'start',
    justifyContent: 'space-between',
    minHeight: variables.tableToolBarHeight,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexGrow: 1,
  },
  filters: {
    display: 'flex',
    WebkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignSelf: 'start',
  },
  headerText: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '10px'
  },
};