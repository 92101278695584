import { post, get, put } from 'services/client';
import { apiEndpoints } from 'config';
import lsConstants from 'constants/local-storage';

const isLoggedIn = () => localStorage.getItem(lsConstants.CURRENT_USER) ? JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER)) : false;


export default {
  login: (username, password) => {
    const options = {
      url: apiEndpoints.login,
      data : {
        email: username,
        password
      }
    };
    return post(options);
  },
  signUp: (data) => {
    const options = {
      url: apiEndpoints.signUp,
      data
    };
    if (data.get('csrfToken')) {
      options.headers = {
        'Content-Type': 'multipart/form-data',
        'x-csrf-token': data.get('csrfToken') // Set the CSRF token in the header
      };
    };
    return post(options);
  },
  forgotPassword: (email) => {
    const options = {
      url: apiEndpoints.resetPassword,
      data : {
        email
      }
    };
    return post(options);
  },
  checkResetToken: (token) => {
    const options = { url: apiEndpoints.checkResetToken.replace(':token', token) };
    return get(options);
  },
  resetPassword: async (data) => {
    const options = { url: apiEndpoints.resetPassword, data};
    return put(options);
  },
  changePassword: async (data) => {
    const options = { url: apiEndpoints.changePassword, data};
    return put(options);
  },
  getCSRFToken: async () => {
    const options = {
      url: apiEndpoints.getCSRFToken
    };
    return get(options);
  },
  getSingleUseToken: async () => {
    const options = {
      url: apiEndpoints.getSingleUseToken
    };
    return get(options);
  },

  getCaptchaKeys: async () => {
    const options = {
      url: apiEndpoints.getCaptchaKeys
    };
    return get(options);
  },

  logout: async () => {
    const options = {
      url: apiEndpoints.logout
    };
    return post(options);
  }
}

export { isLoggedIn };