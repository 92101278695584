import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";
import SettingsService from 'services/settingsService';
import RatingsService from "services/ratingsService";

const defaultData = {
  name: '',
  serviceProviderId: '',
  producer: '',
  image: '',
  sweetness: '',
  color: '',
  style: '',
  countryId: null,
  region: null,
  isSuperVino: false,
  year: '',
  typeId: null,
  subRegion: '',
  special: '',
  ratings: [],
  menuItemPrices: [],
  menuItemSpecs: [
    {
      image: '',
      title: 'About the Wine',
      description: '',
      menuItemId: null,
      index: 0
    },
    {
      image: '',
      title: 'Tasting Notes',
      description: '',
      menuItemId: null,
      index: 1
    },
    {
      image: '',
      title: 'Pairing Notes',
      description: '',
      menuItemId: null,
      index: 2
    }
  ],
};

const defaultParams = {
  params: {
    filter: {},
    sort: {
      field: "createdAt",
      order: "desc"
    },
    limit: 50,
    skip: 0
  }
}

const requiredFields = [ "name", "serviceProviderId", "producer", "sweetness", "color", "style", "image", "typeId", "countryId", "region"];

class Catalogues {

  getDefaultStoreProps = () => ({
    catalogues: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
    menuItemState: '',
    menuItemData: JSON.parse(JSON.stringify(defaultData)),
    menuItemDataErrors: {},
    isCreateFormChanged: false,
    pageName: '',

    // sp list
    spList: [],

    ratings: [],
    ratingsLoaded: false,
    ratingsLoading: false,

    // countries data
    countries: [],
    countriesLoaded: false,
    countriesLoading: false,

    countriesSelected: null,
    menuItemTypesSelected: null,

    menuItemTypes: [],
    wineTypesLoaded: false,
    wineTypesLoading: false,
  })
 

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreCatalogues(newData) {
    this.catalogues.data = [];
    newData.data.forEach(this.addStoreCatalogue);
    this.catalogues.count = newData.count;
  }

  @action
  addStoreCatalogue = (element) => {
    this.catalogues.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter || defaultParams;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

  @action
  createFormChanged = (value = true) => {
    this.isCreateFormChanged = value;
  }

  @computed
  getCreateFormChanged = () => this.isCreateFormChanged;

  getAllOptionsLoaded = () => this.wineTypesLoaded && this.countriesLoaded;

  @action
  clearTypeAutocompleteData = () => {
    this.wineTypesLoaded = false;
    this.wineTypesLoading = false;
  }

  getWineTypesData = async () => {
    if (this.wineTypesLoaded) {
      return this.menuItemTypes;
    }
    if (this.wineTypesLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.wineTypesLoaded) {
            resolve(this.menuItemTypes)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadMenuItemTypesData();
    return this.menuItemTypes;
  }

  loadMenuItemTypesData = async () => {
    this.wineTypesLoading = true;
    try {
      const response = await SettingsService.getWineTypesOptions();
      if (response) {
        const items = [];
        response.data.forEach(menuItemType => {
          const item = {
            id: menuItemType.id,
            name: menuItemType.name
          };
          if (item.id === this.menuItemData.typeId) {
            this.menuItemTypesSelected = item;
          }
          items.push(item);
        });
        this.menuItemTypes = items;
        this.wineTypesLoaded = true;
        this.wineTypesLoading = false;
      }
    } catch (err) {
      this.wineTypesLoading = false;
    }
  }

  @action
  setMenuItemState(value) {
    this.menuItemState = value;
  }

  @action
  initializeMenuItemData = (currentSP) => {
    this.menuItemData = JSON.parse(JSON.stringify(defaultData));
    if (currentSP && currentSP !== 'unset' && currentSP !== 'allSP') {
      this.menuItemData.serviceProviderId = currentSP;
    }
  }

  getStoreMenuItemForCreate = () => this.menuItemData;

  @action
  setServiceProviderId = (id) => {
    if (this.menuItemState === 'create') {
        this.menuItemData = JSON.parse(JSON.stringify(defaultData));
        this.menuItemData.serviceProviderId = id;
    }
  }

  @action
  onChangeCheckBox = (e) => {
    this.createFormChanged();
    this.menuItemData[e.target.name] = e.target.checked;
  }

  @action
  onChangeButtonGroup = (name, value) => {
    this.createFormChanged();
    this.menuItemData[name] = value;
    if (!value && requiredFields.includes(name)) {
      this.menuItemDataErrors[name] = 'required';
    } else {
      delete this.menuItemDataErrors[name];
    }
  }

  handleFieldChange(event, type) {
    const {value, name} = event.target;
    if (name === 'countryId') {
      this.menuItemData.region = null;
    }
    this.menuItemData[name] = type === 'number' ? +value : value;
    
    if (!value && requiredFields.includes(name)) {
      this.menuItemDataErrors[name] = 'required';
    } else {
      delete this.menuItemDataErrors[name];
    }
  }

  @action
  onChangeField = (e, type) => {
    this.createFormChanged();
    this.handleFieldChange(e, type);
    return true;
  }

  getMenuItemData = (isEdit) => {
    const cleanData = JSON.parse(JSON.stringify(this.menuItemData));
    if (isEdit) {
      delete cleanData.active
      delete cleanData.serviceProvider
      delete cleanData.menuItemType
    };
    const prices = [];
    cleanData.menuItemPrices.forEach(price => {
      if (price.unit && price.price) {
        prices.push({
          ...price,
          discountedPrice: price.discountedPrice || 0
        });
      }
    });
    cleanData.menuItemPrices = prices;
    cleanData.ratings = cleanData.ratings.filter(rating => rating.ratingTypeId && rating.rating);

    return cleanData;
  };

  @action
  setMenuItemData = (data) => {
    this.menuItemData = data;
    if (this.wineTypesLoaded) {
      this.menuItemTypesSelected = this.menuItemTypes.find(type => type.id === data.typeId);
    }
    if (this.countriesLoaded) {
      this.countriesSelected = this.countries.find(country => country.id === this.menuItemData.countryId);
    }
  };

  getMenuItemDataErrors = () => this.menuItemDataErrors;

  @action
  clearMenuItemDataErrors = () => {
    this.menuItemDataErrors = {};
    this.menuItemData = JSON.parse(JSON.stringify(defaultData));
    this.createFormChanged(false);
  }

  menuItemDataHasError = () => {
    let hasError = false;
    requiredFields.forEach(i => {
      if (i === 'typeId' && this.menuItemTypesSelected) {
        delete this.menuItemDataErrors[i];
      } else if (!this.menuItemData[i]) {
        this.menuItemDataErrors[i] = 'required';
        hasError = true;
      } else {
        delete this.menuItemDataErrors[i];
      }
    });
    return hasError;
  };

  @action
  setPageName = (value) => {
    this.pageName = value;
  }

  @action
  setSpList = (list) => {
    this.spList = list;
  };

  @computed
  get getSpList() {
    return this.spList;
  };

  @computed
  get wineTypesOptions() {
    return this.menuItemTypes;
  }

  @computed
  get getSelectedWineType() {
    return this.menuItemTypesSelected;
  }

  @action
  handleAddPriceRow = () => {
    this.createFormChanged();
    this.menuItemData.menuItemPrices.push({
      unit: '',
      price: '',
      discountedPrice: '',
      menuItemId: this.menuItemData.id,
      index: this.menuItemData.menuItemPrices.length
    });
  }

  @action
  onChangePriceUnit = (event, unit, optionIndex) => {
    this.createFormChanged();
    const { value, name } = event.target;
    const data = this.menuItemData.menuItemPrices.find((price, index) => index === optionIndex);
    if (data) {
      data[name] = value;
      // const changedData = this.menuItemData.menuItemPrices.find((price, index) => index === optionIndex);
      // if (!changedData.unit || !changedData.price || !changedData.discountedPrice) {
      //   this.menuItemDataErrors.price = 'required';
      // } else {
      //   delete this.menuItemDataErrors.price;
      // }
    }
    delete this.menuItemDataErrors.menuItemPrices;
    return true;
  }

  @action
  onChangeRating = (event, unit, optionIndex) => {
    this.createFormChanged();
    const { value, name } = event.target;
    const data = this.menuItemData.ratings.find((price, index) => index === optionIndex);
    if (data) {
      data[name] = value;
    }
    delete this.menuItemDataErrors.ratings;
    return true;
  }

  @action
  handleRemovePriceRow = (index) => {
    this.createFormChanged();
    this.menuItemData.menuItemPrices = this.menuItemData.menuItemPrices.filter((price, priceIndex) => priceIndex !== index);
    if (!this.menuItemData.menuItemPrices.length) {
      delete this.menuItemDataErrors.menuItemPrices;
    }
  }

  checkRatingsFieldsHasError = () => {
    let hasError = false;
    this.menuItemData.ratings.forEach(({ratingTypeId, rating}) => {
      if (!ratingTypeId || !rating) {
        this.menuItemDataErrors.ratings = 'required';
        hasError = true;
      } else {
        delete this.menuItemDataErrors.ratings;
      }
    });
    return hasError;
  }

  checkPriceFieldsHasError = () => {
    let hasError = false;
    this.menuItemData.menuItemPrices.forEach(({unit, price}) => {
      if (!unit || !price) {
        this.menuItemDataErrors.menuItemPrices = 'required';
        hasError = true;
      } else {
        delete this.menuItemDataErrors.menuItemPrices;
      }
    });
    return hasError;
  }

  checkWineSpecFieldsHasError = () => {
    let hasError = false;
    this.menuItemData.menuItemSpecs.forEach((spec) => {
      if (!spec.title) {
        this.menuItemDataErrors.wineSpecs = 'required';
        hasError = true;
      }
    });
    return hasError;
  }

  @action
  onChangeWineSpec = (event, optionIndex, name) => {
    this.createFormChanged();
    if (event && typeof event === 'object') {
      const { value } = event.target;
      const data = this.menuItemData.menuItemSpecs.find((specItem, index) => index === optionIndex);
      if (data) {
        data[name] = value;
      } 
    } else if (name === 'image') {
      const data = this.menuItemData.menuItemSpecs.find((specItem, index) => index === optionIndex);
      if (data) {
        data[name] = event;
      }
    }
    const changedData = this.menuItemData.menuItemSpecs.find((spec, index) => index === optionIndex);
    if (!changedData.title) {
      this.menuItemDataErrors.wineSpecs = 'required';
    } else {
      delete this.menuItemDataErrors.wineSpecs;
    }
    return true;
  }

  getRatingsData = async () => {
    if (this.ratingsLoaded) {
      return this.ratings;
    }
    if (this.ratingsLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.ratingsLoaded) {
            resolve(this.ratings)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadRatingsData();
    return this.ratings;
  }

  loadRatingsData = async () => {
    this.ratingsLoading = true;
    try {
      const response = await RatingsService.getRatingsOptions();
      if (response) {
        this.ratings = response.data;
        this.ratingsLoaded = true;
        this.ratingsLoading = false;
      }
    } catch (err) {
      this.ratingsLoading = false;
    }
  }

  @computed
  get getRatingsOptions() {
    return this.ratings;
  }

  @action
  handleAddRatingRow = () => {
    this.createFormChanged();
    this.menuItemData.ratings.push({
      rating: 0,
      menuItemId: this.menuItemData.id,
      index: this.menuItemData.menuItemPrices.length
    });
  }

  @action
  handleRemoveRatingRow = (index) => {
    this.createFormChanged();
    this.menuItemData.ratings = this.menuItemData.ratings.filter((rating, ratingIndex) => ratingIndex !== index);
    if (!this.menuItemData.ratings.length) {
      delete this.menuItemDataErrors.ratings;
    }
  }

  getCountriesData = async () => {
    if (this.countriesLoaded) {
      return this.countries;
    }
    if (this.countriesLoading) {
      return new Promise(resolve => {
        const checkData =() => {
          if (this.countriesLoaded) {
            resolve(this.countries)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadCountriesData();
    return this.countries;
  }

  loadCountriesData = async () => {
    this.countriesLoading = true;
    try {
      const response = await SettingsService.getCountriesOptions();
      if (response) {
        const countriesData = [];
        response.data.forEach(country => {
          const item = {
            id: country.id,
            name: country.name
          };
          if (item.id === this.menuItemData.countryId) {
            this.countriesSelected = item;
          }
          countriesData.push(item);
        });
        this.countries = countriesData;
        this.countriesLoaded = true;
        this.countriesLoading = false;
      }
    } catch (err) {
      this.countriesLoading = false;
    }
  }

  @computed
  get isCountryOptionLoaded() {
    return this.countriesLoaded;
  }

  @computed
  get countriesOptions() {
    return this.countries;
  }

  @computed
  get getSelectedOption() {
    return this.countriesSelected
  };

  @action
  addNewAutocompleteDataTmp = (text, name, addType) => {
    this.isSpChanged = false;
    if (!text) return;
    const existItem = this.menuItemData[addType].find(i => i.name === text);
    if (!existItem) {
      const newCategory = { id: 'newId', name: text, serviceProviderId: this.menuItemData.serviceProviderId };
      this[`${addType}Selected`] = newCategory;
      this.menuItemData[addType] = [...this.menuItemData[addType], newCategory];
      delete this.menuItemDataErrors[name];
    }
  };

  onChangeAutocomplete = (newValue, name, addType) => {
    this.createFormChanged();
    if (newValue && typeof newValue === 'object') {
      this[`${addType}Selected`] = newValue;
      this.menuItemData[name] = newValue.id;
      delete this.menuItemDataErrors[name];
    } else if (!newValue && requiredFields.includes(name)) {
      this.menuItemDataErrors[name] = 'required';
      this.menuItemData[name] = null;
      this[`${addType}Selected`] = null;
    }
  }

  setProducerDefaultValue = (value) => {
    this.menuItemData.producer = value;
  }
}

export default new Catalogues();