import M from 'messages';
import moment from 'moment';

import { pad } from './helper';

const createUsersTableData = (id, name, usersRole, email, isApproved, lastLoginDate, serviceProviderId, image) => ({ id, name, usersRole, email, isApproved, lastLoginDate, serviceProviderId, image });

const adaptUsersTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createUsersTableData(
        pad(item.id, 3),
        item.name,
        M.get(`usersRoles.${item.usersRole.name}`),
        item.email,
        item.isApproved,
        item.lastLoginDate ? moment(item.lastLoginDate).format('DD.MM.YYYY, HH:mm') : '',
        item.serviceProvider ? item.serviceProvider.name : '',
        item.image
      )
    );
  });
  return dataList;
};

// eslint-disable-next-line camelcase
const createSPTableData = (id, sortingOrder, name, type, isQrPartner, isApproved, approved, country, email, userId, created_at) => ({ id, sortingOrder, name, type, isQrPartner, isApproved, approved, country, email, userId, created_at });

const adaptSPTableData = (response) => {
  const dataList = [];
  response.forEach((item) => {
    dataList.push(
      createSPTableData(
        pad(item.id, 3),
        item.sortingOrder,
        item.name,
        item.type,
        item.isQrPartner,
        item.user?.isApproved,
        item.user?.isApproved ? 'Approved' : 'Not approved',
        item.country?.name || '',
        item.user?.email || '',
        item.user?.id,
        item.created_at ? moment(item.created_at).format('DD/MM/YY') : '',
      )
    );
  });
  return dataList;
};

const createKiosksMenusTableData = (menuName, menuItemsCount, kiosksCount, serviceProviderId, id, isDefault, active, shouldResetWineRating, createdAt) => ({ menuName, menuItemsCount, kiosksCount, serviceProviderId, id, isDefault, active, shouldResetWineRating, createdAt });

const adaptKiosksMenusTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createKiosksMenusTableData(
        item.menuName,
        item.menuItemsCount,
        (item.kiosksCount || 0) + (item.deliveryStationsCount || 0) + (item.chestFreezersCount || 0),
        item.name,
        pad(item.id, 3),
        item.isDefault,
        item.active,
        item.shouldResetWineRating,
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',
      )
    );
  });
  return dataList;
};

const createCataloguesTableData = (id, serviceProviderId, producer, name, country, color, isSuperVino, year, active, tableOrWineNumber, createdAt) => ({id, serviceProviderId, producer, name, country, color, isSuperVino, year, active, tableOrWineNumber, createdAt });

const adaptCataloguesTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createCataloguesTableData(
        pad(item.id, 3),
        item.serviceProvider.name,
        item.producer,
        item.name,
        item.country?.name || '',
        item.color,
        item.isSuperVino,
        item.year,
        item.active,
        item.tableOrWineNumber || 'N/A',
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',

      )
    );
  });
  return dataList;
};

const serviceProvidersOptionData = (data) => data.map(item => ({ value: item.id, label: item.name, title: item.name }));

const createWineLabelsTableData = (id, serviceProviderId, name, country, producer, color, year, energyKcal, status, createdAt, active) => ({ id, serviceProviderId, name, country, producer, color, year, energyKcal, status, createdAt, active });

const adaptWineLabelsTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createWineLabelsTableData(
        pad(item.id, 3),
        item.serviceProvider.name,
        item.name,
        item.country?.name || '',
        item.producer,
        item.color,
        item.year,
        item.energyKcal,
        item.status === 'in_market'? 'yes' : 'no',
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',
        item.active,
      )
    );
  });
  return dataList;
};

export {
  adaptUsersTableData,
  adaptSPTableData,
  adaptKiosksMenusTableData,
  adaptCataloguesTableData,
  serviceProvidersOptionData,
  adaptWineLabelsTableData
}
